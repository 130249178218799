<template>
    <div ref="editorToolbar">
        <button @mousedown.prevent="applyFormat('b')" class="btn btn-light btn-sm"><i class="bi bi-type-bold"/></button>
        <button @mousedown.prevent="applyFormat('i')" class="btn btn-light btn-sm"><i class="bi bi-type-italic"/>
        </button>
        <button @mousedown.prevent="applyFormat('ul')" class="btn btn-light btn-sm"><i class="bi bi-list-ul"/></button>
        <button @mousedown.prevent="applyFormat('ol')" class="btn btn-light btn-sm"><i class="bi bi-list-ol"/></button>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorEditorToolbar",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageEditorIframeWindow() {
            return this.pageEditor.iframe.contentDocument
        }
    },
    mounted() {
        console.log(this.$refs.editorToolbar)
    },
    methods: {
        applyFormat(tagName) {
            const selection = this.pageEditorIframeWindow.getSelection()
            if (!selection.rangeCount) return

            const range = selection.getRangeAt(0)

            const selectedContent = range.cloneContents()
            const hasTag = selectedContent.querySelector(tagName)
            if (hasTag) {
                const container = document.createElement(tagName)
                container.appendChild(selectedContent)
                const re = new RegExp('<\\/?' + tagName + '>', 'g')
                container.innerHTML = container.innerHTML.replace(re, '')
                range.deleteContents()
                range.insertNode(container)
            } else {
                const element = document.createElement(tagName)
                range.surroundContents(element)
            }

            /*
            const selection = this.pageEditorIframeWindow.getSelection()
            if (!selection.rangeCount) return

            const range = selection.getRangeAt(0)
            const selectedContent = range.cloneContents()
            const container = document.createElement('div')
            container.appendChild(selectedContent)

            // Check if selection contains <b> or <strong> tags
            const hasTag = container.querySelector(tagName)

            if (hasTag) {
                // Remove tag
                container.innerHTML = container.innerHTML.replace(/<\/?' + tagName + '>/g, '')
                range.deleteContents()
                range.insertNode(container)
            } else {
                const element = document.createElement(tagName)
                range.surroundContents(element)
            }

             */
        }
    }
}
</script>

<style scoped>
div {
    user-select: none;
    position: absolute;
    border: 1px solid red;
    background: #fff;
}

button {
    user-select: none;
}
</style>