export default {
    namespaced: true,
    state: {
        data: {}
    },
    mutations: {
        data(state, {pageId, data}) {
            state.data[pageId] = data
        }
    },
    getters: {
        pageData: (state) => (pageId) => {
            return state.data[pageId]
        }
    },
    actions: {
        async get({state, commit, dispatch, rootState}, pageId) {
            if (state.data[pageId] !== undefined) {
                return state.data[pageId]
            } else {
                const options = []
                commit('data', {pageId, options})
                await dispatch('pageSectionLink/init', {}, {root: true})
                
                const pageSectionLinkData = rootState.pageSectionLink.data.filter(o => o.pageId === pageId)
                
                for (let i = 0; i < pageSectionLinkData.length; i++) {
                    const pageSection = pageSectionLinkData[i]
                    
                    const template = rootState.templates.items.find(o => o.id === pageSection.sectionTemplateId)
                    
                    let areaKey = 0
                    const areas = []
                    const matches = template.templateHtml.matchAll(/\{\{ area }}/g)
                    for (const match of matches) {
                        areas.push({
                            value: 'area_' + pageSection.id + '_' + areaKey,
                            text: 'Area ' + (areaKey + 1)
                        })
                        
                        areaKey++
                    }
                    
                    options.push({
                        id: pageSection.id,
                        name: 'Section ' + (i + 1),
                        areas
                    })
                }
                
                return options
            }
        }
    }
}