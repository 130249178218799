var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      staticClass: "form-select form-select-sm w-auto",
      on: {
        change: _vm.setPropagatedContent,
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        },
      },
    },
    [
      _c("option", { attrs: { selected: "", disabled: "" } }, [
        _vm._v("Select…"),
      ]),
      _vm._v(" "),
      _vm.pageSectionAreas?.length
        ? [
            _vm._l(_vm.pageSectionAreas, function (section, k) {
              return [
                _c("optgroup", { attrs: { label: section.name } }, [
                  _vm._v(_vm._s(section.name) + "}"),
                ]),
                _vm._v(" "),
                _vm._l(section.areas, function (area) {
                  return _c(
                    "option",
                    {
                      domProps: {
                        value: area.value,
                        selected: area.value === _vm.selectedContentArea,
                      },
                    },
                    [_vm._v(_vm._s(area.text) + "\n            ")]
                  )
                }),
              ]
            }),
          ]
        : _vm._l(_vm.pageTemplatesContentAreas, function (contentArea) {
            return _c(
              "option",
              {
                domProps: { selected: contentArea === _vm.selectedContentArea },
              },
              [_vm._v("\n            " + _vm._s(contentArea) + "\n        ")]
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }