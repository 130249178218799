var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "d-flex flex-column h-100 p-3" }, [
    _c("h2", { staticClass: "h5 mb-3" }, [
      _vm._v("\n        " + _vm._s(_vm.contentAreaName) + "\n        "),
      _c("br"),
      _vm._v(" "),
      _c("small", { staticClass: "lh-1 text-body-secondary" }, [
        _vm._v("area"),
      ]),
    ]),
    _vm._v(" "),
    !_vm.isHeaderOrFooterArea
      ? _c(
          "div",
          { staticClass: "d-grid gap-1" },
          [
            _c(
              "Button",
              {
                staticClass: "btn-sm border d-flex align-items-center gap-2",
                attrs: {
                  "data-bs-toggle": "offcanvas",
                  "data-bs-target": "#addContentOffcanvas",
                  "aria-controls": "addContentOffcanvas",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.setOffcanvas.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "bi bi-plus-lg" }),
                _vm._v("\n            New content\n        "),
              ]
            ),
            _vm._v(" "),
            _c(
              "Button",
              {
                staticClass: "btn-sm border d-flex align-items-center gap-2",
                attrs: {
                  "data-bs-toggle": "offcanvas",
                  "data-bs-target": "#addExistingContentOffcanvas",
                  "aria-controls": "addExistingContentOffcanvas",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.setOffcanvas.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "bi-diagram-3-fill" }),
                _vm._v("\n            Existing content\n        "),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }