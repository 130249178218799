var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Import CSV")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("AttachmentDropzone", {
            staticClass: "mb-3",
            attrs: { "set-file": _vm.setFile },
          }),
          _vm._v(" "),
          _vm.loading
            ? _c("div", { staticClass: "mb-3 text-center" }, [
                _vm._v("\n                Processing data…\n            "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.notices, function (notice, index) {
            return [
              notice.heading
                ? _c("div", { staticClass: "mb-3 card" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header bg-success-subtle text-success-emphasis",
                      },
                      [
                        _c("i", { staticClass: "bi bi-info-circle-fill" }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "ms-1",
                          domProps: { innerHTML: _vm._s(notice.heading) },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "card-body",
                      domProps: { innerHTML: _vm._s(notice.message) },
                    }),
                  ])
                : _c("div", {
                    staticClass: "alert alert-success",
                    domProps: { innerHTML: _vm._s(notice) },
                  }),
            ]
          }),
          _vm._v(" "),
          _vm._l(_vm.warnings, function (warning, index) {
            return [
              warning.heading
                ? _c("div", { staticClass: "mb-3 card" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header bg-warning-subtle text-warning-emphasis",
                      },
                      [
                        _c("i", {
                          staticClass: "bi bi-exclamation-triangle-fill",
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "ms-1",
                          domProps: { innerHTML: _vm._s(warning.heading) },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "card-body",
                      domProps: { innerHTML: _vm._s(warning.message) },
                    }),
                  ])
                : _c("div", {
                    staticClass: "alert alert-warning",
                    domProps: { innerHTML: _vm._s(warning) },
                  }),
            ]
          }),
          _vm._v(" "),
          _vm._l(_vm.errors, function (error, index) {
            return [
              error.heading
                ? _c("div", { staticClass: "mb-3 card" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header bg-danger-subtle text-danger-emphasis",
                      },
                      [
                        _c("i", {
                          staticClass: "bi bi-exclamation-triangle-fill",
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "ms-1",
                          domProps: { innerHTML: _vm._s(error.heading) },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "card-body",
                      domProps: { innerHTML: _vm._s(error.message) },
                    }),
                  ])
                : _c("div", { staticClass: "alert alert-danger" }, [
                    _c("i", { staticClass: "bi bi-exclamation-triangle-fill" }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "ms-1",
                      domProps: { innerHTML: _vm._s(error) },
                    }),
                  ]),
            ]
          }),
          _vm._v(" "),
          _vm.rowCount
            ? _c(
                "div",
                { staticClass: "mb-3 text-center" },
                [
                  _c("p", [
                    _c("b", [_vm._v(_vm._s(_vm.rowCount) + " rows")]),
                    _vm._v(" ready to import"),
                  ]),
                  _vm._v(" "),
                  _vm.errors.length || _vm.warnings.length
                    ? _c("p", { staticClass: "mb-3 text-center" }, [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ignoreErrorsAndWarnings,
                                expression: "ignoreErrorsAndWarnings",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.ignoreErrorsAndWarnings
                              )
                                ? _vm._i(_vm.ignoreErrorsAndWarnings, null) > -1
                                : _vm.ignoreErrorsAndWarnings,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.ignoreErrorsAndWarnings,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.ignoreErrorsAndWarnings = $$a.concat(
                                        [$$v]
                                      ))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.ignoreErrorsAndWarnings = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.ignoreErrorsAndWarnings = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(
                            "\n                        Ignore errors and warnings\n                    "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      attrs: {
                        disabled: _vm.importBtnDisabled,
                        "default-class": "btn-warning",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.importData.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n                    Import\n                ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }