export default {
    namespaced: true,
    state: {
        requestLastSent: Math.ceil(Date.now() / 1000),
        requestInProgress: false,
        recentlyModified: [],
    },
    actions: {
        async clearModifiedItems({state, dispatch, rootState}) {
            if (state.requestInProgress) return false
            state.requestInProgress = true
            // This is to prevent multiple requests being sent in quick succession. For example, without this
            // loading a new route that contains a listing would generate 2 requests because both a route
            // change and view.load run this script.
            setTimeout(() => state.requestInProgress = false, 200)
            
            const {items} = (await dispatch('request/get', {
                url: 'api/component/activity',
                params: {createdDate: {ge: state.requestLastSent}}
            }, {root: true})).data
            
            state.requestLastSent = Math.ceil(Date.now() / 1000)
            
            items.forEach(o => {
                const {id, componentId, rowId: itemId, createdBy: userId, activity} = o
                
                if (state.recentlyModified.indexOf(id) > -1) return
                
                state.recentlyModified.push(id)
                // So the number of keys doesn't stack up make sure there's never more than 100.
                if (state.recentlyModified.length > 100) {
                    state.recentlyModified = state.recentlyModified.slice(-100)
                }
                
                const component = rootState.components.items.find(o => o.id === componentId)
                const tableName = component.tableName
                
                // todo - Won't this be running multiple times if multiple items of the same component are updated?
                dispatch('itemData/updateDisplayOrders', {componentId}, {root: true})
                
                dispatch('processTableName', {tableName, id: itemId, userId, activity})
                
                // When users update relationship Many-to-Many data using the FormControlTypeRelationshipManyToMany
                // form control, the data is updated, so there's no need to load it again. This was implemented
                // to remove overhead caused by loads of rel data being constantly downloaded by ODP's users.
                // todo What about instances where rel' data is updated on the API? I should review scripts
                //      which use Categories::insert to see if the UI can be updated to refresh their data.
                if (rootState.user.user.id !== o.createdBy) {
                    dispatch('updateRelationshipManyToManyData', {tableName, componentId, itemId})
                }
            })
        },
        async updateRelationshipManyToManyData({dispatch, rootGetters}, {tableName, componentId, itemId}) {
            const columnNames = rootGetters['componentStructure/get'](componentId)
                .filter(o => o.type === 'relationshipManyToMany')
                .map(a => a.columnName)
            
            if (columnNames) {
                for (const k in columnNames) {
                    const columnName = columnNames[k]
                    dispatch('categories/modifiedItemsUpdate', {tableName, columnName, itemId}, {root: true})
                }
            }
        },
        async processTableName({dispatch, rootState, commit}, {tableName, id, userId, activity}) {
            if (tableName === 'templates_templates') {
                dispatch('templates/init', true, {root: true})
            }
            
            if (
                activity === 'Update'
                && await dispatch('itemData/isset', {tableName, id}, {root: true})
            ) {
                if (rootState.user.user.id !== userId) {
                    await dispatch('itemData/get', {tableName, id, refresh: true}, {root: true})
                }
                
                await dispatch('themes/onModifiedItems', {userId, tableName, id}, {root: true})
                
                /*
                // todo This doesn't currently work. It doesn't update the form's values and as clearModifiedItems
                //      is no longer called in a loop, saves to an open form do not trigger it. What should happen
                //      is that the loop is reintroduced, but only when a form is active.
                // Forms
                if (rootState.user.user.id !== userId) {
                    let formId = 'form:' + tableName + ':' + id
                    if (this.hasModule(formId)) {
                        dispatch('modals/show', {
                            componentName: 'ConfirmModal',
                            obj: {
                                modalTitle: 'This item has been modified.',
                                modalBody: `<p>Do you wish to reload the form?</p>`,
                                onConfirm: () => {
                                    
                                    // todo - This can no longer be used as obj.data is no longer set.
                                    let keys = Object.keys(obj.data)
                                    
                                    dispatch('itemData/get', {
                                        tableName: tableName,
                                        id: id,
                                    }, {root: true})
                                        .then(o => {
                                            keys.forEach((key) => {
                                                commit(formId + '/presetData', {
                                                    name: key,
                                                    value: o[key],
                                                }, {root: true})
                                            })
                                            
                                            commit(formId + '/toggleShow', null, {root: true})
                                        })
                                }
                            }
                        }, {root: true})
                    }
                }
                */
            }
        }
    }
}