var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass:
        "list-group-item list-group-item-action py-1 strong text-uppercase small",
      class: _vm.classNames,
      attrs: { role: "button" },
      on: {
        mouseover: _vm.onMouseover,
        mouseout: _vm.onMouseout,
        click: _vm.onClick,
      },
    },
    [
      _c("small", { staticClass: "flex-fill" }, [
        _vm._v("Section " + _vm._s(_vm.sectionNumber)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }