<template>
    <div v-if="pageContentLinkData.length" class="card-body p-0 pb-1">
        <div class="list-group list-group-flush">
            <PageContentContent v-for="o in pageContentLinkData" :key="o.id" :content-id="o.contentId"/>
        </div>
    </div>
</template>

<script>
import PageContentContent from "./PageContentContent.vue"

export default {
    name: "PageContentArea",
    components: {PageContentContent},
    props: {
        pageSectionLinkId: Number,
        area: String
    },
    computed: {
        pageContentLinkData() {
            return this.$store.state.pageContentData.items
                .filter(o => o.pageSectionLinkId === this.pageSectionLinkId && o.contentArea === this.area)
        }
    }
}
</script>

<style scoped>

</style>