export default {
    namespaced: true,
    state: {
        data: []
    },
    mutations: {
        data(state, data) {
            state.data = data
        }
    },
    actions: {
        async init({state, dispatch, commit}) {
            if (state.data.length === 0) {
                const o = await dispatch('request/get', {
                    url: 'api/page-section'
                }, {root: true})
                
                commit('data', o.data.data)
            }
        }
    }
}