<template>
    <select v-model="formData.primaryCategory" class="form-select form-select-sm" name="primaryCategory" required>
        <option :value="0">- All -</option>
        <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.title }}
        </option>
    </select>
</template>

<script>
export default {
    name: "PrimaryCategoryFilter",
    computed: {
        formData() {
            return this.$store.state.printGiftsReports.formData
        }
    },
    asyncComputed: {
        async categories() {
            const o = await this.$store.dispatch('request/get', {
                url: 'api/component/m_products_categories',
                params: {
                    parentId: 0,
                    fields: ['id', 'title'],
                    sort: 'title'
                }
            })

            return o.data.items
        }
    }
}
</script>

<style scoped>

</style>