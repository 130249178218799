var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormControlPlainText", { staticClass: "small" }, [
        _vm._v("\n        Email sent:\n        "),
        _vm.lastSent
          ? _c("span", [_vm._v(_vm._s(_vm.dateStr))])
          : _c("em", [_vm._v("never")]),
      ]),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "border",
          attrs: { disabled: _vm.isDisabled },
          nativeOn: {
            click: function ($event) {
              return _vm.resendEmail.apply(null, arguments)
            },
          },
        },
        [_vm._v("Send receipt")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }