<template>
    <form ref="form">
        <FormGroup class="mb-3" v-for="(field, i) in fields" :key="i" :form-id="formId" :field="field"
                   :hide-help-text="true"/>

        <PageEditorPageSettings v-if="activeTab === 'Settings'" :form-id="formId"/>
    </form>
</template>

<script>
import FormGroup from "../../common/form/FormGroup.vue"
import PageEditorPageSettings from "./PageEditorPageSettings.vue"

export default {
    name: "PageEditorPageForm",
    components: {PageEditorPageSettings, FormGroup},
    props: {
        formId: String
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        activeTab() {
            return this.pageEditor.pagePanelActiveTab
        },
        siteTreeId() {
            return this.pageEditor.siteTreeId
        },
        fields() {
            return this.$store.state[this.formId]?.fields
        },
        modifiedData() {
            return this.$store?.getters[this.formId + '/modifiedData']()
        }
    },
    mounted() {
        this.$store.commit(this.formId + '/setFormEl', this.$refs.form)
    },
    watch: {
        modifiedData(newValue) {
            // todo Perhaps an autosave: true property in the VueX forms module would be better.
            if (this.isSaving) return
            this.isSaving = true

            // Give components time to compile so they validate correctly.
            this.$nextTick(() => {
                //const isValid = this.$refs.form.checkValidity()
                const isValid = this.$store.dispatch(this.formId + '/validateForm')
                if (isValid) {
                    this.$store.dispatch(this.formId + '/save')
                        .then(() => {
                            this.isSaving = false

                            const columnName = Object.keys(newValue)[0]
                            if (columnName === 'groupAccess') {
                                setTimeout(() => {
                                    this.$store.dispatch(this.siteTreeId + '/init')
                                }, 1000)
                            }
                        })
                }
            })
        }
    }
}
</script>

<style scoped>

</style>