var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "btn-sm d-flex align-items-center gap-2 text-danger",
      nativeOn: {
        click: function ($event) {
          return _vm.deleteSection.apply(null, arguments)
        },
      },
    },
    [_c("i", { staticClass: "bi bi-trash" }), _vm._v("\n    Delete section\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }