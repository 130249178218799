<template>
    <Button @click.native="moveDown" :disabled="isDisabled" class="btn-sm d-flex align-items-center gap-2">
        <i class="bi bi-chevron-up"></i>
        Move up
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorSectionMoveUpBtn",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        isDisabled() {
            return this.pageEditor.pageSections.map(o => o.id).indexOf(this.pageEditor.activeSectionId) === 0
                || this.pageEditor.moveInProgress
        }
    },
    methods: {
        moveDown() {
            this.$store.dispatch('pageEditor/moveSectionUp')
        }
    }
}
</script>

<style scoped>

</style>