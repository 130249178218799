export default {
    namespaced: true,
    state: {
        promises: {},
    },
    mutations: {
        remove(state, {tableName, id}) {
            if (
                state.promises[tableName]
                && state.promises[tableName][id]
            ) {
                delete state.promises[tableName][id]
            }
        },
    },
    actions: {
        isset({state}, {tableName, id}) {
            return state.promises[tableName]?.[id]
        },
        get({state, dispatch}, {tableName, id, refresh}) {
            
            // If the id is a number ensure it's an int. We need to check if it's a number because some components
            // allow strings to be provided as IDs, such as modulecomponents and sites.
            id = parseInt(id)
            
            if (!state.promises[tableName]) {
                this._vm.$set(state.promises, tableName, {})
            }
            
            if (
                refresh
                && state.promises[tableName][id]
            ) {
                this._vm.$delete(state.promises[tableName], tableName)
                delete state.promises[tableName][id]
            }
            
            if (!state.promises[tableName][id]) {
                let promise = new Promise((resolve, reject) => {
                    if (
                        id === 0
                        || isNaN(id) // This prevents request 404 errors when no ID is provided.
                    ) {
                        // Providing a blank object when id===0 means that the object can be preset
                        // with default values e.g. content's pageId and page's navigation groups
                        resolve({})
                    } else {
                        dispatch('request/get', {
                            url: 'api/component/' + tableName + '/' + id
                        }, {root: true})
                            .then((obj) => {
                                if (obj.data) {
                                    dispatch('setDisplayOrder', {
                                        tableName: tableName,
                                        obj: obj.data
                                    })
                                }
                                
                                resolve(obj.data)
                            }, () => {
                                // We must resolve the promise so that the calling script can continue.
                                // todo - I've disabled this because in the then() above I moved the resolve out of the
                                //        if condition, so I'm not sure if this is required.
                                //deferred.resolve();
                            })
                    }
                })
                
                this._vm.$set(state.promises[tableName], id, promise)
            }
            
            return state.promises[tableName][id]
        },
        // Updates a promise's object resulting updates to all directives which reference the object.
        set({state, dispatch}, {tableName, id, property, value}) {
            if (
                state.promises[tableName]
                && state.promises[tableName][id]
            ) {
                state.promises[tableName][id]
                    .then((obj) => {
                        if (typeof property === 'object') {
                            Object.assign(obj, property)
                        } else {
                            obj[property] = value
                        }
                        
                        dispatch('setDisplayOrder', {
                            tableName: tableName,
                            obj: obj
                        })
                    })
            }
        },
        insert({dispatch}, {tableName, data}) {
            return dispatch('request/post', {
                url: 'api/component/' + tableName,
                postData: data
            }, {root: true})
                .then((response) => {
                    let item = response.data
                    dispatch('set', {
                        tableName: tableName,
                        id: item.id,
                        property: item,
                    })
                    return response
                })
        },
        update({dispatch}, {tableName, id, data}) {
            return dispatch('request/patch', {
                url: 'api/component/' + tableName + '/' + id,
                postData: data
            }, {root: true})
                .then((response) => {
                    dispatch('set', {
                        tableName: tableName,
                        id: id,
                        property: response.data,
                    })
                    return response
                })
        },
        setItems({dispatch}, {tableName, objs}) {
            objs.forEach((obj) => {
                dispatch('setItem', {
                    tableName: tableName,
                    obj: obj
                })
            })
        },
        setItem({state, dispatch}, {tableName, obj}) {
            if (!state.promises[tableName]) {
                this._vm.$set(state.promises, tableName, {})
            }
            
            dispatch('setDisplayOrder', {
                tableName: tableName,
                obj: obj
            })
            
            let id = obj.id
            
            let promise = new Promise((resolve, reject) => {
                resolve(obj)
            })
            this._vm.$set(state.promises[tableName], id, promise)
        },
        // Utility for pre-caching a collection of items to avoid the UI generating lots of individual requests when
        // ItemData.get is used for individual items. For example, the view factory uses it to preload all items to be
        // presented in the listing.
        preload({state, dispatch}, {tableName, ids}) {
            return new Promise((resolve, reject) => {
                if (!ids) {
                    reject
                    return
                }
                
                // ids may contain some ids which have already been set. To reduce the request size we only want unset IDs.
                // So create a new array only containing the unset IDs.
                let unsetIds = []
                ids.forEach((id) => {
                    id = parseInt(id)
                    if (
                        state.promises[tableName]
                        && state.promises[tableName][id]
                    ) {
                        return
                    }
                    unsetIds.push(id)
                })
                
                if (unsetIds.length) {
                    dispatch('request/post', {
                        url: 'api/component/' + tableName,
                        postData: {
                            id: unsetIds
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                        .then((obj) => {
                            let objs = obj.data.items
                            objs.forEach((obj) => {
                                // If a request is sent for an item which does not exist then the
                                // response's obj.data will be set to ""
                                if (obj !== "") {
                                    dispatch('setItem', {
                                        tableName: tableName,
                                        obj: obj
                                    })
                                }
                            })
                            
                            // Now that we've set all unset items we can collate _all_ items.
                            resolveAllItems()
                        })
                } else {
                    resolveAllItems()
                }
                
                function resolveAllItems() {
                    let promiseArray = []
                    ids.forEach((id) => {
                        promiseArray.push(
                            dispatch('get', {
                                tableName: tableName,
                                id: id
                            })
                        )
                    })
                    
                    Promise.all(promiseArray)
                        .then((objs) => {
                            resolve(objs)
                        })
                }
            })
        },
        archive({commit, dispatch}, {tableName, id, doArchive}) {
            return dispatch('request/post', {
                url: 'api/ListingController/archive',
                postData: {
                    tableName: tableName,
                    ids: [id], // ListingController/archive expects an array
                    archive: doArchive
                }
            }, {root: true})
                .then((response) => {
                    dispatch('set', {
                        tableName: tableName,
                        id: id,
                        property: 'isArchived',
                        value: doArchive,
                    })
                    commit('cacheNeedsClearing', null, {root: true})
                    return response
                })
        },
        delete({commit, dispatch}, {tableName, id}) {
            return dispatch('request/delete', {
                url: 'api/component/' + tableName + '/' + id
            }, {root: true})
                .then(() => {
                    commit('cacheNeedsClearing', null, {root: true})
                    
                    commit('remove', {tableName, id})
                    
                    if (tableName === 'modulecomponents') {
                        dispatch('components/init', null, {root: true})
                    }
                })
        },
        setDisplayOrder({commit}, {tableName, obj}) {
            if (obj.displayOrder !== undefined) {
                commit('displayOrders/set', {
                    tableName: tableName,
                    id: obj.id,
                    displayOrder: obj.displayOrder
                }, {root: true})
            }
        },
        updateDisplayOrders({dispatch, rootState}, {componentId}) {
            let component = rootState.components.items.find(o => o.id === componentId)
            if (component.showDisplayOrder) {
                let tableName = component.tableName
                dispatch('request/get', {
                    url: 'api/component/' + tableName,
                    params: {
                        fields: ['id', 'displayOrder'],
                    }
                }, {root: true})
                    .then((obj) => {
                        obj.data.items.forEach((obj) => {
                            dispatch('set', {
                                tableName: tableName,
                                id: obj.id,
                                property: 'displayOrder',
                                value: obj.displayOrder,
                            })
                        })
                    })
            }
        }
    },
}