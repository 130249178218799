var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contentAreaName || _vm.activePageContentId || _vm.pageSectionsNode
    ? _c("div", { staticClass: "dropdown" }, [
        _c(
          "div",
          {
            staticClass: "d-inline-block",
            attrs: { "data-bs-toggle": "dropdown" },
          },
          [
            _c(
              "Button",
              { staticClass: "rounded-0", attrs: { title: "Add content" } },
              [_c("i", { staticClass: "bi bi-plus-lg" })]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "dropdown-menu" },
          [
            _vm.pageSectionsNode
              ? _c("li", [
                  _c(
                    "a",
                    {
                      staticClass:
                        "dropdown-item d-flex align-items-center gap-2",
                      attrs: { role: "button" },
                      on: { click: _vm.addSection },
                    },
                    [
                      _c("i", { staticClass: "bi bi-plus-lg" }),
                      _vm._v("\n                New section\n            "),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.contentAreaName || _vm.activePageContentId
              ? [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass:
                          "dropdown-item d-flex align-items-center gap-2",
                        attrs: {
                          "data-bs-toggle": "offcanvas",
                          href: "#addContentOffcanvas",
                          role: "button",
                          "aria-controls": "addContentOffcanvas",
                        },
                        on: { click: _vm.setOffcanvas },
                      },
                      [
                        _c("i", { staticClass: "bi bi-plus-lg" }),
                        _vm._v(
                          "\n                    New content\n                "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass:
                          "dropdown-item d-flex align-items-center gap-2",
                        attrs: {
                          "data-bs-toggle": "offcanvas",
                          href: "#addExistingContentOffcanvas",
                          role: "button",
                          "aria-controls": "addExistingContentOffcanvas",
                        },
                        on: { click: _vm.setOffcanvas },
                      },
                      [
                        _c("i", { staticClass: "bi bi-diagram-3-fill" }),
                        _vm._v(
                          "\n                    Existing content\n                "
                        ),
                      ]
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }