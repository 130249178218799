<template>
    <div class="list-group-item py-1 pe-2 d-flex align-items-center">
        <span class="flex-fill">{{ template?.title }}</span>

        <Button class="btn-sm"><i class="bi bi-pencil-square"/></Button>
        <Button class="btn-sm"><i class="bi bi-chevron-up"/></Button>
        <Button class="btn-sm"><i class="bi bi-chevron-down"/></Button>
        <Button class="btn-sm"><i class="bi bi-x-lg"/></Button>
    </div>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "PageContentContent",
    components: {Button},
    props: {
        contentId: Number
    },
    computed: {

    },
    asyncComputed: {
        content() {
            return this.$store.dispatch('itemData/get', {tableName: 'content', id: this.contentId})
        },
        template() {
            if (this.content) {
                return this.$store.dispatch('itemData/get', {tableName: 'templates_templates', id: this.content.contentType})
            }
        }
    }
}
</script>

<style scoped>

</style>