<template>
    <a v-if="area" role="button" class="list-group-item list-group-item-action py-1 ps-0 strong text-uppercase small"
       :class="classNames" @mouseover="onMouseover" @mouseout="onMouseout" @click="onClick">
        <small class="ps-3 flex-fill">{{ area.label }}</small>
    </a>
</template>

<script>
export default {
    name: "PageEditorContentNavContentArea",
    props: {
        contentAreaName: String
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        activeContentAreaName() {
            return this.pageEditor.activeContentAreaName
        },
        hoverContentAreaName() {
            return this.pageEditor.hoverContentAreaName
        },
        area() {
            return this.pageEditor.contentAreas.find(o => o.name === this.contentAreaName)
        },
        isSectionArea() {
            return !!this.area?.sectionId
        },
        classNames() {
            let classNames = this.isSectionArea ? 'ps-2' : ''

            if (this.contentAreaName === this.activeContentAreaName) {
                classNames += ' active'
            } else if (this.contentAreaName === this.hoverContentAreaName) {
                classNames += ' list-group-item-hover'
            } else {
                classNames += ' text-body-secondary'
                classNames += this.isSectionArea ? ' bg-body-tertiary' : ' bg-body-secondary'
            }

            return classNames
        }
    },
    methods: {
        onMouseover() {
            this.$store.commit('pageEditor/hoverContentAreaName', this.contentAreaName)
        },
        onMouseout() {
            this.$store.commit('pageEditor/hoverContentAreaName', '')
        },
        async onClick() {
            await this.$store.dispatch('pageEditor/goToContentArea', {
                router: this.$router,
                contentAreaName: this.contentAreaName
            })

            await this.$store.dispatch('pageEditor/scrollToContentArea', {contentAreaName: this.contentAreaName})
        }
    }
}
</script>

<style scoped>
.list-group-item {
    border-color: transparent !important;
    font-size: 0.75rem;
    font-weight: 500;
}

.list-group-item-hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: rgba(9, 110, 253, 0.2) !important;
}
</style>