var render, staticRenderFns
import script from "./FormControlModulestructureSelectType.vue?vue&type=script&lang=js"
export * from "./FormControlModulestructureSelectType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fff52d8",
  null
  
)

export default component.exports