import param from 'jquery-param'

export default {
    namespaced: true,
    state() {
        return {
            listingName: undefined,
            
            // The name space is provided via a ".<name>" suffice to the listingName. E.g. view:blog.author
            namespace: undefined,
            
            // Listings which appear in modals are namespaced so that their settings do not interfere with the non-
            // namespaced version. Some features are disabled for namespaced modules.
            isNamespaced: false,
            
            tableName: undefined,
            componentId: undefined,
            
            // Listing service
            selectMode: false,
            showFilters: true,
            allItemsSelected: false,
            selectedIds: [],
            showArchive: false,
            onSelect: undefined,
            
            // Listing settings
            orderBy: '',
            orderDirection: 'Asc',
            pageSize: 20,
            viewMode: 'table',
            columns: [],
            showSearchToolbar: false,
            searchTerm: '',
            page: 1,
            
            // todo This can be removed once we roll out the new filters and remove the Search Modal
            searchColumn: false,
            
            filters: [],
            savedFilters: [],
            
            hiddenFilters: {},
            
            ids: [],
            allIds: [],
            
            // The total number of ids across all pages
            // Setting it to zero indicates that the current filters have no results.
            itemCount: false,
            
            allSelected: false,
            
            loadTimeout: undefined,
        }
    },
    mutations: {
        toggleShowArchive(state) {
            state.showArchive = !state.showArchive
        },
        setPageSize(state, pageSize) {
            state.pageSize = pageSize
        },
        setOrderBy(state, orderBy) {
            state.orderBy = orderBy
        },
        setOrderDirection(state, orderDirection) {
            state.orderDirection = orderDirection
        },
        setViewMode(state, viewMode) {
            state.viewMode = viewMode
        },
        setColumns(state, columns) {
            state.columns = columns
        },
        setPage(state, n) {
            state.page = parseInt(n) || 0
        },
        pageDown(state) {
            state.page--
        },
        pageUp(state) {
            state.page++
        },
        toggleSearchToolbar(state) {
            state.showSearchToolbar = !state.showSearchToolbar
        },
        searchColumn(state, str) {
            state.searchColumn = str
        },
        searchTerm(state, str) {
            state.searchTerm = str
            state.page = 1
        },
        addFilter(state, o) {
            state.filters.push(o)
        },
        setFilterProp(state, {key, name, value}) {
            state.filters[key][name] = value
            
            // Avoids the issue where a blank page is shown when
            // the number of pages is reduced to lower than the user's current.
            if (name === 'value') {
                state.page = 1
            }
        },
        deleteFilter(state, key) {
            state.filters.splice(key, 1)
        },
        deleteAllFilters(state) {
            state.filters.length = 0
        },
        resetFilter(state, key) {
            state.filters[key].value = undefined
        },
        resetAllFilters(state) {
            state.searchTerm = ''
            state.searchColumn = false
            state.page = 1
            
            state.filters.forEach(o => o.value = undefined)
        },
        saveFilters(state, name) {
            // If the name matches an existing one update it
            let filter = state.savedFilters.find(o => o.name === name)
            if (filter) {
                filter.filters = JSON.parse(JSON.stringify(state.filters))
            } else {
                
                // If the filter data matches a saved filters' then update its name
                filter = state.savedFilters.find(o => JSON.stringify(o.filters) === JSON.stringify(state.filters))
                if (filter) {
                    filter.name = name
                } else {
                    state.savedFilters.push({
                        name: name,
                        filters: JSON.parse(JSON.stringify(state.filters))
                    })
                }
            }
        },
        deleteSavedFilter(state, name) {
            const i = state.savedFilters.map(o => o.name).indexOf(name)
            state.savedFilters.splice(i, 1)
        },
        selectAll(state) {
            state.selectedIds = []
            if (!state.allSelected) {
                state.ids.forEach((itemId) => {
                    state.selectedIds.push(itemId)
                })
            }
            state.allSelected = !state.allSelected
            state.allItemsSelected = false
        },
        selectNone(state) {
            state.selectedIds = []
            state.allSelected = false
            state.allItemsSelected = false
        },
        selectAllItems(state) {
            state.allItemsSelected = true
        },
        onShiftClick(state, id) {
            let len = state.selectedIds.length
            
            // Shift click only works if another item was previously selected.
            if (len) {
                let lastSelectedId = state.selectedIds[len - 1]
                let keys = []
                
                state.ids.forEach((itemId, key) => {
                    if (itemId === lastSelectedId) {
                        keys.push(key)
                    }
                    if (itemId === id) {
                        keys.push(key)
                    }
                })
                
                let start = keys.shift()
                let end = keys.pop()
                let ids = state.ids.slice(start, end + 1)
                
                ids.forEach((itemId) => {
                    if (state.selectedIds.indexOf(itemId) === -1) {
                        state.selectedIds.push(itemId)
                    }
                })
            }
        },
        onMetaClick(state, id) {
            let i = state.selectedIds.indexOf(id)
            if (i === -1) {
                state.selectedIds.push(id)
            } else {
                state.selectedIds.splice(i, 1)
                state.allSelected = false
            }
        },
    },
    getters: {
        selectedIdsLength(state) {
            return state.selectedIds.length
        },
        activeFilters(state) {
            return state.filters.filter(o => o.value !== undefined && o.value !== '')
        },
        isFilterActive(state, getters) {
            return (
                state.searchTerm
                || getters.activeFilters.length > 0
            )
        },
        visibleColumnIds(state, getters, rootState, rootGetters) {
            let data = []
            
            // The component's fields have duplicate display orders across the different fieldsets. E.g. name => 1,
            // createdDate => 1, so they must be ordered by fieldset.
            let allFields = rootGetters['componentStructure/get'](state.componentId)
            
            if (rootState.user.user.administrator === 0) {
                allFields = allFields.filter(o => ['administrator', 'assume'].indexOf(o.columnName) === -1)
            }
            
            rootState.fieldsets.items
                // The fieldsets are returned ordered by display order, so collect the fields associated to each one.
                .filter(o => o.componentId === state.componentId)
                .forEach(o => {
                    const fieldsetId = o.id
                    const fields = allFields.filter(o => o.fieldsetId === fieldsetId && state.columns.indexOf(o.id) > -1)
                    data.push(...fields)
                })
            
            // Now add the fields which aren't assigned to a fieldset e.g. Display Order
            const fields = allFields.filter(o => o.fieldsetId === 0 && state.columns.indexOf(o.id) > -1)
            data.push(...fields)
            
            return data.map(a => a.id)
        },
        shareUrl(state, getters) {
            let url = window.location.origin + '/#/'
            
            url += state.tableName + '/'
            
            const params = {
                pageSize: state.pageSize,
                viewMode: state.viewMode
            }
            
            if (state.page > 1) {
                params.page = state.page
            }
            
            if (state.orderBy) {
                params.sort = state.orderDirection === 'Desc' ? '-' : ''
                params.sort += state.orderBy
            }
            
            if (state.searchColumn) {
                params.searchColumn = state.searchColumn
            }
            
            if (state.searchTerm) {
                params.searchTerm = state.searchTerm
            }
            
            if (state.showArchive) {
                params.showArchive = 1
            }
            
            if (getters.activeFilters) {
                getters.activeFilters.forEach(o => {
                    if (o.predicate === '=') {
                        params[o.columnName] = o.value
                    } else {
                        params[o.columnName] = {}
                        params[o.columnName][o.predicate] = o.value
                    }
                })
            }
            
            if (Object.keys(params).length > 0) {
                //url += '?' + new URLSearchParams(params)
                url += '?' + param(params)
            }
            
            return url
        }
    },
    actions: {
        init({rootState, state, commit, dispatch, rootGetters}, {
            listingName,
            selectedIds,
            hiddenFilters,
            selectMode,
            onSelect,
            query
        }) {
            // Listing names are in the format "view:<tableName>[.<namespace>]"
            // E.g. "view:articles" and "view:articles.author"
            // namespaces are used to isolate the view's settings from the main component's view.
            state.listingName = listingName
            
            state.tableName = state.listingName.split('.')[0]
            state.componentId = rootState.components.componentIds[state.tableName]
            state.namespace = state.listingName.split('.')[1]
            state.isNamespaced = state.listingName.indexOf('.') > -1
            
            const settings = JSON.parse(localStorage.getItem(state.listingName) || '{}')
            
            // Expands the search toolbar on page load.
            if (settings.showSearchToolbar) {
                dispatch('toggleSearchToolbar')
            }
            
            // Avoids adding duplicates when moving back and forth between components
            if (settings.filters && Object.keys(state.filters).length === 0) {
                settings.filters.forEach(o => dispatch('addFilter', o))
            }
            
            state.savedFilters = settings.savedFilters || []
            
            // These must default to the state's default above.
            state.selectedIds = selectedIds || []
            state.hiddenFilters = hiddenFilters || {}
            state.selectMode = selectMode || false
            state.onSelect = onSelect || undefined
            //state.showArchive = showArchive; // todo - The default is false and SelectListModal sets it as false,
            
            const allColumns = rootGetters['componentStructure/get'](state.componentId)
            
            // Default columns
            let columnIds = allColumns.filter(o => o.browser).map(o => o.id)
            if (settings.columns) {
                // Ensure that the columns in settings exist. Fixes issue when a column is deleted
                const allColumnIds = allColumns.map(o => o.id)
                columnIds = settings.columns.filter(id => allColumnIds.indexOf(id) > -1)
            }
            dispatch('setColumns', columnIds)
            
            const primaryKey = state.tableName + 'ID'
            if (
                settings.orderBy
                // Ensures the column exists. Prevents errors when targeted column is deleted
                && (
                    allColumns.find(o => o.columnName === settings.orderBy)
                    || settings.orderBy === primaryKey
                )
                && !query?.sort
            ) {
                dispatch('setOrderBy', settings.orderBy)
            } else {
                dispatch('setOrderBy', state.tableName + 'ID')
                dispatch('setOrderDirection', 'Desc')
            }
            
            if (settings.orderDirection && !query?.sort) {
                dispatch('setOrderDirection', settings.orderDirection)
            }
            
            if (settings.pageSize && !query?.pageSize) {
                dispatch('setPageSize', settings.pageSize)
            }
            
            if (settings.viewMode && !query?.viewMode) {
                dispatch('setViewMode', settings.viewMode)
            }
            
            if (query && Object.keys(query).length > 0) {
                commit('setPage', query.page || state.page || 0)
                state.searchColumn = query.searchColumn || state.searchColumn
                state.showArchive = !!query.showArchive
                
                if (query.searchTerm) {
                    commit('searchTerm', query.searchTerm)
                }
                
                if (query.pageSize) {
                    dispatch('setPageSize', parseInt(query.pageSize))
                    delete query.pageSize
                }
                
                if (query.viewMode) {
                    dispatch('setViewMode', query.viewMode)
                    delete query.viewMode
                }
                
                let sort = query.sort
                if (sort) {
                    dispatch('setOrderBy', sort?.indexOf('-') === 0 ? sort.substr(1) : sort)
                    dispatch('setOrderDirection', sort?.indexOf('-') === 0 ? 'Desc' : 'Asc')
                    delete query.sort
                }
                
                // Convert the remaining params into filters
                if (Object.keys(query).length > 0) {
                    let doReset = true
                    const columns = rootGetters['componentStructure/get'](state.componentId)
                    for (let columnName in query) {
                        if (query.hasOwnProperty(columnName)) {
                            const value = query[columnName]
                            
                            let predicate
                            if (columnName.indexOf('[') > -1) {
                                const parts = columnName.split(/[\[\]]/) // foo[gt] => ['foo', 'gt', '']
                                columnName = parts[0]
                                predicate = parts[1]
                            } else {
                                predicate = '='
                            }
                            
                            let type
                            if (columnName === state.tableName + 'ID') {
                                // todo This could be set to different predicate
                                type = 'number'
                            } else {
                                const column = columns.find(o => o.columnName === columnName)
                                if (!column) {
                                    console.warn(columnName + ' not found.')
                                    continue
                                }
                                type = column.type
                            }
                            
                            if (doReset) {
                                doReset = false
                                dispatch('deleteAllFilters')
                            }
                            
                            const typeOptions = rootGetters['advancedFilters/typesOptions'](type)
                            const option = typeOptions.find(o => o.predicate === predicate)
                            dispatch('addFilter', {
                                columnName: columnName,
                                type: type,
                                condition: option.condition,
                                predicate: predicate,
                                conditionText: option.text,
                                value: value
                            })
                        }
                    }
                }
            }
            
            dispatch('scheduleLoad')
        },
        scheduleLoad({state, dispatch}) {
            
            // This is required so that Vue doesn't show the cached version of a listing before it updates.
            // Without this, when in in product_options clicking in and out of different option's variables the previous
            // option's variables are presented for a split second.
            // todo - I imagine this hack wouldn't be need if the state and component were working in a proper Vue way.
            state.ids = []
            
            if (state.loadTimeout) {
                clearTimeout(state.loadTimeout)
            }
            
            // If using the pageSize input, and repeatedly clicking the up arrow, 250 reduces the number of requests.
            let delay = 250
            
            state.loadTimeout = setTimeout(() => {
                dispatch('load')
            }, delay)
        },
        load({state, dispatch, rootState, rootGetters}) {
            
            if (state.tableName) {
                if (
                    state.tableName === 'product_options'
                    || state.tableName === 'product_variables'
                ) {
                    rootState.navigationComponent = 'product_types'
                } else if (state.tableName.indexOf('product_variations__') === 0) {
                    rootState.navigationComponent = 'm_products_products'
                }
            }
            
            dispatch('modifiedItems/clearModifiedItems', {}, {root: true})
            
            const searchParams = state.hiddenFilters
            
            // Franchise filtering
            // Only implement franchiseId filtering if the user has a franchiseId. The franchiseId field in the
            // Users component should be mandatory, so that all users of sites which have the field will filter.
            if (rootState.user.user.franchiseId && state.tableName !== 'users') {
                
                // Franchise ID filter - Where franchiseId is a column of the current table
                const hasFranchiseIdColumn = !!rootGetters['componentStructure/get'](state.componentId)
                    .find(o => o.columnName === 'franchiseId')
                
                if (hasFranchiseIdColumn) {
                    searchParams.franchiseId = rootState.user.user.franchiseId
                    dispatch('sendLoadViewRequest', {searchParams})
                    return
                }
                
                const franchiseObjs = []
                
                // Used to prevent the number of times findFranchiseComponent is called recursively.
                // Setting maxLevel to 2 supports ODP's Bookings.
                // I.e. Bookings -> Course dates -> Courses
                let level = 0
                const maxLevel = 1
                
                // Recursively descends through components' relationshipOneToMany fields in search of a
                // franchiseId column. E.g.
                //   odp_course_bookings.courseDateId
                //     -> odp_course_dates.courseId
                //     -> odp_courses.franchiseId
                // Whilst doing so, it retains the table and column names of components it searches. E.g.
                //   const franchiseObjs = [
                //     { tableName: 'odp_course_bookings', columnName: 'courseDateId' },
                //     { tableName: 'odp_course_dates', columnName: 'courseId' },
                //   ]
                // These are then used for sourcing IDs in order to filter the current component. For example,
                //   1. IDs from odp_courses are loaded where franchiseId = <user's franchiseId>.
                //   2. The Courses IDs are then used to load IDs from odp_course_dates where
                //      odp_course_dates.courseId = <odp_courses IDs>
                //   3. The Course Dates IDs are then provided in the listing's searchParams to filter the
                //      current listing. E.g. searchParams.courseDateId = <odp_course_dates IDs>
                function findFranchiseComponent(componentId) {
                    const tableName = rootState.components.items.find(o => o.id === componentId).tableName
                    
                    const objs = rootGetters['componentStructure/get'](componentId)
                    
                    // Once a franchiseId column is found, stop the recursive process and apply the
                    // filter.
                    const franchiseIdColumn = objs.find(o => o.columnName === 'franchiseId')
                    if (franchiseIdColumn) {
                        applyFranchiseFilter(tableName, 'franchiseId', rootState.user.user.franchiseId)
                        return
                    }
                    
                    // Until a franchiseId column is found, or the number of recursions meets maxLevel,
                    // search each component for a required relationshipOneToMany field and check the
                    // fields target component for a franchiseId field.
                    const item = objs.find(o => o.type === 'relationshipOneToMany' && o.required)
                    if (item) {
                        franchiseObjs.push({
                            tableName,
                            columnName: item.columnName
                        })
                        
                        if (level <= maxLevel) {
                            level++
                            findFranchiseComponent(item.categoryComponentId)
                        } else {
                            dispatch('sendLoadViewRequest', {searchParams})
                        }
                    } else {
                        dispatch('sendLoadViewRequest', {searchParams})
                    }
                }
                
                // Initially called by findFranchiseComponent, once it locates a component with a franchiseId
                // column, it loads IDs from tableName where franchiseId = the user's franchiseId.
                // If franchiseObjs is populated it then recursively
                function applyFranchiseFilter(tableName, columnName, ids) {
                    // If there are no more levels, then this is the currently loaded component e.g. Bookings.
                    // In which case, filter its results using the IDs e.g. searchParams.courseDateId => ids
                    if (franchiseObjs.length === 0) {
                        searchParams[columnName] = ids
                        dispatch('sendLoadViewRequest', {searchParams})
                        return
                    }
                    
                    dispatch('request/post', {
                        url: 'api/component/' + tableName,
                        postData: {
                            field: 'id',
                            [columnName]: ids,
                            //isArchived: 0
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                        .then((o) => {
                            const ids = o.data.values
                            const obj = franchiseObjs.pop()
                            applyFranchiseFilter(obj.tableName, obj.columnName, ids)
                        })
                }
                
                findFranchiseComponent(state.componentId)
                return
            }
            
            dispatch('sendLoadViewRequest', {searchParams})
        },
        sendLoadViewRequest({state, dispatch, getters, rootState, rootGetters}, {searchParams}) {
            const postData = {
                field: 'id',
                //__itemCount: true
                __getAllIds: true
            }
            
            const component = rootState.components.items.find(o => o.tableName === state.tableName)
            
            // Only apply the filter if the feature is enabled.
            // Prevents isArchived: 0 generating errors on components without an isArchived column
            if (component.useArchive) {
                postData.isArchived = state.showArchive ? 1 : 0
            }
            
            const start = state.page - 1
            if (start) {
                postData.start = start * state.pageSize
            }
            
            postData.limit = state.pageSize
            
            if (
                state.orderBy
                // Addresses issue when sort by column is deleted from a component
                && (
                    state.orderBy === state.tableName + 'ID'
                    || rootGetters['componentStructure/get'](state.componentId).find(o => o.columnName === state.orderBy)
                )
            ) {
                const sortPrefix = state.orderDirection === 'Desc' ? '-' : ''
                postData.sort = sortPrefix + state.orderBy
            }
            
            if (state.searchTerm) {
                postData.search = state.searchTerm
                
                if (state.searchColumn) {
                    postData.searchColumn = state.searchColumn
                }
            }
            
            const filters = {}
            Object.assign(filters, searchParams)
            if (Object.keys(filters).length) {
                postData.filters = filters
            }
            
            if (getters.activeFilters) {
                getters.activeFilters.forEach(o => {
                    if (o.predicate === '=') {
                        postData[o.columnName] = o.value
                    } else {
                        postData[o.columnName] = {}
                        postData[o.columnName][o.predicate] = o.value
                    }
                })
            }
            
            dispatch('request/post', {
                url: 'api/component/' + state.tableName,
                postData: postData,
                customHeaders: {
                    'X-Http-Method-Override': 'GET'
                }
            }, {root: true})
                .then((obj) => {
                    let ids = obj.data.values
                    //let itemCount = obj.data.itemCount
                    state.allIds = obj.data.allIds
                    
                    dispatch('itemData/preload', {
                        tableName: state.tableName,
                        ids: ids,
                    }, {root: true})
                        .then((objs) => {
                            return dispatch('preloadViewItemData/get', {
                                tableName: state.tableName,
                                visibleColumns: state.columns,
                                objs: objs,
                            }, {root: true})
                        })
                        .then((o) => {
                            state.ids = ids
                            //state.itemCount = itemCount
                            state.itemCount = state.allIds.length
                        })
                })
        },
        async toggleShowArchive({commit, dispatch}) {
            commit('toggleShowArchive')
            await dispatch('setLocalStorage')
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        async setPageSize({commit, dispatch}, pageSize) {
            commit('setPageSize', pageSize)
            await dispatch('setLocalStorage')
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        async setOrderBy({commit, dispatch}, orderBy) {
            commit('setOrderBy', orderBy)
            await dispatch('setLocalStorage')
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        async setOrderDirection({commit, dispatch}, orderDirection) {
            commit('setOrderDirection', orderDirection)
            await dispatch('setLocalStorage')
            dispatch('scheduleLoad')
        },
        async setViewMode({commit, dispatch}, viewMode) {
            commit('setViewMode', viewMode)
            await dispatch('setLocalStorage')
            dispatch('setLocation')
        },
        setColumns({commit, dispatch}, columns) {
            commit('setColumns', columns)
            dispatch('setLocalStorage')
        },
        async setPage({commit, dispatch}, n) {
            commit('setPage', n)
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        async pageDown({commit, dispatch}) {
            commit('pageDown')
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        async pageUp({commit, dispatch}) {
            commit('pageUp')
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        toggleSearchToolbar({commit, dispatch}) {
            commit('toggleSearchToolbar')
            dispatch('setLocalStorage')
        },
        async searchTerm({commit, dispatch}, str) {
            commit('searchTerm', str)
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        addFilter({state, commit, dispatch}, o) {
            if (!o) {
                o = {
                    columnName: state.tableName + 'ID',
                    type: 'number',
                    condition: 'is',
                    predicate: '=',
                    conditionText: 'is',
                    value: '' // Must match the value of an empty number input, which is an empty string.
                }
            }
            
            // If no value was set, set the value property to activate Vue's data binding.
            // Without this, changes to value won't be detected.
            if (o.value === undefined) o.value = undefined
            
            commit('addFilter', o)
            dispatch('setLocalStorage')
        },
        async setFilterProp({commit, dispatch}, {key, name, value}) {
            commit('setFilterProp', {key, name, value})
            await dispatch('setLocalStorage')
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        async deleteFilter({commit, dispatch}, key) {
            commit('deleteFilter', key)
            await dispatch('setLocalStorage')
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        deleteAllFilters({commit, dispatch}) {
            commit('deleteAllFilters')
            dispatch('setLocalStorage')
        },
        async resetFilter({commit, dispatch}, key) {
            commit('resetFilter', key)
            await dispatch('setLocalStorage')
            await dispatch('setLocation')
            dispatch('scheduleLoad')
        },
        async resetAllFilters({commit, dispatch}) {
            commit('resetAllFilters')
            await dispatch('setLocalStorage')
            dispatch('scheduleLoad')
        },
        saveFilters({commit, dispatch}, name) {
            commit('saveFilters', name)
            dispatch('setLocalStorage')
        },
        deleteSavedFilter({commit, dispatch}, name) {
            commit('deleteSavedFilter', name)
            dispatch('setLocalStorage')
        },
        setLocalStorage({state}) {
            localStorage.setItem(state.listingName, JSON.stringify({
                pageSize: state.pageSize,
                orderBy: state.orderBy,
                orderDirection: state.orderDirection,
                viewMode: state.viewMode,
                columns: state.columns,
                showArchive: state.showArchive,
                showSearchToolbar: state.showSearchToolbar,
                filters: state.filters,
                savedFilters: state.savedFilters
            }))
        },
        setLocation({state, getters}) {
            if (!state.isNamespaced) {
                window.location = getters.shareUrl
            }
        },
        loadSavedFilter({state, dispatch}, name) {
            // Remove all existing filters
            state.filters.splice(0)
            
            const savedFilter = state.savedFilters.find(o => o.name === name)
            savedFilter.filters.forEach(o => dispatch('addFilter', JSON.parse(JSON.stringify(o))))
            
            dispatch('scheduleLoad')
            dispatch('setLocation')
        },
        onClick({state, commit}, id) {
            // Clicking rows inside the SelectListModal are treated the same as meta clicking.
            if (state.onSelect) {
                commit('onMetaClick', id)
                state.onSelect(state.selectedIds)
            } else {
                let i = state.selectedIds.indexOf(id)
                if (
                    i === -1
                    || state.allSelected
                    || state.selectedIds.length > 1
                ) {
                    state.selectedIds = [id]
                } else {
                    state.selectedIds = []
                }
                
                state.allSelected = false
            }
        },
        onDblClick({state, rootGetters}) {
            if (
                !state.onSelect
                && !state.selectMode
                && state.tableName.indexOf('product_variations__') !== 0
            ) {
                return rootGetters['user/access'](state.tableName, 'edit')
            }
        }
    }
}