var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.sectionTemplateId,
          expression: "sectionTemplateId",
        },
      ],
      staticClass: "form-select form-select-sm w-auto",
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.sectionTemplateId = $event.target.multiple
            ? $$selectedVal
            : $$selectedVal[0]
        },
      },
    },
    [
      _c("option", { attrs: { value: "", selected: "", disabled: "" } }, [
        _vm._v("Select…"),
      ]),
      _vm._v(" "),
      _vm._l(_vm.pageSectionTemplates, function (o) {
        return _c(
          "option",
          {
            key: o.id,
            domProps: { value: o.id, selected: o.id === _vm.sectionTemplateId },
          },
          [_vm._v("\n        " + _vm._s(o.title) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }