var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass:
        "list-group-item list-group-item-action py-1 small d-inline-flex align-items-center gap-1",
      class: _vm.classNames,
      attrs: { role: "button" },
      on: {
        mouseover: _vm.mouseoverContent,
        mouseout: _vm.mouseoutContent,
        click: _vm.selectContent,
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "flex-fill small ps-2",
          class: { "ps-3": _vm.isSectionContent },
        },
        [_vm._v(_vm._s(_vm.contentTypeTitle))]
      ),
      _vm._v(" "),
      _c("ItemStatusIcons", {
        attrs: {
          "table-name": "content",
          id: _vm.contentId,
          "is-active": _vm.isActive,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }