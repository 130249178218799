<template>
    <Button class="border" data-bs-toggle="offcanvas" data-bs-target="#contentTypeFieldsetOffcanvas"
            aria-controls="contentTypeFieldsetOffcanvas">
        <i class="bi bi-plus-lg"/>
        Fieldset
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ContentTypeNewFieldsetBtn",
    components: {Button}
}
</script>

<style scoped>

</style>