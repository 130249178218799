<template>
    <Button v-if="!hide" :disabled="isSaving" title="Save" @click.native="onClick" :default-class="defaultBtnClass"
            :active-class="'btn-warning'" :active-value="isModified">
        <span v-if="buttonText" v-html="buttonText"/>
        <span v-else class="bi-save"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "FormSaveBtn",
    components: {Button},
    props: {
        formId: String,
        buttonText: String,
        // todo - The onSave script should not be part of this button. The VueX form module should instead have an
        //        onSave option which all instances of the form should use. This option has been added to suppress this
        //        component's onSave event.
        cancelOnSave: Boolean,
        defaultBtnClass: String
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        isModified() {
            return this.$store.getters[this.formId + '/isModified']()
        },
        isSaving() {
            return this.$store.state[this.formId].isSaving
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        hide() {
            return this.tableName === 'files'
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch(this.formId + '/save')
                .then(o => {
                    // The form VueX module's save action will return invalid: true if validation fails.
                    // If the form's validation fails don't redirect.
                    if (!o?.invalid && !this.cancelOnSave) {
                        this.onSave(o)
                    }
                })
        },
        onSave(item) {
            if (this.tableName.indexOf('product_variations__') === 0) {
                window.location = '/#/' + this.tableName + '?productId=' + this.currentData.productId
            }
            // Saving a new item, redirect to the item's form.
            else if (
                this.$store.state[this.formId]?.id === 0
                && item.id
            ) {
                switch (true) {
                    // Saving a new component attribute
                    case this.$route.name === 'componentFields':
                        this.$store.dispatch('componentFields/setItems')
                        this.$router.push({params: {fieldId: item.id}})
                        break

                    // Saving a new content type field
                    case (
                        this.$route.name === 'themesEditor'
                        && this.$route.query.section === 'content_types'
                    ):
                        this.$store.dispatch('componentFields/setItems')
                        this.$store.commit('componentFields/activeEditId', item.id)
                        break

                    default:
                        this.$router.push({
                            name: 'form',
                            params: {id: item.id},
                            query: this.$route.query // Retain the query string
                        })
                }
            }
        }
    }
}
</script>

<style scoped>

</style>