<template>
    <iframe v-if="iframeSrcdoc" ref="iframe" :srcdoc="iframeSrcdoc" @load="init" class="flex-fill"></iframe>
</template>

<script>
export default {
    name: "PageEditorIframe",
    props: {
        pageId: Number
    },
    data() {
        return {
            iframeSrcdoc: ''
        }
    },
    async created() {
        this.iframeSrcdoc = (await this.$store.dispatch('request/get', {
            url: 'api/page-editor/' + this.pageId
        })).data.html
    },
    destroyed() {
        this.$store.dispatch('pageEditor/unset')
    },
    methods: {
        async init() {
            const params = this.$route.params

            await this.$store.dispatch('pageEditor/init', {
                router: this.$router,
                iframe: this.$refs.iframe,
                sectionId: parseInt(params.sectionId || 0),
                areaIndex: parseInt(params.areaIndex || -1),
                pageContentId: parseInt(params.pageContentId || 0),
                onSectionDelete: () => {
                    this.$router.push({name: 'pageEditor', params: {pageId: this.pageId}})
                }
            })
        }
    }
}
</script>

<style scoped>

</style>