<template>
    <div>
        <div v-for="modal in modals" :key="modal.index" :class="{ in: modal.show }" class="modal" role="dialog"
             style="display: block" tabindex="-1" @click.self="hideModal(modal.index)">
            <component :is="modal.componentName" :modal="modal" :options="modal.options"
                       @hide-modal="hideModal(modal.index)"/>
        </div>

        <div v-for="modal in modals" class="modal-backdrop show"></div>
    </div>
</template>

<script>
import AddContentModal from './AddContentModal'
import AddItemModal from './AddItemModal'
import BulkUploadModal from './BulkUploadModal'
import CodeMirrorModal from './CodeMirrorModal'
import ConfirmModal from './ConfirmModal'
import ContentTypeFieldsModal from '../../common/content-type/ContentTypeFieldsModal'
import HistoryCodeEditorModal from '../../../src/common/item-history/HistoryCodeEditorModal'
import HistoryTexteditorModal from '../../../src/common/item-history/HistoryTexteditorModal'
import ImageSelectionModal from './ImageSelectionModal'
import ImportModal from './ImportModal'
import LightboxModal from './LightboxModal'
import ListingColumnsModal from './ListingColumnsModal'
import NewContentTypeModal from '../../../src/common/content-type/NewContentTypeModal'
import OdpAddPaymentModal from '../../bespoke/odp/OdpAddPaymentModal'
import OdpAddCreditModal from '../../bespoke/odp/OdpAddCreditModal'
import OdpCreditHistoryModal from '../../bespoke/odp/OdpCreditHistoryModal'
import OdpDeleteCreditModal from '../../bespoke/odp/OdpDeleteCreditModal'
import OdpPaymentLogModal from '../../bespoke/odp/OdpPaymentLogModal'
import OdpPaymentNoteModal from '../../bespoke/odp/OdpPaymentNoteModal'
import OrdersLogsModal from './OrdersLogsModal'
import OrdersReportsModal from './OrdersReportsModal'
import PrintReportModal from '../../components/reports/PrintReportModal'
import SearchModal from './SearchModal'
import SelectListModal from './SelectListModal'
import ShowNotesModal from '../../bespoke/flyspain/ShowNotesModal'
import SiteTreeModal from './SiteTreeModal'
import TagsModal from '../../../src/common/tags/TagsModal'
import ThemesEditorAddModal from './ThemesEditorAddModal'
import ThemesFindModal from './ThemesFindModal'
import ThemesUsageModal from './ThemesUsageModal'
import ZendeskModal from './ZendeskModal'

export default {
    name: "Modals",
    components: {
        AddContentModal,
        AddItemModal,
        BulkUploadModal,
        CodeMirrorModal,
        ConfirmModal,
        ContentTypeFieldsModal,
        HistoryCodeEditorModal,
        HistoryTexteditorModal,
        ImageSelectionModal,
        ImportModal,
        ListingColumnsModal,
        LightboxModal,
        NewContentTypeModal,
        OdpAddPaymentModal,
        OdpAddCreditModal,
        OdpCreditHistoryModal,
        OdpDeleteCreditModal,
        OdpPaymentLogModal,
        OdpPaymentNoteModal,
        OrdersLogsModal,
        OrdersReportsModal,
        PrintReportModal,
        SearchModal,
        SelectListModal,
        ShowNotesModal,
        SiteTreeModal,
        TagsModal,
        ThemesEditorAddModal,
        ThemesFindModal,
        ThemesUsageModal,
        ZendeskModal,
    },
    computed: {
        modals() {
            return this.$store.state.modals.modals
        }
    },
    methods: {
        hideModal(index) {
            this.$store.dispatch('modals/remove', index)
        },
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                this.$store.commit('modals/pop')
            }
        })
    }
}
</script>

<style scoped>

</style>