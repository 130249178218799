var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.area
    ? _c(
        "a",
        {
          staticClass:
            "list-group-item list-group-item-action py-1 ps-0 strong text-uppercase small",
          class: _vm.classNames,
          attrs: { role: "button" },
          on: {
            mouseover: _vm.onMouseover,
            mouseout: _vm.onMouseout,
            click: _vm.onClick,
          },
        },
        [
          _c("small", { staticClass: "ps-3 flex-fill" }, [
            _vm._v(_vm._s(_vm.area.label)),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }