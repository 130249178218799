var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "editorToolbar" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm",
        on: {
          mousedown: function ($event) {
            $event.preventDefault()
            return _vm.applyFormat("b")
          },
        },
      },
      [_c("i", { staticClass: "bi bi-type-bold" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm",
        on: {
          mousedown: function ($event) {
            $event.preventDefault()
            return _vm.applyFormat("i")
          },
        },
      },
      [_c("i", { staticClass: "bi bi-type-italic" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm",
        on: {
          mousedown: function ($event) {
            $event.preventDefault()
            return _vm.applyFormat("ul")
          },
        },
      },
      [_c("i", { staticClass: "bi bi-list-ul" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm",
        on: {
          mousedown: function ($event) {
            $event.preventDefault()
            return _vm.applyFormat("ol")
          },
        },
      },
      [_c("i", { staticClass: "bi bi-list-ol" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }