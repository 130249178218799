var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-group list-group-flush" },
    [
      [
        _c("PageEditorContentNavContentArea", {
          attrs: { "content-area-name": "Page header" },
        }),
        _vm._v(" "),
        _c("PageEditorContentNavContent", {
          attrs: { "content-area-name": "Page header" },
        }),
      ],
      _vm._v(" "),
      _vm._l(_vm.pageSections, function (section, k) {
        return [
          _c("PageEditorContentNavSection", {
            attrs: { "section-id": section.id },
          }),
          _vm._v(" "),
          _vm._l(_vm.sectionAreaNames(section.id), function (areaName, k) {
            return [
              _c("PageEditorContentNavContentArea", {
                attrs: { "content-area-name": areaName },
              }),
              _vm._v(" "),
              _c("PageEditorContentNavContent", {
                attrs: { "content-area-name": areaName },
              }),
            ]
          }),
        ]
      }),
      _vm._v(" "),
      _vm._l(_vm.contentAreaNames, function (contentAreaName) {
        return [
          _c("PageEditorContentNavContentArea", {
            attrs: { "content-area-name": contentAreaName },
          }),
          _vm._v(" "),
          _c("PageEditorContentNavContent", {
            attrs: { "content-area-name": contentAreaName },
          }),
        ]
      }),
      _vm._v(" "),
      [
        _c("PageEditorContentNavContentArea", {
          attrs: { "content-area-name": "Page footer" },
        }),
        _vm._v(" "),
        _c("PageEditorContentNavContent", {
          attrs: { "content-area-name": "Page footer" },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }