<template>
    <Button v-if="show" @click.native="login" class="btn-light border">Login</Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "FormControlMRegistrantsRegistrantsLogin",
    components: {Button},
    props: {
        currentData: Object,
        field: Object
    },
    computed: {
        show() {
            return this.accessGroups && this.currentData?.username.trim().length > 0
        }
    },
    asyncComputed: {
        async categoryIds() {
            const o = await this.$store.dispatch('categories/getTableColumnData', {
                tableName: 'm_registrants_registrants',
                columnName: 'contactGroup'
            })

            return o.filter(o => o.itemId === this.currentData.id)?.map(o => o.catId)
        },
        async accessGroups() {
            if (this.categoryIds) {
                const o = await this.$store.dispatch('itemData/preload', {
                    tableName: 'm_registrants_groups',
                    ids: this.categoryIds,
                })

                return o.filter(o => !!o.accessGroup).length > 0
            }
        }
    },
    watch: {
        show() {
            this.field.visibility = this.show ? '' : 'hidden'
        }
    },
    methods: {
        async login() {
            const o = await this.$store.dispatch('request/get', {
                url: 'api/customer/get-auto-login-key',
                params: {customerId: this.currentData.id}
            })

            if (o.data.key) {
                window.open(this.$store.state.websiteEndpoint + '/controller/Customers/autoLogin/' + o.data.key)
            }
        }
    }
}
</script>

<style scoped>

</style>