import { render, staticRenderFns } from "./TableCellTypeColor.vue?vue&type=template&id=526c0eca&scoped=true"
import script from "./TableCellTypeColor.vue?vue&type=script&lang=js"
export * from "./TableCellTypeColor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526c0eca",
  null
  
)

export default component.exports