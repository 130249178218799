<template>
    <a role="button" class="list-group-item list-group-item-action py-1 small d-inline-flex align-items-center gap-1"
       :class="classNames" @mouseover="mouseoverContent" @mouseout="mouseoutContent" @click="selectContent">
        <span class="flex-fill small ps-2" :class="{'ps-3': isSectionContent}">{{ contentTypeTitle }}</span>

        <ItemStatusIcons :table-name="'content'" :id="contentId" :is-active="isActive"/>
    </a>
</template>

<script>
import ItemStatusIcons from "../../common/ItemStatusIcons.vue"

export default {
    name: "PageEditorContentNavContentItem",
    components: {ItemStatusIcons},
    props: {
        pageContentId: Number
    },
    computed: {
        contentId() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)?.contentId
        },
        contentTypeTitle() {
            return this.$store.getters['templates/contentTypes'].find(o => o.id === this.content?.contentType)?.title
        },
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        hoverPageContentId() {
            return this.pageEditor.hoverPageContentId
        },
        isActive() {
            return this.pageContentId === this.activePageContentId
        },
        contentItem() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.pageContentId)
        },
        isSectionContent() {
            return this.$store.getters['pageEditor/isSectionContent'](this.pageContentId)
        },
        classNames() {
            return [
                this.pageContentId === this.activePageContentId ? 'active' :
                    this.pageContentId === this.hoverPageContentId ? 'list-group-item-hover' : ''
            ]
                .filter(Boolean) // Removes empty values (false or '')
                .join(' ');
        }
    },
    asyncComputed: {
        content() {
            if (this.contentId) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.contentId
                })
            }
        }
    },
    methods: {
        mouseoverContent() {
            this.$store.commit('pageEditor/hoverPageContentId', this.pageContentId)
        },
        mouseoutContent() {
            this.$store.commit('pageEditor/hoverPageContentId', 0)
        },
        async selectContent() {
            await this.$store.dispatch('pageEditor/goToContent', {
                router: this.$router,
                pageContentId: this.pageContentId
            })

            await this.$store.dispatch('pageEditor/scrollToContent', {pageContentId: this.pageContentId})
        }
    }
}
</script>

<style scoped>
.list-group-item {
    border-color: transparent !important;
}

.list-group-item-hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: rgba(9, 110, 253, 0.2);
}
</style>