<template>
    <Button v-if="show" @click.native="onClick" class="btn-light btn-sm border">Login</Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "TableCellCustomersLogin",
    components: {Button},
    props: {
        item: Object
    },
    computed: {
        show() {
            if (this.categoryIds?.length) {
                return this.$store.state.accessGroups.items.map(o => o.id)
                    .some(item => this.categoryIds.includes(item))
            }
        }
    },
    asyncComputed: {
        async categoryIds() {
            if (this.item.username?.trim().length > 0) {
                const o = await this.$store.dispatch('categories/getTableColumnData', {
                    tableName: 'm_registrants_registrants',
                    columnName: 'contactGroup'
                })

                return o.filter(o => o.itemId === this.item.id).map(o => o.catId)
            }
        }
    },
    methods: {
        onClick() {
            const url = 'api/customer/get-auto-login-key?customerId=' + this.item.id
            this.$store.dispatch('request/get', {url})
                .then(o => {
                    if (o.data.key) {
                        let url = this.$store.state.websiteEndpoint + '/controller/Customers/autoLogin/' + o.data.key
                        window.open(url)
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>