<template>
    <div>
        <ul class="list-group mb-2">
            <li v-for="item in items" :key="item.id" class="list-group-item d-flex align-items-center"
                :class="{'py-1 px-2 small':formSmall}">
                <div class="flex-fill">
                    <PagePath :page-id="item.pageId" class="me-2"/>
                    {{ item.contentArea }}
                </div>

                <Button class="btn-sm" @click.native="goToPage(item.pageId)"><i class="bi-pencil-square"/></Button>
            </li>
        </ul>

        <Button @click.native="showSiteTree" class="btn-sm border"><i class="bi bi-plus-lg"/></Button>
    </div>
</template>

<script>
import PagePath from '../../common/PagePath'
import Button from "../Button.vue"

export default {
    name: "FormControlContentPageUse",
    components: {Button, PagePath},
    props: {
        formId: String,
        currentData: Object,
        field: Object
    },
    computed: {
        contentId() {
            return this.currentData.id
        },
        items() {
            return this.$store.state.pageContentData.items.filter(o => o.contentId === this.contentId)
        },
        formSmall() {
            return this.$store.state[this.formId].formSmall
        },
        isSiteTree() {
            return this.$route.name === 'siteTree'
        },
        isPageEditor() {
            return this.$route.name === 'pageEditor'
        }
    },
    methods: {
        goToPage(pageId) {
            if (this.isPageEditor) {
                this.$store.dispatch('pageEditor/goToContent', {
                    router: this.$router,
                    pageContentId: this.items.find(o => o.pageId === pageId).id,
                    pageId: pageId
                })
            } else if (this.isSiteTree) {
                this.$router.push({name: 'siteTree', params: {pageOrContentId: pageId}})
            } else {
                this.$router.push({name: 'form', params: {component: 'pages', id: pageId}})
            }
        },
        showSiteTree() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    propagatedContentId: this.contentId,
                    onSave: async (o) => {
                        const propagationData = o.propagationData
                        const newPageIds = propagationData.map(o => o.pageId)

                        const originalPages = this.$store.state.pageContentData.items.filter(o => o.contentId === this.contentId)
                        const originalPageIds = originalPages.map(o => o.pageId)

                        const addedPages = propagationData.filter(o => !originalPageIds.includes(o.pageId))
                        const removedPages = originalPages.filter(o => !newPageIds.includes(o.pageId))

                        for (const o of addedPages) {
                            const pageSectionLinkId = o.contentArea.startsWith('area_')
                                ? parseInt(o.contentArea.split('_')[1]) : null
                            
                            await this.$store.dispatch('request/post', {
                                url: 'api/save-content-to-page',
                                postData: {
                                    pageId: o.pageId,
                                    pageSectionLinkId,
                                    contentArea: o.contentArea,
                                    contentId: this.contentId
                                }
                            })
                        }

                        for (const o of removedPages) {
                            await this.$store.dispatch('request/delete', {
                                url: 'api/delete-content-from-page/' + o.pageId + '/' + this.contentId
                            })
                        }

                        await this.$store.dispatch('pageContentData/init')
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>