<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Usage</h4>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <ul class="list-group list-group-flush">
                    <li v-for="(content, index) in contentData" :key="index"
                        class="list-group-item d-flex align-items-center">
                        <div class="flex-fill">
                            {{ content.title }}
                        </div>

                        <Button class="btn-sm" @click.native="goToSiteTree(content.pageContentId)"
                                :class="{'invisible': !content.pageContentId}">
                            <i class="bi-diagram-3-fill"/>
                        </Button>

                        <Button class="btn-sm" @click.native="goToContent(content.id)">
                            <i class="bi-pencil-square"/>
                        </Button>

                        <Button @click.native="websiteHref(content.pageContentId)" class="btn-sm"
                                :class="{'invisible': !content.pageContentId}">
                            <i class="bi-globe"/>
                        </Button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "ThemesUsageModal",
    components: {Button},
    props: {
        options: Object
    },
    computed: {
        id() {
            return this.options.id
        }
    },
    asyncComputed: {
        async contentData() {
            const {items} = (await this.$store.dispatch('request/get', {
                url: 'api/component/content',
                params: {contentType: this.id}
            })).data

            return items.flatMap(({id, title}) => {
                const objs = this.$store.state.pageContentData.items.filter(o => o.contentId === id)
                return objs.length ? objs.map(o => ({id, pageContentId: o.id, title})) : [{id, title}]
            })
        }
    },
    methods: {
        goToSiteTree(pageContentId) {
            window.location = '/#/site_tree/c' + pageContentId
            this.$emit('hide-modal')
        },
        goToContent(contentId) {
            window.location = '/#/content/' + contentId
            this.$emit('hide-modal')
        },
        websiteHref(pageContentId) {
            const token = localStorage.getItem('token')
            const url = `${this.$store.state.ccmsSchemeAndHost}/controller/preview-open?pageContentId=${pageContentId}&token=${token}`

            window.open(url)
        }
    }
}
</script>

<style scoped>

</style>