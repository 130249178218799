<template>
    <div>
        <template v-if="contentAreas?.length">
            <PageContentMobileContentArea
                v-for="(contentArea, index) in contentAreas" :key="contentArea" :page-id="pageId"
                :content-area="contentArea" :content-area-first="index === 0"
                :content-area-last="index === contentAreas.length - 1"
                :content-areas="contentAreas"/>

            <PageContentOrphaned :page-id="pageId" :content-areas="contentAreas" class="mb-3"/>
        </template>
        <PageContentSections v-else-if="pageSectionsContentAreas?.length" :page-id="pageId"/>
    </div>
</template>

<script>
import PageContentMobileContentArea from './PageContentMobileContentArea'
import PageContentOrphaned from "./PageContentOrphaned.vue"
import PageContentSections from "./PageContentSections.vue"

export default {
    name: "PageContentMobile",
    components: {
        PageContentSections,
        PageContentOrphaned,
        PageContentMobileContentArea
    },
    props: {
        pageId: Number,
    },
    asyncComputed: {
        contentAreas() {
            return this.$store.dispatch('pageTemplatesContentAreas/getPagesContentAreas', this.pageId)
        },
        pageSectionsContentAreas() {
            return this.$store.dispatch('pageSectionsContentAreas/get', this.pageId)
        }
    }
}
</script>

<style scoped>

</style>