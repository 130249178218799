<template>
    <div>
        <label>Container</label>
        <select v-model="pageContainerId" class="form-select form-select-sm w-auto">
            <option value="" selected disabled>Select&hellip;</option>
            <option v-for="o in pageContainers" :key="o.id" :value="o.id" :selected="o.id === pageContainerId">
                {{ o.title }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "PageEditorSectionContainerMenu",
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageContainers() {
            return this.pageEditor.pageContainers
        },
        activeSectionId() {
            return this.pageEditor.activeSectionId
        },
        sectionData() {
            return this.pageEditor.pageSectionData?.find(o => o.id === this.activeSectionId)
        },
        pageContainerId: {
            get() {
                return this.sectionData?.pageContainerId || ''
            },
            set(newValue) {
                this.$store.dispatch('pageEditor/saveSectionContainer', newValue)
            }
        }
    }
}
</script>

<style scoped>

</style>