var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-group-item py-1 pe-2 d-flex align-items-center" },
    [
      _c("span", { staticClass: "flex-fill" }, [
        _vm._v(_vm._s(_vm.template?.title)),
      ]),
      _vm._v(" "),
      _c("Button", { staticClass: "btn-sm" }, [
        _c("i", { staticClass: "bi bi-pencil-square" }),
      ]),
      _vm._v(" "),
      _c("Button", { staticClass: "btn-sm" }, [
        _c("i", { staticClass: "bi bi-chevron-up" }),
      ]),
      _vm._v(" "),
      _c("Button", { staticClass: "btn-sm" }, [
        _c("i", { staticClass: "bi bi-chevron-down" }),
      ]),
      _vm._v(" "),
      _c("Button", { staticClass: "btn-sm" }, [
        _c("i", { staticClass: "bi bi-x-lg" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }