<template>
    <div class="list-group list-group-flush">
        <template>
            <PageEditorContentNavContentArea :content-area-name="'Page header'"/>
            <PageEditorContentNavContent :content-area-name="'Page header'"/>
        </template>

        <template v-for="(section, k) in pageSections">
            <PageEditorContentNavSection :section-id="section.id"/>
            <template v-for="(areaName, k) in sectionAreaNames(section.id)">
                <PageEditorContentNavContentArea :content-area-name="areaName"/>
                <PageEditorContentNavContent :content-area-name="areaName"/>
            </template>
        </template>

        <template v-for="contentAreaName in contentAreaNames">
            <PageEditorContentNavContentArea :content-area-name="contentAreaName"/>
            <PageEditorContentNavContent :content-area-name="contentAreaName"/>
        </template>

        <template>
            <PageEditorContentNavContentArea :content-area-name="'Page footer'"/>
            <PageEditorContentNavContent :content-area-name="'Page footer'"/>
        </template>
    </div>
</template>

<script>
import PageEditorContentNavContent from "./PageEditorContentNavContent"
import PageEditorContentNavContentArea from "./PageEditorContentNavContentArea.vue"
import PageEditorContentNavSection from "./PageEditorContentNavSection.vue"

export default {
    name: "PageEditorContentNav",
    components: {PageEditorContentNavSection, PageEditorContentNavContentArea, PageEditorContentNavContent},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageSections() {
            return this.pageEditor.pageSections
        },
        contentAreaNames() {
            return this.pageEditor.contentAreas
                .filter(o => o.name !== 'Page header' && o.name !== 'Page footer' && !o.sectionId)
                .map(o => o.name)
        }
    },
    methods: {
        sectionAreaNames(sectionId) {
            return this.pageEditor.contentAreas.filter(o => o.sectionId === sectionId).map(o => o.name)
        }
    }
}
</script>

<style scoped>
.list-group-item {
    border-color: transparent !important;
}
</style>