import { render, staticRenderFns } from "./ThemesEditor.vue?vue&type=template&id=80f11456&scoped=true"
import script from "./ThemesEditor.vue?vue&type=script&lang=js"
export * from "./ThemesEditor.vue?vue&type=script&lang=js"
import style0 from "./ThemesEditor.vue?vue&type=style&index=0&id=80f11456&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80f11456",
  null
  
)

export default component.exports