<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Import CSV</h4>
                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <AttachmentDropzone :set-file="setFile" class="mb-3"/>

                <div v-if="loading" class="mb-3 text-center">
                    Processing data&hellip;
                </div>

                <template v-for="(notice, index) in notices">
                    <div v-if="notice.heading" class="mb-3 card">
                        <div class="card-header bg-success-subtle text-success-emphasis">
                            <i class="bi bi-info-circle-fill"></i>
                            <span class="ms-1" v-html="notice.heading"></span>
                        </div>
                        <div class="card-body" v-html="notice.message"/>
                    </div>
                    <div v-else class="alert alert-success" v-html="notice"/>
                </template>

                <template v-for="(warning, index) in warnings">
                    <div v-if="warning.heading" class="mb-3 card">
                        <div class="card-header bg-warning-subtle text-warning-emphasis">
                            <i class="bi bi-exclamation-triangle-fill"></i>
                            <span class="ms-1" v-html="warning.heading"></span>
                        </div>
                        <div class="card-body" v-html="warning.message"/>
                    </div>
                    <div v-else class="alert alert-warning" v-html="warning"/>
                </template>

                <template v-for="(error, index) in errors">
                    <div v-if="error.heading" class="mb-3 card">
                        <div class="card-header bg-danger-subtle text-danger-emphasis">
                            <i class="bi bi-exclamation-triangle-fill"></i>
                            <span class="ms-1" v-html="error.heading"></span>
                        </div>
                        <div class="card-body" v-html="error.message"/>
                    </div>
                    <div v-else class="alert alert-danger">
                        <i class="bi bi-exclamation-triangle-fill"></i>
                        <span class="ms-1" v-html="error"></span>
                    </div>
                </template>

                <!--<div v-if="errors.length && !loading" class="mt-3 card">
                    <div class="card-header bg-danger-subtle text-danger-emphasis">
                        The data contains the following error(s).
                    </div>

                    <div class="card-body p-0">
                        <ul class="list-group list-group-flush">
                            <li v-for="(error, index) in errors" :key="index" class="list-group-item" v-html="error"/>
                        </ul>
                    </div>
                </div>-->

                <div v-if="rowCount" class="mb-3 text-center">
                    <p><b>{{ rowCount }} rows</b> ready to import</p>

                    <p v-if="errors.length || warnings.length" class="mb-3 text-center">
                        <label>
                            <input type="checkbox" class="form-check-input" v-model="ignoreErrorsAndWarnings">
                            Ignore errors and warnings
                        </label>
                    </p>

                    <Button :disabled="importBtnDisabled" @click.native="importData" :default-class="'btn-warning'">
                        Import
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AttachmentDropzone from '../../common/AttachmentDropzone'
import ProgressBar from '../../vue/common/ProgressBar'
import Button from "../../common/Button.vue"

export default {
    name: "ImportModal",
    components: {Button, AttachmentDropzone, ProgressBar},
    props: {
        options: Object
    },
    data() {
        return {
            tableName: this.options.tableName,
            productId: this.options.productId,
            notices: [],
            warnings: [],
            errors: [],
            loading: false,
            rowCount: 0,
            fileName: undefined,
            importComplete: false,
            textareaStyle: {},
            percentage: 0,
            ignoreErrorsAndWarnings: false
        }
    },
    computed: {
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        importBtnDisabled() {
            return this.errors.length || this.warnings.length ? !this.ignoreErrorsAndWarnings : false
        }
    },
    created() {
        this.resetScope()
    },
    methods: {
        resetScope() {
            this.loading = false
            this.notices = []
            this.warnings = []
            this.errors = []
            this.rowCount = 0
            this.importComplete = false
            this.textareaStyle = {}
            this.percentage = 0
        },
        async setFile(obj) {
            if (obj) {
                this.loading = true

                this.fileName = obj.fileName

                const {notices, warnings, errors, rowCount} = (
                    await this.$store.dispatch('request/post', {
                        url: this.$store.state.apiBackendEndpoint + '/api/import/validate',
                        postData: {tableName: this.tableName, fileName: this.fileName}
                    })
                ).data

                Object.assign(this, {notices, warnings, errors, rowCount})

                this.loading = false
            }
        },
        async importData() {
            this.$emit('hide-modal')

            const {title} = this.$store.state.components.items.find(o => o.tableName === this.tableName)

            const {notificationId} = await this.$store.dispatch('toasts/notification', {
                title: title + ' import',
                body: 'Starting&hellip;',
                onInterval: async () => {
                    // 3 issues: Created a horrid refreshing effect where the listing went blank before reloading.
                    //           And it needs to specifically reload the listing being imported into.
                    //           Finally, if an error occurred this interval kept running.
                    //const viewId = this.$store.state.mainViewId
                    //await this.$store.dispatch(viewId + '/scheduleLoad')
                }
            })

            await this.$store.dispatch('request/post', {
                url: this.$store.state.apiBackendEndpoint + '/api/import/import',
                postData: {
                    tableName: this.tableName,
                    fileName: this.fileName,
                    productId: this.productId,
                    notificationId
                }
            })

            this.$store.commit('cacheNeedsClearing')

            const viewId = this.$store.state.mainViewId
            await this.$store.dispatch(viewId + '/scheduleLoad')
        }
    }
}
</script>

<style scoped>

</style>