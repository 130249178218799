import moment from "moment/moment"

export default {
    namespaced: true,
    state: {
        franchises: [],
        schools: [],
        reports: [
            {
                name: 'birthdayDates',
                label: 'Birthday dates'
            },
            {
                name: 'birthdayPartyEnquiries',
                label: 'Birthday party enquiries'
            },
            //{
            //    name: 'bookingsReport',
            //    label: 'Bookings report'
            //},
            {
                name: 'courseDatesData',
                label: 'Course dates data'
            },
            {
                name: 'customerBookingData',
                label: 'Customer booking data'
            },
            {
                name: 'customerCredit',
                label: 'Customer credit'
            },
            {
                name: 'customerPostcodes',
                label: 'Customer postcodes'
            },
            {
                name: 'marketingReport',
                label: 'Marketing report'
            },
            {
                name: 'postcodes',
                label: 'Postcodes'
            },
            {
                name: 'partyBookings',
                label: 'Party bookings'
            },
            {
                name: 'referAFriendLTV',
                label: 'Refer a friend LTV'
            },
            {
                name: 'schoolParents',
                label: 'School parents'
            }
        ],
        formData: {
            reportName: '',
            startDate: '',
            endDate: ''
        },
        isLoading: false,
        rows: undefined
    },
    mutations: {
        formData(state, formData) {
            state.formData = formData
        },
        franchises(state, franchises) {
            state.franchises = franchises
        },
        schools(state, schools) {
            state.schools = schools
        },
        rows(state, rows) {
            state.rows = rows
            
            state.isLoading = false
        }
    },
    getters: {
        startTimestamp(state) {
            return moment(state.formData.startDate, 'YYYY-MM-DD').unix()
        },
        endTimestamp(state) {
            // The date input type has no time, so equates to the time 00:00:00,
            // so add the number of seconds in a day minus 1 to get 23:59:59
            return moment(state.formData.endDate, 'YYYY-MM-DD').unix() + 86399
        },
        getDate: () => (timestamp) => {
            return timestamp ? moment(timestamp).format('DD/MM/YYYY') : ''
        },
        getAge: () => (dateOfBirth) => {
            if (!dateOfBirth) {
                return ''
            }
            
            const ageDifMs = Date.now() - new Date(dateOfBirth)
            const ageDate = new Date(ageDifMs) // milliseconds from epoch
            
            return Math.abs(ageDate.getUTCFullYear() - 1970)
        }
    },
    actions: {
        init({state, commit, dispatch, rootState}, {query}) {
            dispatch('setFranchises')
            
            const franchiseId = rootState.user.user.franchiseId ?? 0
            dispatch('setSchools', {franchiseId: franchiseId})
            
            // Support for copying and pasting URLs.
            // On load, if a query string has already been set then apply it to state.formData and load the rows
            if (
                query.reportName // Only apply the query to formData if the query string has been set with vars
                && JSON.stringify(query) !== JSON.stringify(state.formData)
            ) {
                Object.assign(state.formData, query)
                state.formData.reportName = query.reportName
                state.formData.franchiseId = query.franchiseId ? parseInt(query.franchiseId) : 0
                dispatch('loadRows')
            }
        },
        loadRows({state, commit, dispatch}) {
            commit('rows', undefined)
            
            state.isLoading = true
            
            switch (state.formData.reportName) {
                case 'birthdayDates':
                    dispatch('loadBirthdayDates')
                    break
                
                case 'birthdayPartyEnquiries':
                    dispatch('loadBirthdayPartyEnquiries')
                    break
                
                //case 'bookingsReport':
                //    dispatch('loadBookingsReport')
                //    break
                
                case 'courseDatesData':
                    dispatch('loadClubBookingData')
                    break
                
                case 'customerBookingData':
                    dispatch('loadCustomerBookingData')
                    break
                
                case 'customerCredit':
                    dispatch('loadCustomerCreditData')
                    break
                
                case 'customerPostcodes':
                    dispatch('loadCustomers')
                    break
                
                case 'marketingReport':
                    dispatch('loadBookingsReport')
                    break
                
                case 'postcodes':
                    dispatch('loadCustomersPostcodes')
                    break
                
                case 'partyBookings':
                    dispatch('loadPartyBookings')
                    break
                
                case 'referAFriendLTV':
                    dispatch('loadReferAFriendLTV')
                    break
                
                case 'schoolParents':
                    dispatch('loadSchoolsParents')
                    break
            }
        },
        setFranchises({commit, dispatch}) {
            return dispatch('request/get', {
                url: 'api/component/odp_franchises',
                params: {
                    sort: 'title',
                    fields: ['id', 'title']
                }
            }, {root: true})
                .then((obj) => {
                    commit('franchises', obj.data.items)
                })
        },
        setSchools({commit, dispatch}, {franchiseId}) {
            const params = {
                sort: 'name',
                fields: ['id', 'name'],
                name: {
                    ne: ''
                }
            }
            
            if (franchiseId) {
                params.franchiseId = franchiseId
            }
            
            return dispatch('request/get', {
                url: 'api/component/odp_schools',
                params: params
            }, {root: true})
                .then((obj) => {
                    commit('schools', obj.data.items)
                })
        },
        loadBirthdayDates({state, dispatch, getters, commit, rootState}) {
            let children
            let childParentIds
            
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            
            const params = {
                field: 'id',
                status: 1
            }
            if (franchiseId) {
                params.franchiseId = franchiseId
            }
            
            return dispatch('request/get', {
                url: 'api/component/odp_schools',
                params: params
            }, {root: true})
                .then((obj) => {
                    return dispatch('request/post', {
                        url: 'api/component/odp_children',
                        postData: {
                            dateOfBirth: {
                                month: state.formData.month,
                            },
                            schoolId: obj.data.values
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    children = obj.data.items
                    
                    return dispatch('request/post', {
                        url: 'api/categories/get-content-ids',
                        postData: {
                            tableName: 'm_registrants_registrants',
                            columnName: 'children',
                            categoryId: children.map(a => a.id),
                            groupByCategoryId: true
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    childParentIds = obj.data
                    let parentIds = Object.values(childParentIds).flat()
                    
                    return dispatch('request/post', {
                        url: 'api/component/m_registrants_registrants',
                        postData: {
                            id: parentIds,
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    let allParents = obj.data.items
                    
                    let rows = []
                    children.forEach((item) => {
                        let parentName = ''
                        let parentEmail = ''
                        let parentTelephone = ''
                        let parentMobile = ''
                        
                        let parentIds = childParentIds[item.id]
                        if (parentIds) {
                            let parentId = parentIds[0]
                            let parents = allParents.filter(o => o.id === parentId)
                            if (parents) {
                                let parent = parents[0]
                                parentName = parent.displayName
                                parentEmail = parent.email
                                parentTelephone = parent.telephone
                                parentMobile = parent.mobile
                            }
                        }
                        
                        rows.push({
                            'Child name': item.displayName,
                            'DOB': getters['getDate'](item.dateOfBirth),
                            'Age': getters['getAge'](item.dateOfBirth),
                            'Parent name': parentName,
                            'Email': parentEmail,
                            'Telephone': parentTelephone,
                            'Telephone 2': parentMobile
                        })
                    })
                    
                    commit('rows', rows)
                })
        },
        loadBirthdayPartyEnquiries({state, dispatch, getters, commit, rootState}) {
            const tableName = 'form_3' // Birthday party form
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            let franchises = []
            
            dispatch('request/get', {
                url: 'api/component/odp_franchises',
            }, {root: true})
                .then((o) => {
                    franchises = o.data.items
                    
                    const params = {
                        createdDate: {
                            ge: getters['startTimestamp'],
                            le: getters['endTimestamp']
                        }
                    }
                    if (franchiseId) {
                        params.franchiseId = franchiseId
                    }
                    
                    return dispatch('request/get', {
                        url: 'api/component/' + tableName,
                        params: params
                    }, {root: true})
                })
                .then(o => {
                    const rows = []
                    
                    o.data.items.forEach((item) => {
                        const franchise = franchises.filter(o => o.id === item.franchiseId)[0] ?? {}
                        
                        rows.push({
                            'ID': item.id,
                            'Created date': getters['getDate'](item.createdDate * 1000),
                            'First name': item.firstName,
                            'Last name': item.lastName,
                            'Email': item.email,
                            'Birthday child name': item.birthdayChildName,
                            'Preferred location': item.preferredLocation,
                            'Time': item.time,
                            'Preferred date': getters['getDate'](item.preferredDate),
                            'Main activity': item.mainActivity,
                            'Phone': item.phone,
                            'Birthday child age': item.birthdayChildAge,
                            'Number of children': item.numberOfChildren,
                            'Where did you hear about us': item.whereDidYouHearAboutUs,
                            'Franchise name': franchise.title
                        })
                    })
                    
                    commit('rows', rows)
                })
        },
        async loadBookingsReport({state, dispatch, getters, commit, rootState, rootGetters}) {
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            
            let o = await dispatch('request/get', {
                url: 'api/component/voucher_provider',
            }, {root: true})
            const voucherProviders = o.data.items
            
            o = await dispatch('request/get', {
                url: 'api/component/franchise_voucher_code',
            }, {root: true})
            const franchiseVoucherCodes = o.data.items
            
            o = await dispatch('request/get', {
                url: 'api/component/odp_franchises',
            }, {root: true})
            const franchises = o.data.items
            
            const params = {}
            if (franchiseId) {
                params.franchiseId = franchiseId
            }
            o = await dispatch('request/get', {
                url: 'api/component/odp_courses',
                params: params
            }, {root: true})
            const courses = o.data.items
            
            o = await dispatch('request/get', {
                url: 'api/component/odp_course_dates',
                params: {courseId: courses.map(a => a.id),}
            }, {root: true})
            const courseDates = o.data.items
            
            o = await dispatch('request/post', {
                url: 'api/component/odp_course_bookings',
                postData: {
                    courseDateId: courseDates.map(a => a.id),
                    createdDate: {
                        ge: getters['startTimestamp'],
                        le: getters['endTimestamp'],
                    },
                    hasExpired: 0,
                    archived: 0,
                    isArchived: 0,
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const bookings = o.data.items
            
            if (!bookings.length) {
                commit('rows', [])
                return
            }
            
            o = await dispatch('request/post', {
                url: 'api/component/odp_credit_log',
                postData: {bookingId: bookings.map(a => a.id), debited: 1},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const creditItems = o.data.items
            if (!creditItems) {
                commit('rows', [])
                return
            }
            
            o = await dispatch('request/post', {
                url: 'api/component/odp_children',
                postData: {id: bookings.map(a => a.childId),},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const children = o.data.items
            if (!children) {
                commit('rows', [])
                return
            }
            
            o = await dispatch('request/post', {
                url: 'api/component/odp_schools',
                postData: {id: children.map(a => a.schoolId),},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const schools = o.data.items
            
            const childIds = children.map(a => a.id)
            o = await dispatch('categories/getTableColumnData', {
                tableName: 'm_registrants_registrants',
                columnName: 'children'
            }, {root: true})
            const parentIds = o.filter(o => childIds.indexOf(o.catId) > -1).map(o => o.itemId)
            
            o = await dispatch('request/post', {
                url: 'api/component/m_registrants_registrants',
                postData: {id: parentIds},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const parents = o.data.items
            
            const rows = []
            for (const k in bookings) {
                const item = bookings[k]
                
                o = await dispatch('categories/getTableColumnData', {
                    tableName: 'm_registrants_registrants',
                    columnName: 'children'
                }, {root: true})
                const parentId = o.find(o => o.catId === item.childId)?.itemId
                const parent = parentId ? parents.find(o => o.id === parentId) : {}
                
                const courseDate = courseDates.filter(o => o.id === item.courseDateId)[0] ?? {}
                const course = courses.filter(o => o.id === courseDate.courseId)[0] ?? {}
                const child = children.filter(o => o.id === item.childId)[0] ?? {}
                const school = schools.filter(o => o.id === child.schoolId)[0] ?? {}
                const franchise = franchises.filter(o => o.id === school.franchiseId)[0] ?? {}
                const franchiseVoucherCode = franchiseVoucherCodes.filter(o => o.id === item.franchiseVoucherCodeId)[0] ?? {}
                const voucherProvider = voucherProviders.filter(o => o.id === franchiseVoucherCode.voucherProviderId)[0] ?? {}
                const creditUsed = creditItems.find(o => o.bookingId === item.id)?.amount ?? ''
                
                switch (state.formData.reportName) {
                    /*case 'bookingsReport':
                        rows.push({
                            'Booking ID': item.id,
                            'Course': course.title,
                            'Course type': course.courseType,
                            'Start date': getters['getDate'](courseDate.startDate),
                            'School': school.name,
                            'Location': courseDate.location,
                            'Franchise': franchise.title,
                            'Amount paid': item.amountPaid,
                            'Voucher provider': item.voucherProvider,
                            'Franchise voucher code': voucherProvider ? voucherProvider.title : '',
                            'Agreed price': item.agreedPrice,
                            'Payment note': item.paymentNote,
                            'Credit used': creditUsed,
                            'Name': parent.displayName || '',
                            'Email': parent.email || '',
                            'Child': child.displayName,
                        })
                        break*/
                    
                    case 'marketingReport':
                        rows.push({
                            'Date joined': getters['getDate'](parent.createdDate * 1000),
                            'Child name': child.displayName,
                            'Child DOB': getters['getDate'](child.dateOfBirth),
                            'School': school.name,
                            'Franchise': franchise.title,
                            'Booking ID': item.id
                        })
                        break
                }
            }
            
            commit('rows', rows)
        },
        async loadClubBookingData({state, dispatch, getters, commit, rootState}) {
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            
            let params = {}
            if (franchiseId) {
                params.franchiseId = franchiseId
            }
            
            const courses = (await dispatch('request/get', {
                url: 'api/component/odp_courses',
                params: params
            }, {root: true})).data.items
            
            const courseDates = (await dispatch('request/get', {
                url: 'api/component/odp_course_dates',
                params: {
                    courseId: courses.map(a => a.id),
                    startDate: {ge: state.formData.startDate, le: state.formData.endDate}
                }
            }, {root: true})).data.items
            
            let eventIds = courseDates.filter(o => o.event).map(o => o.event)
            eventIds = [...new Set(eventIds)] // Unique
            
            const events = (await dispatch('request/get', {
                url: 'api/component/odp_event',
                params: {id: eventIds, fields: ['id', 'name']}
            }, {root: true})).data.items
            
            const schools = (await dispatch('request/post', {
                url: 'api/component/odp_schools',
                postData: {id: courseDates.map(a => a.schoolId)},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})).data.items
            
            const franchises = (await dispatch('request/get', {
                url: 'api/component/odp_franchises',
            }, {root: true})).data.items
            
            const rows = []
            courseDates.forEach((item) => {
                const course = courses.filter(o => o.id === item.courseId)[0] ?? {}
                const school = schools.filter(o => o.id === item.schoolId)[0] ?? {}
                const franchise = franchises.filter(o => o.id === course.franchiseId)[0] ?? {}
                
                const startDate = moment(item.startDate)
                const event = events.find(o => o.id === item.event)
                
                rows.push({
                    'Course date ID': item.id,
                    'Franchise': franchise.title,
                    'Course': course.title,
                    'Event': event?.name || '',
                    'School': school.name,
                    'Location': item.location,
                    'Activity': item.activity,
                    'Start date': getters['getDate'](item.startDate),
                    'No of places': item.numberOfPlaces || 0,
                    'No of bookings': item.numberOfBookings || 0,
                    'Places remaining': item.placesRemaining || 0,
                    'Price': item.price,
                    'Published': item.status,
                    'Week no.': startDate.week(),
                    'Month no.': startDate.format('MM'),
                    'Year no.': startDate.format('YYYY'),
                })
            })
            
            commit('rows', rows)
        },
        async loadCustomerBookingData({state, dispatch, getters, commit, rootState, rootGetters}) {
            let rows = []
            
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            
            // Load the courses associated to the selected franchise
            let params = {}
            if (franchiseId) {
                params.franchiseId = franchiseId
            }
            const courses = (await dispatch('request/get', {
                url: 'api/component/odp_courses',
                params: params
            }, {root: true})).data.items
            
            // Load the course dates associated to the courses
            const courseDates = (await dispatch('request/get', {
                url: 'api/component/odp_course_dates',
                params: {courseId: courses.map(a => a.id)}
            }, {root: true})).data.items
            
            // Load the bookings associated to the course dates within the specified date range
            const bookings = (await dispatch('request/post', {
                url: 'api/component/odp_course_bookings',
                postData: {
                    courseDateId: courseDates.map(a => a.id),
                    createdDate: {
                        ge: getters['startTimestamp'],
                        le: getters['endTimestamp']
                    },
                    hasExpired: 0,
                    archived: 0,
                    isArchived: 0
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})).data.items
            
            let eventIds = courseDates.filter(o => o.event).map(o => o.event)
            eventIds = [...new Set(eventIds)] // Unique
            
            const events = (await dispatch('request/get', {
                url: 'api/component/odp_event',
                params: {id: eventIds, fields: ['id', 'name']}
            }, {root: true})).data.items
            
            const children = (await dispatch('request/post', {
                url: 'api/component/odp_children',
                postData: {id: bookings.map(a => a.childId),},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})).data.items
            
            let childIds = children.map(a => a.id)
            
            const parentIds = (await dispatch('categories/getTableColumnData', {
                tableName: 'm_registrants_registrants',
                columnName: 'children'
            }, {root: true}))
                .filter(o => childIds.indexOf(o.catId) > -1).map(o => o.itemId)
            
            const parents = (await dispatch('request/post', {
                url: 'api/component/m_registrants_registrants',
                postData: {id: parentIds},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})).data.items
            
            const schools = (await dispatch('request/post', {
                url: 'api/component/odp_schools',
                postData: {id: children.map(a => a.schoolId)},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})).data.items
            
            const franchises = (await dispatch('request/get', {
                url: 'api/component/odp_franchises',
            }, {root: true})).data.items
            
            const franchiseVoucherCodes = (await dispatch('request/get', {
                url: 'api/component/franchise_voucher_code',
            }, {root: true})).data.items
            
            const voucherProviders = (await dispatch('request/get', {
                url: 'api/component/voucher_provider',
            }, {root: true})).data.items
            
            if (bookings.length) {
                for (const k in bookings) {
                    const item = bookings[k]
                    
                    const child = children.filter(o => o.id === item.childId)[0] ?? {}
                    const school = schools.filter(o => o.id === child.schoolId)[0] ?? {}
                    const courseDate = courseDates.filter(o => o.id === item.courseDateId)[0] ?? {}
                    const course = courses.filter(o => o.id === courseDate.courseId)[0] ?? {}
                    const franchise = franchises.filter(o => o.id === course.franchiseId)[0] ?? {}
                    const franchiseVoucherCode = franchiseVoucherCodes.filter(o => o.id === item.franchiseVoucherCodeId)[0] ?? {}
                    const voucherProvider = voucherProviders.filter(o => o.id === franchiseVoucherCode.voucherProviderId)[0] ?? {}
                    
                    let parent = {}
                    const parentId = (await dispatch('categories/getTableColumnData', {
                        tableName: 'm_registrants_registrants',
                        columnName: 'children'
                    }, {root: true}))
                        .find(o => o.catId === item.childId)?.itemId
                    
                    if (parentId) {
                        parent = parents.find(o => o.id === parentId)
                    }
                    
                    const date = moment(courseDate.startDate)
                    const event = events.find(o => o.id === courseDate.event)
                    
                    rows.push({
                        'Booking ID': item.id,
                        'Event': event?.name || '',
                        'Course': course.title,
                        'Course date ID': courseDate.id,
                        'Course type': course.courseType,
                        'Start date': getters['getDate'](courseDate.startDate),
                        'Year (of club)': date.format('YYYY'),
                        'Week (of club)': date.week(),
                        'Month (of club)': date.format('MM'),
                        'School': school.name,
                        'Location': courseDate.location,
                        'Franchise': franchise.title,
                        'Amount paid': item.amountPaid || 0,
                        'Voucher provider': voucherProvider.title,
                        'Franchise voucher code': franchiseVoucherCode.code,
                        'Agreed price': item.agreedPrice,
                        'Payment note': item.paymentNote,
                        'Credit used': item.creditAmountUsed || 0,
                        'Name': parent.displayName || '',
                        'Email': parent.email || '',
                        'Child': child.displayName,
                        'Created': getters['getDate'](item.createdDate * 1000),
                        
                        //'Bookings': courseDate.numberOfBookings,
                        //'Week no. of booking': 'TBC.',
                        //'Month no. of booking': 'TBC.',
                        //'Year no. of booking': 'TBC.',
                        //'Booking created': getters['getDate'](item.createdDate * 1000),
                    })
                }
            }
            
            commit('rows', rows)
        },
        async loadCustomerCreditData({state, dispatch, commit, rootState}) {
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            
            const schoolIds = (await dispatch('request/get', {
                url: 'api/component/odp_schools',
                params: {
                    field: 'id',
                    //status: 1,
                    ...(franchiseId && {franchiseId})
                }
            }, {root: true})).data.values
            
            if (!schoolIds.length) return
            
            const childIds = (await dispatch('request/post', {
                url: 'api/component/odp_children',
                postData: {schoolId: schoolIds, field: 'id', status: 1},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})).data.values
            
            if (!childIds.length) return
            
            const parentIds = (await dispatch('request/post', {
                url: 'api/categories/get-content-ids',
                postData: {
                    tableName: 'm_registrants_registrants',
                    columnName: 'children',
                    categoryId: childIds
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true}))?.data || []
            
            if (!parentIds.length) return
            
            const parentsChildIds = (await dispatch('request/post', {
                url: 'api/categories/get-category-ids',
                postData: {
                    tableName: 'm_registrants_registrants',
                    columnName: 'children',
                    contentIds: parentIds,
                    groupByItemId: true
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true}))?.data || []
            
            const childSchoolIds = ((await dispatch('request/post', {
                url: 'api/component/odp_children',
                postData: {id: childIds, fields: ['id', 'schoolId']},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true}))?.data.items || [])
                .reduce((acc, {id, schoolId}) => {
                    acc[id] = schoolId
                    return acc
                }, {})
            
            const schoolFranchiseIds = ((await dispatch('request/post', {
                url: 'api/component/odp_schools',
                postData: {id: schoolIds, fields: ['id', 'franchiseId']},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true}))?.data.items || [])
                .reduce((acc, {id, franchiseId}) => {
                    acc[id] = franchiseId
                    return acc
                }, {})
            
            const items = (await dispatch('request/post', {
                url: 'api/component/m_registrants_registrants',
                postData: {
                    id: parentIds,
                    fields: ['id', 'firstName', 'lastName', 'email', 'creditAmount'],
                    status: 1,
                    creditAmount: {gt: 0}
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true}))?.data.items || []
            
            const franchises = (await dispatch('request/get', {
                url: 'api/component/odp_franchises'
            }, {root: true})).data.items
            
            commit('rows', items.map(({id, firstName, lastName, email, creditAmount}) => {
                const childId = parentsChildIds[id][0] || 0
                const schoolId = childSchoolIds[childId] || 0
                const franchiseId = schoolFranchiseIds[schoolId] || 0
                const franchise = franchises.find(o => o.id === franchiseId)
                
                return {
                    'Customer ID': id,
                    'Franchise': franchise?.title || '',
                    'First name': firstName,
                    'Last name': lastName,
                    'Email': email,
                    'Credit amount': creditAmount
                }
            }))
        },
        loadCustomersPostcodes({state, dispatch, commit, rootState}) {
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            
            let params = {
                field: 'id',
                status: 1
            }
            if (franchiseId) {
                params.franchiseId = franchiseId
            }
            
            return dispatch('request/get', {
                url: 'api/component/odp_schools',
                params: params
            }, {root: true})
                .then((obj) => {
                    return dispatch('request/get', {
                        url: 'api/component/odp_children',
                        params: {
                            schoolId: obj.data.values,
                            field: 'id',
                            status: 1
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    return dispatch('request/post', {
                        url: 'api/categories/get-content-ids',
                        postData: {
                            tableName: 'm_registrants_registrants',
                            columnName: 'children',
                            categoryId: obj.data.values
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    let parentIds = obj.data
                    return dispatch('request/post', {
                        url: 'api/component/m_registrants_registrants',
                        postData: {
                            id: parentIds,
                            field: 'postcode',
                            status: 1,
                            sort: 'postcode'
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    let postcodes = []
                    obj.data.values.forEach((postcode) => {
                        if (postcode) {
                            postcode = postcode.toUpperCase().replace(/[^a-zA-Z0-9]/g, "").trim()
                            if (postcodes.indexOf(postcode) === -1) {
                                postcodes.push(postcode)
                            }
                        }
                    })
                    
                    let rows = []
                    postcodes.forEach((postcode) => {
                        rows.push({
                            'Postcode': postcode,
                        })
                    })
                    
                    commit('rows', rows)
                })
        },
        async loadPartyBookings({state, commit, getters, dispatch, rootState}) {
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            
            const params = {
                createdDate: {
                    ge: getters['startTimestamp'],
                    le: getters['endTimestamp']
                },
                isArchived: 0
            }
            
            if (franchiseId) {
                params.franchiseId = franchiseId
            }
            
            let o = await dispatch('request/get', {
                url: 'api/component/odp_party_bookings',
                params: params
            }, {root: true})
            const items = o.data.items
            const customerIds = items.map(o => o.customerId)
            if (!customerIds) {
                commit('rows', rows)
            }
            
            o = await dispatch('request/get', {
                url: 'api/component/odp_franchises'
            }, {root: true})
            const franchises = o.data.items
            
            o = await dispatch('request/post', {
                url: 'api/component/m_registrants_registrants',
                postData: {id: customerIds},
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const customers = o.data.items
            
            const rows = []
            items.forEach(item => {
                rows.push({
                    'Customer ID': item.customerId,
                    'Name': customers.find(o => o.id === item.customerId).name,
                    'Franchise': franchises.find(o => o.id === item.franchiseId).title,
                    'Party date': item.partyDate,
                    'Agreed price': item.agreedPrice,
                    'Notes': item.notes,
                    'Resend email': item.resendEmail
                })
            })
            
            commit('rows', rows)
        },
        async loadCustomers({state, dispatch, commit, getters, rootState, rootGetters}) {
            let parents
            let franchises
            let schools
            let children
            let childIds
            
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            
            let o = await dispatch('request/post', {
                url: 'api/component/m_registrants_registrants',
                postData: {
                    fields: ['id', 'firstName', 'lastName', 'email', 'telephone', 'mobile', 'postcode', 'createdDate',
                        'whereDidYouHearAboutUs', 'referredBy'],
                    createdDate: {
                        ge: getters['startTimestamp'],
                        le: getters['endTimestamp']
                    },
                    status: 1,
                    sort: 'lastName'
                },
                customHeaders: {
                    'X-Http-Method-Override': 'GET'
                }
            }, {root: true})
            parents = o.data.items
            
            o = await dispatch('request/post', {
                url: 'api/categories/get-category-ids',
                postData: {
                    tableName: 'm_registrants_registrants',
                    columnName: 'children',
                    contentId: parents.map(a => a.id)
                },
                customHeaders: {
                    'X-Http-Method-Override': 'GET'
                }
            }, {root: true})
            childIds = o.data
            
            o = await dispatch('request/post', {
                url: 'api/component/odp_children',
                postData: {
                    status: 1,
                    id: childIds
                },
                customHeaders: {
                    'X-Http-Method-Override': 'GET'
                }
            }, {root: true})
            children = o.data.items
            
            let params = {
                status: 1,
                id: children.map(o => o.schoolId)
            }
            if (franchiseId) {
                params.franchiseId = franchiseId
            }
            
            o = await dispatch('request/post', {
                url: 'api/component/odp_schools',
                postData: params,
                customHeaders: {
                    'X-Http-Method-Override': 'GET'
                }
            }, {root: true})
            schools = o.data.items
            
            o = await dispatch('request/get', {
                url: 'api/component/odp_franchises',
                params: {
                    id: schools.map(o => o.franchiseId)
                }
            }, {root: true})
            franchises = o.data.items
            
            const referredByCustomerIds = parents.map(o => o.referredBy).filter(e => e) // remove empty values
            
            o = await dispatch('request/get', {
                url: 'api/component/m_registrants_registrants',
                params: {
                    fields: ['id', 'email'],
                    id: referredByCustomerIds
                }
            }, {root: true})
            const referredByCustomerEmails = o.data.items
            
            let rows = []
            for (const k in parents) {
                const parent = parents[k]
                
                // Determine the franchise from the parent's child's school
                let franchise = {}
                let child
                
                o = await dispatch('categories/getTableColumnData', {
                    tableName: 'm_registrants_registrants',
                    columnName: 'children'
                }, {root: true})
                const objs = o.filter(o => o.itemId === parent.id)
                
                objs.forEach(obj => {
                    if (child === undefined) {
                        const childId = obj.catId
                        child = children.find(o => o.id === childId)
                    }
                })
                if (child) {
                    const school = schools.find(o => o.id === child?.schoolId)
                    franchise = franchises.find(o => o.id === school?.franchiseId)
                }
                
                if (
                    !franchiseId
                    || franchise?.id === franchiseId
                ) {
                    const referredByCustomerEmail = referredByCustomerEmails
                        .find(o => o.id === parent.referredBy)
                    
                    rows.push({
                        'Customer ID': parent.id,
                        'Name': parent.firstName + ' ' + parent.lastName,
                        'Email': parent.email,
                        'Telephone': parent.telephone || parent.mobile,
                        'Postcode': parent.postcode,
                        'Franchise': franchise?.title || '',
                        'Where did you hear about us?': parent.whereDidYouHearAboutUs,
                        'Referred by': parent.referredBy || '',
                        'Referred by email': referredByCustomerEmail?.email || '',
                        'Created': getters['getDate'](parent.createdDate * 1000),
                    })
                }
            }
            
            commit('rows', rows)
        },
        async loadReferAFriendLTV({state, dispatch, getters, commit, rootState}) {
            let o
            
            const franchiseId = rootState.user.user.franchiseId || state.formData.franchiseId
            
            const params = {fields: ['id', 'franchiseId']}
            if (franchiseId) {
                params.franchiseId = franchiseId
            }
            o = await dispatch('request/get', {
                url: 'api/component/odp_courses',
                params: params
            }, {root: true})
            const courses = o.data.items
            const courseIds = courses.map(o => o.id)
            
            // Load course dates associated to the courses
            o = await dispatch('request/get', {
                url: 'api/component/odp_course_dates',
                params: {
                    fields: ['id', 'courseId', 'startDate', 'event', 'location', 'activity'],
                    courseId: courseIds
                }
            }, {root: true})
            const courseDates = o.data.items
            const courseDateIds = courseDates.map(o => o.id)
            const eventIds = [...new Set(courseDates.map(o => o.event))]
            
            o = await dispatch('request/get', {
                url: 'api/component/odp_event',
                params: {
                    fields: ['id', 'name'],
                    courseId: eventIds
                }
            }, {root: true})
            const events = o.data.items
            
            // Load all customers' IDs within the date range.
            o = await dispatch('request/get', {
                url: 'api/component/m_registrants_registrants',
                params: {
                    field: 'id',
                    createdDate: {
                        ge: getters['startTimestamp'],
                        le: getters['endTimestamp']
                    },
                    referredBy: {gt: 0},
                    status: 1,
                    isArchived: 0
                }
            }, {root: true})
            const customerIds = o.data.values
            
            // Load the customer's child IDs.
            o = await dispatch('request/post', {
                url: 'api/categories/get-category-ids',
                postData: {
                    tableName: 'm_registrants_registrants',
                    columnName: 'children',
                    contentId: customerIds
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const childIds = o.data
            
            // Load bookings
            o = await dispatch('request/post', {
                url: 'api/component/odp_course_bookings',
                postData: {
                    childId: childIds,
                    courseDateId: courseDateIds,
                    archived: 0,
                    isArchived: 0,
                    hasExpired: 0
                    //placeConfirmed: 1
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const bookings = o.data.items.filter(o => o.placeConfirmed === 1 || o.voucherPaymentConfirmed === 1)
            
            if (!bookings?.length) {
                commit('rows', [])
                return
            }
            
            const bookingsChildIds = bookings.map(o => o.childId)
            
            o = await dispatch('request/post', {
                url: 'api/categories/get-content-ids',
                postData: {
                    tableName: 'm_registrants_registrants',
                    columnName: 'children',
                    categoryId: bookingsChildIds
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const bookingsCustomerIds = o.data
            
            // Load the customer's child IDs.
            o = await dispatch('request/post', {
                url: 'api/categories/get-category-ids',
                postData: {
                    tableName: 'm_registrants_registrants',
                    columnName: 'children',
                    contentId: bookingsCustomerIds,
                    groupByItemId: true
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const customersChildIds = o.data
            
            o = await dispatch('request/post', {
                url: 'api/component/odp_children',
                postData: {
                    id: childIds,
                    fields: ['id', 'dateOfBirth', 'schoolId']
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const children = o.data.items
            const schoolIds = [...new Set(children.map(o => o.schoolId))] // [...new Set()] gets unique values
            
            o = await dispatch('request/post', {
                url: 'api/component/odp_schools',
                postData: {
                    id: schoolIds,
                    fields: ['id', 'name']
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const schools = o.data.items
            
            // Load all customers' IDs within the date range.
            o = await dispatch('request/post', {
                url: 'api/component/m_registrants_registrants',
                postData: {
                    id: bookingsCustomerIds,
                    status: 1,
                    isArchived: 0
                },
                customHeaders: {'X-Http-Method-Override': 'GET'}
            }, {root: true})
            const customers = o.data.items
            
            o = await dispatch('request/get', {
                url: 'api/component/odp_franchises',
                params: {
                    fields: ['id', 'title']
                }
            }, {root: true})
            const franchises = o.data.items
            
            const rows = []
            
            customers.forEach(o => {
                const childIds = customersChildIds[o.id]
                const childBookings = bookings.filter(o => childIds.indexOf(o.childId) > -1)
                const numBookings = childBookings.length
                const total = childBookings.reduce((total, o) => {
                    const value = parseFloat(o.amountPaid)
                    return value ? total + value : total
                }, 0)
                const totalStr = Math.round((total + Number.EPSILON) * 100) / 100
                const averagePaid = Math.round((total / numBookings) * 100) / 100
                
                // Source the franchise from the first booking's course
                const courseDateId = childBookings.map(o => o.courseDateId)[0]
                const courseId = courseDates.find(o => o.id === courseDateId)?.courseId
                const franchiseId = courses.find(o => o.id === courseId)?.franchiseId
                const franchiseTitle = franchises.find(o => o.id === franchiseId)?.title || ''
                
                const bookingIds = childBookings.map(o => o.id)
                let bookingLinks = ''
                if (bookingIds.length) {
                    bookingIds.forEach((id) => {
                        const href = rootState.ccmsEndpoint + '#/odp_course_bookings/' + id
                        bookingLinks += '<a href="' + href + '">' + id + '</a> '
                    })
                }
                
                const booking = childBookings[0]
                const courseDate = courseDates.find(o => o.id === booking.courseDateId)
                
                const childId = childIds[0]
                const child = children.find(o => o.id === childId)
                
                const row = {
                    'ID': o.id,
                    'Created date': getters['getDate'](o.createdDate * 1000),
                    'First name': o.firstName,
                    'Last name': o.lastName,
                    'Total paid bookings': '£' + totalStr,
                    'Number of bookings': numBookings,
                    'Average paid': '£' + averagePaid,
                    'Franchise': franchiseTitle,
                    'Course date': getters['getDate'](courseDate.startDate),
                    'Event': events.find(o => o.id === courseDate.event)?.name || '',
                    'Location': courseDate.location,
                    'Activity': courseDate.activity,
                    'Age of child': moment().diff(child.dateOfBirth, 'years'),
                    'School child attends': schools.find(o => o.id === child.schoolId)?.name,
                    'Bookings': bookingLinks
                }
                
                rows.push(row)
            })
            
            commit('rows', rows)
        },
        loadSchoolsParents({state, dispatch, getters, commit}) {
            let children
            let childParentIds
            
            dispatch('request/get', {
                url: 'api/component/odp_children',
                params: {
                    field: 'id',
                    schoolId: state.formData.schoolId
                }
            }, {root: true})
                .then((obj) => {
                    const childIds = obj.data.values
                    
                    // Get all child IDs associated to bookings within the time frame
                    let params = {
                        field: 'childId',
                        archived: 0,
                        childId: childIds
                    }
                    if (state.formData.monthsAgo) {
                        let time = moment(new Date()).subtract(state.formData.monthsAgo, 'month').unix()
                        params.createdDate = {
                            ge: time
                        }
                    }
                    
                    // Load the child IDs from bookings within the given time period.
                    // It's only parents of children who have been booked in the time period that should be reported.
                    return dispatch('request/post', {
                        url: 'api/component/odp_course_bookings',
                        postData: params,
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    // Get the children associated to the school
                    return dispatch('request/post', {
                        url: 'api/component/odp_children',
                        postData: {
                            id: obj.data.values,
                            schoolId: state.formData.schoolId,
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    children = obj.data.items
                    
                    return dispatch('request/post', {
                        url: 'api/categories/get-content-ids',
                        postData: {
                            tableName: 'm_registrants_registrants',
                            columnName: 'children',
                            categoryId: children.map(a => a.id),
                            groupByCategoryId: true
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    childParentIds = obj.data
                    let parentIds = Object.values(childParentIds).flat()
                    
                    return dispatch('request/post', {
                        url: 'api/component/m_registrants_registrants',
                        postData: {
                            id: parentIds,
                        },
                        customHeaders: {
                            'X-Http-Method-Override': 'GET'
                        }
                    }, {root: true})
                })
                .then((obj) => {
                    let allParents = obj.data.items
                    
                    let rows = []
                    children.forEach((item) => {
                        let parentName = ''
                        let parentEmail = ''
                        let parentTelephone = ''
                        let parentMobile = ''
                        
                        let parentIds = childParentIds[item.id]
                        if (parentIds) {
                            let parentId = parentIds[0]
                            let parents = allParents.filter(o => o.id === parentId)
                            if (parents) {
                                let parent = parents[0]
                                parentName = parent.displayName
                                parentEmail = parent.email
                                parentTelephone = parent.telephone
                                parentMobile = parent.mobile
                            }
                        }
                        
                        rows.push({
                            'Parent name': parentName,
                            'Child name': item.displayName,
                            'Child DOB': getters['getDate'](item.dateOfBirth),
                            'Email': parentEmail,
                            'Telephone': parentTelephone,
                            'Telephone 2': parentMobile
                        })
                    })
                    
                    commit('rows', rows)
                })
        }
    }
}