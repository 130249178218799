<template>
    <div class="d-inline">
        <Button v-if="show" class="rounded-0" data-bs-toggle="offcanvas" @click.native="onClick"
                data-bs-target="#offcanvasSiteTree" aria-controls="offcanvasSiteTree">
            <i class="bi-diagram-3-fill"/>
        </Button>

        <div ref="offcanvas" id="offcanvasSiteTree" aria-labelledby="offcanvasSiteTreeLabel"
             class="offcanvas offcanvas-start" tabindex="-1">
            <div class="offcanvas-header">
                <SiteTreeNavigationMenu :site-tree-id="siteTreeId" class="w-auto"/>
                <button ref="closeBtn" type="button" class="btn-close" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>

            <div class="offcanvas-body">
                <SiteTree :site-tree-id="siteTreeId"/>
            </div>

            <div v-if="showPinBtn" class="offcanvas-footer text-end p-3">
                <PageEditorSiteTreePinBtn/>
            </div>
        </div>
    </div>
</template>

<script>
import SiteTreeNavigationMenu from "./site-tree/SiteTreeNavigationMenu.vue"
import siteTree from "../store/modules/siteTree"
import SiteTree from "./site-tree/SiteTree.vue"
import Button from "./Button.vue"
import PageEditorSiteTreePinBtn from "../components/page-editor/PageEditorSiteTreePinBtn.vue"

export default {
    name: "ShowSiteTreeBtn",
    components: {
        PageEditorSiteTreePinBtn,
        Button,
        SiteTree,
        SiteTreeNavigationMenu
    },
    props: {
        siteTreeId: String,
        tableName: String,
        id: Number,
        pageEditor: Boolean,
        showPinBtn: false
    },
    computed: {
        isSiteTree() {
            return this.$route.name === 'siteTree'
        },
        showContent() {
            return this.tableName === 'content'
        },
        show() {
            return (
                (
                    this.tableName === 'pages'
                    || this.tableName === 'content'
                    || this.pageEditor
                )
                && (
                    !this.isSiteTree
                    || !this.$store.state.sidebarToggle['site_treeLeft']
                )
            )
        }
    },
    created() {
        if (this.$store.hasModule(this.siteTreeId)) {
            this.$store.unregisterModule(this.siteTreeId)
        }
        this.$store.registerModule(this.siteTreeId, siteTree)
        this.$store.dispatch(this.siteTreeId + '/init', {
            selectContentMode: this.showContent,
            showContent: this.showContent,
            onPageSelect: (pageId) => {
                const name = this.isSiteTree ? 'site_tree' : 'pages'
                window.location = '/#/' + name + '/' + pageId

                this.hide()
            },
            onContentSelect: (pageContentId) => {
                const contentId = this.$store.state.pageContentData.items.find(o => o.id === pageContentId)?.contentId
                const name = this.isSiteTree ? 'site_tree' : 'content'

                this.isSiteTree
                    ? window.location = '/#/' + name + '/c' + pageContentId
                    : window.location = '/#/' + name + '/' + contentId

                this.hide()
            }
        })
    },
    methods: {
        hide() {
            this.$refs.closeBtn.click()
        },
        onClick() {
            let pageId = 0
            let pageContentLinkId = 0

            if (this.tableName === 'pages') {
                pageId = this.id
            } else if (this.isSiteTree) {
                pageContentLinkId = parseInt(this.$route.params.pageOrContentId.substring(1))
            }

            if (pageId) {
                this.$store.dispatch(this.siteTreeId + '/setPageId', pageId)
                this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', pageId)
            } else if (pageContentLinkId) {
                this.$store.dispatch(this.siteTreeId + '/setContentId', pageContentLinkId)
                const pageContentLinkItem = this.$store.state.pageContentData.items.find(o => o.id === pageContentLinkId)
                pageId = pageContentLinkItem.pageId
                this.$store.commit(this.siteTreeId + '/addExpandedPageId', pageId)
                this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', pageId)
            }
        }
    }
}
</script>

<style scoped>

</style>