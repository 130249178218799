var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.contentAreas?.length
        ? [
            _vm._l(_vm.contentAreas, function (contentArea, index) {
              return _c("PageContentMobileContentArea", {
                key: contentArea,
                attrs: {
                  "page-id": _vm.pageId,
                  "content-area": contentArea,
                  "content-area-first": index === 0,
                  "content-area-last": index === _vm.contentAreas.length - 1,
                  "content-areas": _vm.contentAreas,
                },
              })
            }),
            _vm._v(" "),
            _c("PageContentOrphaned", {
              staticClass: "mb-3",
              attrs: {
                "page-id": _vm.pageId,
                "content-areas": _vm.contentAreas,
              },
            }),
          ]
        : _vm.pageSectionsContentAreas?.length
        ? _c("PageContentSections", { attrs: { "page-id": _vm.pageId } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }