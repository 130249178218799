<template>
    <div>
        <Button ref="button" class="h-100 rounded-0" data-bs-toggle="offcanvas" data-bs-target="#notificationsOffcanvas"
                @click.native="openMenu">
            <i :class="{ 'active': active }" class="bi bi-bell-fill notification-icon"/>
        </Button>

        <div @click="closeMenu" class="offcanvas offcanvas-end bg-transparent border-0" tabindex="-1"
             id="notificationsOffcanvas" aria-labelledby="notificationsOffcanvasLabel">
            <!--<div class="offcanvas-header">
                <h5 class="offcanvas-title" id="notificationsOffcanvasLabel">Notifications</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>-->

            <div class="offcanvas-body pt-5">
                <Toast @click.stop.native class="m-0 mb-3 w-100" v-for="item in items" :key="item.id" :title="item.title"
                       :body="item.data.message" :created-by="item.createdBy" :created-date="item.createdDate"
                       :download-url="item.data.downloadUrl" :more-info="item.data.moreInfo"
                       :percentage="item.data.percentage" :disable-auto-hide="true" :disable-animation="true"
                       :disable-close="false" :on-close="() => {onCloseToast(item.id)}"/>
            </div>

            <div class="p-3 text-end">

            </div>
        </div>
    </div>
</template>

<script>
import NotificationsMenuClearActiveBtn from "./NotificationsMenuClearActiveBtn"
import Toast from "../toasts/Toast"
import Button from "../Button.vue"
import ItemHistory from "../item-history/ItemHistory.vue"
import FormSidebarBtn from "../form/FormSidebarBtn.vue"

export default {
    name: "NotificationsMenu",
    components: {
        FormSidebarBtn,
        ItemHistory,
        Button,
        Toast,
        NotificationsMenuClearActiveBtn,
    },
    computed: {
        active() {
            return this.$store.state.notificationsMenu.active
        },
        items() {
            return this.$store.state.notificationsMenu.items
        }
    },
    mounted() {
        const myOffcanvas = document.getElementById('notificationsOffcanvas')
        myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
            this.$store.dispatch('notificationsMenu/hideMenu')
        })
    },
    methods: {
        openMenu() {
            this.$store.dispatch('notificationsMenu/openMenu')
        },
        closeMenu() {
            this.$refs.button.$el.click()
        },
        onCloseToast(itemId) {
            this.$store.dispatch('notificationsMenu/removeItem', {
                itemId: itemId
            })
        }
    }
}
</script>

<style scoped>
.notification-icon {
    transition-property: color;
    transition-duration: 300ms;
}

.notification-icon.active {
    color: #f60;
}
</style>