export default {
    namespaced: true,
    state: {
        router: undefined,
        pageId: undefined,
        iframe: undefined,
        
        pageSectionsNode: undefined,
        pageSections: [],
        hoverSectionId: 0,
        activeSectionId: 0,
        onSectionDelete: () => {
        },
        pageSectionTemplates: undefined,
        pageSectionData: undefined,
        
        contentAreas: [],
        hoverContentAreaName: '',
        activeContentAreaName: '',
        
        contentItems: [],
        hoverPageContentId: 0,
        activePageContentId: 0,
        
        pageContainers: undefined,
        
        toolbarDropdown: undefined,
        offcanvas: undefined,
        moveInProgress: false,
        siteTreeId: 'pageEditorSiteTree',
        pinSiteTree: false,
        leftSidebarActiveTab: 'Site tree',
        pagePanelActiveTab: 'Edit',
        contentPanelActiveTab: 'Edit',
        
        showUnpublishedContent: true,
        showPendingContent: true,
        showExpiringContent: true,
        showExpiredContent: true,
        
        offcanvasEnd: false,
        wheelPromise: undefined,
        
        newPage: false,
        editorToolbar: undefined
    },
    mutations: {
        pageId(state, pageId) {
            state.pageId = pageId
        },
        iframe(state, iframe) {
            state.iframe = iframe
        },
        pageSection(state, pageSection) {
            state.pageSections.push(pageSection)
        },
        hoverSectionId(state, hoverSectionId) {
            state.hoverSectionId = hoverSectionId
        },
        activeSectionId(state, activeSectionId) {
            state.activeSectionId = activeSectionId
        },
        contentArea(state, contentArea) {
            contentArea.label = contentArea.sectionId > 0
                ? 'Area ' + (parseInt(contentArea.name.split('_').pop()) + 1) // "area_3_0" => "Area 1"
                : contentArea.name
            
            state.contentAreas.push(contentArea)
        },
        contentItem(state, contentItem) {
            contentItem.isHidden = false
            
            state.contentItems.push(contentItem)
        },
        hoverContentAreaName(state, contentAreaName) {
            state.hoverContentAreaName = contentAreaName
        },
        activeContentAreaName(state, contentAreaName) {
            state.activeContentAreaName = contentAreaName
        },
        hoverPageContentId(state, pageContentId) {
            state.hoverPageContentId = pageContentId
        },
        activePageContentId(state, pageContentId) {
            state.activePageContentId = pageContentId
        },
        deselectAll(state) {
            state.hoverContentAreaName = ''
            state.activeContentAreaName = ''
            state.hoverPageContentId = 0
            state.activePageContentId = 0
        },
        toolbarDropdown(state, toolbarDropdown) {
            state.toolbarDropdown = toolbarDropdown
        },
        pinSiteTree(state, bool) {
            state.pinSiteTree = bool
        },
        toggleShowUnpublishedContent(state) {
            state.showUnpublishedContent = !state.showUnpublishedContent
        },
        toggleShowPendingContent(state) {
            state.showPendingContent = !state.showPendingContent
        },
        toggleShowExpiringContent(state) {
            state.showExpiringContent = !state.showExpiringContent
        },
        toggleShowExpiredContent(state) {
            state.showExpiredContent = !state.showExpiredContent
        },
        offcanvasEnd(state, bool) {
            state.offcanvasEnd = bool
        },
        leftSidebarActiveTab(state, name) {
            state.leftSidebarActiveTab = name
        }
    },
    getters: {
        isSectionContent: (state) => (pageContentId) => {
            return !!state.contentAreas.find(
                o => o.name === state.contentItems.find(
                    o => o.pageContentId === pageContentId
                )?.contentAreaName
            )?.sectionId
        }
    },
    actions: {
        // This is needed so that when the PageEditor loads, components don't generate errors
        // due to contentArea data  existing from previous pages.
        unset({state}) {
            state.pageSectionsNode = undefined
            
            state.pageSections = []
            state.contentAreas = []
            state.contentItems = []
        },
        loadSettings({commit}) {
            const settings = JSON.parse(localStorage.getItem('pageEditor'))
            if (settings?.pinSiteTree) {
                commit('pinSiteTree', true)
            }
        },
        async init({state, commit, dispatch}, {
            router,
            iframe,
            sectionId,
            areaIndex,
            pageContentId,
            onSectionDelete
        }) {
            state.router = router
            state.onSectionDelete = onSectionDelete
            
            commit('deselectAll')
            
            commit('iframe', iframe)
            
            iframe.contentDocument.addEventListener('keyup', function(e) {
                dispatch('keyboardEventHandler', e)
            })
            
            await dispatch('initAll')
            
            // Sets the active item on page refresh
            if (pageContentId) {
                commit('activePageContentId', pageContentId)
                commit('leftSidebarActiveTab', 'Content')
            } else if (areaIndex > -1) {
                const contentAreaName = state.contentAreas[areaIndex]?.name
                commit('activeContentAreaName', contentAreaName)
                commit('leftSidebarActiveTab', 'Content')
            } else if (sectionId) {
                commit('activeSectionId', sectionId)
                commit('leftSidebarActiveTab', 'Content')
            }
            
            if (state.showUnpublishedContent) {
                await dispatch('showUnpublishedContent')
            }
            
            if (state.newPage) {
                state.newPage = false
                
                const sectionId = await dispatch('addSection')
                
                await dispatch('goToSection', {router, sectionId})
                
                commit('leftSidebarActiveTab', 'Content')
            }
        },
        async initAll({state, dispatch}) {
            await dispatch('unset')
            
            const body = state.iframe.contentDocument.body
            
            dispatch('initArea', {
                el: body.getElementsByTagName('ccms-page-header')[0],
                name: 'Page header',
                sectionId: 0
            })
            
            const pageSectionsNode = body.querySelector('[data-ccms-page-sections]')
            if (pageSectionsNode) {
                state.pageSectionsNode = pageSectionsNode
                
                if (state.pageSectionTemplates === undefined) {
                    state.pageSectionTemplates = (await dispatch('request/get', {
                        url: 'api/component/templates_templates',
                        params: {type: 'section', sort: 'title'}
                    }, {root: true})).data.items
                }
                
                if (state.pageContainers === undefined) {
                    state.pageContainers = (await dispatch('request/get', {
                        url: 'api/component/templates_templates',
                        params: {type: 'container', sort: 'title'}
                    }, {root: true})).data.items
                }
                
                await dispatch('setPageSectionData')
                
                Array.from(pageSectionsNode.children).forEach(section => {
                    dispatch('initSection', {el: section})
                })
            }
            
            Array.from(body.querySelectorAll('[data-ccms-content-area]'))
                .forEach(contentArea => {
                    dispatch('initArea', {
                        el: contentArea,
                        name: contentArea.dataset.name,
                        sectionId: 0
                    })
                })
            
            dispatch('initArea', {
                el: body.getElementsByTagName('ccms-page-footer')[0],
                name: 'Page footer',
                sectionId: 0
            })
        },
        initSection({state, commit, dispatch}, {el, index}) {
            const sectionId = parseInt(el.id.substring(1))
            const section = {id: sectionId, el}
            
            el.addEventListener('mouseover', () => {
                commit('hoverSectionId', sectionId)
            })
            el.addEventListener('mouseout', () => {
                commit('hoverSectionId', 0)
            })
            el.addEventListener('click', () => {
                dispatch('goToSection', {router: state.router, sectionId})
            })
            
            index !== undefined
                ? state.pageSections.splice(index, 0, section)
                : commit('pageSection', section)
            
            // Section areas
            Array.from(el.querySelectorAll('[data-ccms-section-area]'))
                .forEach(el => dispatch('initArea', {el, name: el.dataset.name, sectionId}))
        },
        initArea({state, commit, dispatch}, {el, name, sectionId}) {
            if (el) {
                commit('contentArea', {sectionId, el, name})
                
                el.addEventListener('mouseover', (e) => {
                    e.stopPropagation()
                    commit('hoverSectionId', 0)
                    commit('hoverContentAreaName', name)
                })
                el.addEventListener('mouseout', () => {
                    commit('hoverContentAreaName', '')
                })
                el.addEventListener('click', (e) => {
                    e.stopPropagation() // Prevent section click event
                    dispatch('goToContentArea', {router: state.router, contentAreaName: name})
                })
                
                const content = Array.from(el.querySelectorAll('[data-ccms-editor-content]'))
                content.length
                    ? content.forEach(el => dispatch('initContent', {el, contentAreaName: name}))
                    : el.style.minHeight = '50px'
            }
        },
        initContent({state, commit, dispatch}, {el, contentAreaName}) {
            const pageContentId = parseInt(el.id.substring(1))
            
            el.addEventListener('mouseover', (e) => {
                e.stopPropagation() // Prevent section and area mouseover events
                commit('hoverSectionId', 0)
                commit('hoverContentAreaName', '')
                commit('hoverPageContentId', pageContentId)
            })
            el.addEventListener('mouseout', () => {
                commit('hoverPageContentId', 0)
            })
            el.addEventListener('click', (e) => {
                e.stopPropagation() // Prevent area click event
                dispatch('goToContent', {router: state.router, pageContentId, pageId: state.pageId})
            })
            
            //Array.from(el.querySelectorAll('[data-ccms-editable]'))
            //    .forEach(el => dispatch('initEditableContent', {el, pageContentId}))
            
            commit('contentItem', {el, pageContentId, contentAreaName})
        },
        async initEditableContent({rootState, dispatch, rootGetters}, {el, pageContentId}) {
            el.style.border = '3px solid transparent'
            
            el.addEventListener('mouseover', (e) => {
                e.stopPropagation()
                el.style.borderColor = 'rgba(9, 110, 253, 0.2)'
                el.style.borderRadius = '5px'
            })
            el.addEventListener('mouseout', () => {
                el.style.borderColor = 'transparent'
                el.style.borderRadius = ''
            })
            
            const contentId = rootState.pageContentData.items.find(o => o.id === pageContentId).contentId
            
            const {contentType} = (await dispatch('itemData/get', {
                tableName: 'content',
                id: contentId
            }, {root: true}))
            
            const tableName = 'component_' + contentType
            const componentId = rootState.components.componentIds[tableName]
            const fields = rootGetters['componentStructure/get'](componentId)
            
            const name = el.dataset.ccmsEditable
            const {type} = fields.find(o => o.columnName === name)
            
            const {id} = (await dispatch('request/get', {
                url: 'api/component/' + tableName,
                params: {contentId}
            }, {root: true})).data.items[0]
            
            const applyEvent = () => {
                el.addEventListener('input', () => {
                    const data = {[name]: el.innerHTML}
                    dispatch('itemData/update', {tableName, id, data}, {root: true})
                })
            }
            
            switch (type) {
                case 'texteditor':
                    el.setAttribute('contenteditable', true)
                    applyEvent()
                    break
                case 'text':
                case 'textarea':
                    el.setAttribute('contenteditable', 'plaintext-only')
                    applyEvent()
                    break
            }
            
            const div = document.createElement('div')
            el.addEventListener('focus', () => {})
            el.addEventListener('blur', () => {div.remove()})
        },
        async setPageSectionData({state, dispatch}) {
            state.pageSectionData = (await dispatch('request/get', {
                url: 'api/page-section/' + state.pageId
            }, {root: true})).data.data
        },
        async moveSectionUp({state, dispatch}) {
            // Don't move the top item up
            if (state.pageSections.map(o => o.id).indexOf(state.activeSectionId) === 0)
                return
            
            if (state.moveInProgress) return
            state.moveInProgress = true
            
            await dispatch('request/patch', {url: 'api/page-section/' + state.activeSectionId + '/up'}, {root: true})
            
            const i = state.pageSections.map(o => o.id).indexOf(state.activeSectionId)
            const previousSection = state.pageSections[i - 1].el
            const section = state.pageSections.splice(i, 1)[0]
            state.pageSections.splice(i - 1, 0, section)
            section.el.after(previousSection)
            
            state.moveInProgress = false
        },
        async moveSectionDown({state, dispatch}) {
            if (state.pageSections.map(o => o.id).indexOf(state.activeSectionId) === state.pageSections.length - 1)
                return
            
            if (state.moveInProgress) return
            state.moveInProgress = true
            
            await dispatch('request/patch', {url: 'api/page-section/' + state.activeSectionId + '/down'}, {root: true})
            
            const i = state.pageSections.map(o => o.id).indexOf(state.activeSectionId)
            const nextSection = state.pageSections[i + 1].el
            const section = state.pageSections.splice(i, 1)[0]
            state.pageSections.splice(i + 1, 0, section)
            section.el.before(nextSection)
            
            state.moveInProgress = false
        },
        deleteSection({state, dispatch}) {
            dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Delete section',
                    modalBody: `
                        <p class="alert alert-danger">This <strong>cannot</strong> be undone.</p>
                        <p class="mb-0">Are you sure?</p>
                    `,
                    onConfirm: () => {
                        dispatch('request/delete', {
                            url: 'api/page-section/' + state.activeSectionId
                        }, {root: true})
                            .then(() => {
                                const i = state.pageSections.map(o => o.id).indexOf(state.activeSectionId)
                                const section = state.pageSections.splice(i, 1)[0]
                                
                                section.el.remove()
                                
                                state.onSectionDelete()
                                
                                // Removes any propagated content that was contained within the deleted section
                                // from the content's Page Use control.
                                dispatch('pageContentData/init', {}, {root: true})
                            })
                    }
                }
            }, {root: true})
        },
        async moveUp({state, rootState, dispatch}, pageContentId) {
            if (state.moveInProgress) return
            state.moveInProgress = true
            
            const pageItems = rootState.pageContentData.items.filter(o => o.pageId === state.pageId)
            const item = pageItems.find(o => o.id === pageContentId)
            const contentAreaItems = pageItems.filter(o => o.contentArea === item.contentArea)
            const index = contentAreaItems.map(o => o.id).indexOf(pageContentId)
            const isFirst = index === 0
            
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            
            if (isFirst) {
                const index = state.contentAreas.map(o => o.name).indexOf(content.contentAreaName)
                const previousContentArea = state.contentAreas[index - 1]
                
                if (!previousContentArea) {
                    state.moveInProgress = false
                    return
                }
                
                if (previousContentArea.name === 'Page header') {
                    state.moveInProgress = false
                    console.log('Top')
                    return
                }
                
                // Note: Remember that pageContentData includes unpublished content. So if unpublished content
                //       is NOT visible, the indexes may not seem correct.
                const contentAreaItems = pageItems.filter(o => o.contentArea === previousContentArea.name)
                const newDisplayOrder = contentAreaItems.length + 1
                
                await dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        pageSectionLinkId: previousContentArea.sectionId,
                        contentArea: previousContentArea.name,
                        displayOrder: newDisplayOrder
                    }
                }, {root: true})
                
                await dispatch('pageContentData/init', {}, {root: true})
                
                const parent = content.el.parentNode
                parent.removeChild(content.el)
                
                const contentArea = state.contentAreas.find(o => o.name === previousContentArea.name)
                contentArea.el.append(content.el)
                content.contentAreaName = previousContentArea.name
                
                state.moveInProgress = false
                
            } else {
                await dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        pageSectionLinkId: item.pageSectionLinkId,
                        contentArea: item.contentArea,
                        displayOrder: item.displayOrder - 1
                    }
                }, {root: true})
                
                await dispatch('pageContentData/init', {}, {root: true})
                
                const previousEl = content.el.previousSibling
                previousEl?.before(content.el)
                
                state.moveInProgress = false
            }
            
            dispatch('scrollToContent', {pageContentId})
        },
        async moveDown({state, rootState, dispatch}, pageContentId) {
            if (state.moveInProgress) return
            state.moveInProgress = true
            
            const pageItems = rootState.pageContentData.items.filter(o => o.pageId === state.pageId)
            const item = pageItems.find(o => o.id === pageContentId)
            const contentAreaItems = pageItems.filter(o => o.contentArea === item.contentArea)
            const index = contentAreaItems.map(o => o.id).indexOf(pageContentId)
            const isLast = index === contentAreaItems.length - 1
            
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            
            if (isLast) {
                const index = state.contentAreas.map(o => o.name).indexOf(content.contentAreaName)
                const nextContentArea = state.contentAreas[index + 1]
                
                if (!nextContentArea) {
                    state.moveInProgress = false
                    return
                }
                
                if (nextContentArea.name === 'Page footer') {
                    state.moveInProgress = false
                    console.log('Bottom')
                    return
                }
                
                await dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        pageSectionLinkId: nextContentArea.sectionId,
                        contentArea: nextContentArea.name,
                        displayOrder: 1
                    }
                }, {root: true})
                
                await dispatch('pageContentData/init', {}, {root: true})
                
                // Remove the content from the DOM
                const parent = content.el.parentNode
                parent.removeChild(content.el)
                
                // Insert the content into the top of the next content area
                const contentArea = state.contentAreas.find(o => o.name === nextContentArea.name)
                contentArea.el.prepend(content.el)
                content.contentAreaName = nextContentArea.name
                
                state.moveInProgress = false
                
            } else {
                await dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        pageSectionLinkId: item.pageSectionLinkId,
                        contentArea: item.contentArea,
                        displayOrder: item.displayOrder + 1
                    }
                }, {root: true})
                
                await dispatch('pageContentData/init', {}, {root: true})
                
                const nextEl = content.el.nextSibling
                nextEl?.after(content.el)
                
                state.moveInProgress = false
            }
            
            dispatch('scrollToContent', {pageContentId})
        },
        detachContent({state, dispatch, commit}, pageContentId) {
            dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Detach content',
                    modalBody: `
                        <p class="alert alert-warning">No other references to this content will be affected.</p>
                    `,
                    onConfirm: async () => {
                        await dispatch('request/delete', {
                            url: 'api/delete-page-content-link-id/' + pageContentId
                        }, {root: true})
                        
                        dispatch('removeContentFromPage', pageContentId)
                    }
                }
            }, {root: true})
        },
        deleteContent({state, dispatch, commit, rootState}, pageContentId) {
            const contentId = rootState.pageContentData.items.find(o => o.id === pageContentId)?.contentId
            
            const num = rootState.pageContentData.items.filter(o => o.contentId === contentId)?.length
            const msg = num === 1
                ? 'This is the <strong>only</strong> instance of this content in use.'
                : '<strong>' + num + '</strong> instances of this content will be removed!'
            
            dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Delete content',
                    modalBody: `
                        <p>` + msg + `</p>
                        <p class="alert alert-danger">This <strong>cannot</strong> be undone.</p>
                        <p class="mb-0">Are you sure?</p>
                    `,
                    onConfirm: async () => {
                        await dispatch('itemData/delete', {
                            tableName: 'content',
                            id: contentId
                        }, {root: true})
                        
                        dispatch('removeContentFromPage', pageContentId)
                    }
                }
            }, {root: true})
        },
        async removeContentFromPage({state, commit, dispatch}, pageContentId) {
            // Remove the node
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            content.el.remove()
            
            // Remove the content's data from VueX to update Vue components
            const i = state.contentItems.map(o => o.pageContentId).indexOf(pageContentId)
            state.contentItems.splice(i, 1)
            
            commit('deselectAll')
            
            await dispatch('pageContentData/init', {}, {root: true})
        },
        async showUnpublishedContent({state, dispatch, rootState}) {
            state.contentItems.forEach(obj => {
                const contentId = rootState.pageContentData.items.find(o => o.id === obj.pageContentId)?.contentId
                dispatch('itemData/get', {tableName: 'content', id: contentId}, {root: true})
                    .then((o) => {
                        // Hiding unpublished content
                        if (!state.showUnpublishedContent && o.status === 0) {
                            obj.isHidden = true
                            obj.el.style.setProperty('display', 'none', 'important')
                            
                            // Showing previously hidden content
                        } else if (state.showUnpublishedContent) {
                            obj.isHidden = false
                            obj.el.style.removeProperty('display')
                        }
                    })
            })
        },
        scrollToSection({state, dispatch}, {sectionId, behaviour}) {
            const el = state.pageSections.find(o => o.id === sectionId)?.el
            dispatch('scrollTo', {el, behaviour})
        },
        scrollToContentArea({state, dispatch}, {contentAreaName, behaviour}) {
            const el = state.contentAreas.find(o => o.name === contentAreaName)?.el
            dispatch('scrollTo', {el, behaviour})
        },
        scrollToContent({state, dispatch}, {pageContentId, behaviour}) {
            const el = state.contentItems.find(o => o.pageContentId === pageContentId)?.el
            dispatch('scrollTo', {el, behaviour})
        },
        /*async scrollToContentIfNotVisible({state, dispatch}, el) {
            const isVisible = await dispatch('isVisibleInViewport', el)
            if (!isVisible) {
                dispatch('scrollTo', {el})
            }
        },
        isVisibleInViewport({}, el) {
            const rect = el.getBoundingClientRect()
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        },*/
        scrollTo({state}, {el, behaviour}) {
            // Allows scrollIntoView to scroll to slightly above the top of the element
            el.style.scrollMarginTop = '20px'
            
            el.scrollIntoView({
                behavior: behaviour || 'smooth',
                // Scrolls the element into the middle of the viewport
                block: 'center', // Y axis
                //inline: 'center' // X axis
            })
            
            el.style.scrollMarginTop = ''
        },
        showAddContentModal({state, dispatch, commit}, contentAreaName) {
            dispatch('modals/show', {
                componentName: 'AddContentModal',
                obj: {
                    onSelect: o => {
                        dispatch('addContentToPage', {
                            contentId: o.contentId,
                            contentAreaName
                        })
                    }
                }
            }, {root: true})
        },
        async addContentToPage({state, commit, dispatch}, {contentId, pageSectionLinkId, contentAreaName}) {
            let o = await dispatch('request/post', {
                url: 'api/save-content-to-page',
                postData: {
                    pageId: state.pageId,
                    pageSectionLinkId,
                    contentId,
                    contentArea: contentAreaName
                }
            }, {root: true})
            
            const pageContentId = o.data.pageContentId
            
            await dispatch('pageContentData/init', {}, {root: true})
            
            await dispatch('placeContentInPage', {pageContentId, contentAreaName})
            
            //dispatch('scrollToContent', pageContentId)
            
            return pageContentId
        },
        async placeContentInPage({state, commit, dispatch}, {pageContentId, contentAreaName}) {
            commit('deselectAll')
            commit('activePageContentId', pageContentId)
            
            const {html} = (await dispatch('request/get', {
                url: 'api/page-editor/content/' + pageContentId,
            }, {root: true})).data
            
            const contentEl = new DOMParser().parseFromString(html, 'text/html').body.firstChild
            
            // Move the node to the content area
            const contentAreaEl = state.contentAreas.find(o => o.name === contentAreaName).el
            contentAreaEl.append(contentEl)
            
            // Add the node
            commit('contentItem', {el: contentEl, pageContentId, contentAreaName})
        },
        async reloadContent({state, dispatch}, pageContentId) {
            const {html} = (await dispatch('request/get', {
                url: 'api/page-editor/content/' + pageContentId,
            }, {root: true})).data
            
            const newContentEl = new DOMParser().parseFromString(html, 'text/html').body.firstChild
            
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            content.el.replaceWith(newContentEl)
            content.el = newContentEl
            
            // Prevent the body element's click event from deactivating the active content when clicking the
            // active element itself.
            newContentEl.addEventListener('click', (e) => {
                if (state.activePageContentId === pageContentId) {
                    e.stopPropagation()
                }
            })
        },
        async pinSiteTree({state, commit, dispatch}) {
            commit('pinSiteTree', !state.pinSiteTree)
            await dispatch('setLocalStorage')
        },
        setLocalStorage({state}) {
            localStorage.setItem('pageEditor', JSON.stringify({
                pinSiteTree: state.pinSiteTree,
            }))
        },
        setContentAreaStyling({state}, {contentArea, isEmpty}) {
            contentArea.style.minHeight = isEmpty ? '50px' : ''
        },
        keyboardEventHandler({state, dispatch, rootState}, event) {
            if (state.activeSectionId) {
                switch (event.key) {
                    case 'ArrowUp':
                        dispatch('moveSectionUp')
                        return
                    case 'ArrowDown':
                        dispatch('moveSectionDown')
                        return
                    case 'Backspace':
                        dispatch('deleteSection')
                        return
                }
            } else if (state.activePageContentId) {
                switch (event.key) {
                    case 'ArrowUp':
                        dispatch('moveUp', state.activePageContentId)
                        return
                    case 'ArrowDown':
                        dispatch('moveDown', state.activePageContentId)
                        return
                    case 'Backspace':
                        // Prevent content deletion when using Backspace within a form control
                        if (event.target.tagName === 'BODY') {
                            const contentId = rootState.pageContentData.items.find(o => o.id === state.activePageContentId)?.contentId
                            const num = rootState.pageContentData.items.filter(o => o.contentId === contentId)?.length
                            num > 1
                                ? dispatch('detachContent', state.activePageContentId)
                                : dispatch('deleteContent', state.activePageContentId)
                        }
                        return
                }
            }
            
            //console.log('event.key', event.key)
            //console.log('event.code', event.code)
        },
        async addSection({state, dispatch}) {
            const {pageSectionLinkId: sectionId, html} = (await dispatch('request/put', {
                url: 'api/page-section/' + state.pageId
            }, {root: true})).data
            
            const section = new DOMParser().parseFromString(html, 'text/html').body.firstChild
            state.pageSectionsNode.append(section)
            
            await dispatch('initSection', {el: section})
            
            await dispatch('setPageSectionData')
            
            state.activeSectionId = sectionId
            
            return sectionId
        },
        async saveSectionTemplateId({state, dispatch}, sectionTemplateId) {
            const sectionId = state.activeSectionId
            
            const {html} = (await dispatch('request/post', {
                url: 'api/page-section/' + sectionId,
                postData: {sectionTemplateId}
            }, {root: true})).data
            
            const sectionEl = new DOMParser().parseFromString(html, 'text/html').body.firstChild
            
            const section = state.pageSections.find(o => o.id === sectionId)
            section.el.replaceWith(sectionEl) // Replace the node
            section.el = sectionEl // Replace the node's reference for further changes
            
            state.pageSectionData.find(o => o.id === sectionId).sectionTemplateId = sectionTemplateId
            
            dispatch('initAll')
        },
        async saveSectionContainer({state, dispatch}, pageContainerId) {
            const sectionId = state.activeSectionId
            
            const {html} = (await dispatch('request/post', {
                url: 'api/page-section/' + sectionId,
                postData: {pageContainerId}
            }, {root: true})).data
            
            const sectionEl = new DOMParser().parseFromString(html, 'text/html').body.firstChild
            
            const section = state.pageSections.find(o => o.id === sectionId)
            section.el.replaceWith(sectionEl) // Replace the node
            section.el = sectionEl // Replace the node's reference for further changes
            
            state.pageSectionData.find(o => o.id === sectionId).pageContainerId = pageContainerId
            
            dispatch('initAll')
        },
        async goToSection({state}, {router, sectionId}) {
            if (state.activeSectionId !== sectionId) {
                router.push({name: 'pageEditor', params: {pageId: state.pageId, sectionId: sectionId}})
            }
        },
        async goToContentArea({state}, {router, contentAreaName}) {
            if (state.activeContentAreaName !== contentAreaName) {
                const contentArea = state.contentAreas.find(o => o.name === contentAreaName)
                const sectionId = contentArea.sectionId
                const areaIndex = state.contentAreas.map(o => o.name).indexOf(contentAreaName)
                
                await router.push({name: 'pageEditor', params: {pageId: state.pageId, sectionId, areaIndex}})
            }
        },
        async goToContent({state, rootState, commit}, {router, pageContentId, pageId}) {
            commit('leftSidebarActiveTab', 'Content')
            
            // Supports loading content from the current page as well as other pages.
            // When targeting content on a page other than the current one, the content object cannot be loaded from
            // state.contentItems, so must be sourced from rootState.pageContentData.
            // This is used by the ContentPageUse component, for example.
            let sectionId
            let contentAreaName
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            if (content) {
                contentAreaName = content.contentAreaName
                sectionId = state.contentAreas.find(o => o.name === contentAreaName)?.sectionId
            } else {
                const pageContentData = rootState.pageContentData.items.find(o => o.id === pageContentId)
                contentAreaName = pageContentData.contentArea
                sectionId = pageContentData.pageSectionLinkId
            }
            
            pageId = pageId || state.pageId
            
            if (pageId !== state.pageId || pageContentId !== state.activePageContentId) {
                const areaIndex = state.contentAreas.map(o => o.name).indexOf(contentAreaName)
                await router.push({
                    name: 'pageEditor',
                    params: {pageId, sectionId, areaIndex, pageContentId}
                })
            }
        }
    }
}