<template>
    <select v-model="sectionTemplateId" class="form-select form-select-sm w-auto">
        <option value="" selected disabled>Select&hellip;</option>
        <option v-for="o in pageSectionTemplates" :key="o.id" :value="o.id" :selected="o.id === sectionTemplateId">
            {{ o.title }}
        </option>
    </select>
</template>

<script>
export default {
    name: "PageEditorSectionTemplateMenu",
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageSectionTemplates() {
            return this.pageEditor.pageSectionTemplates
        },
        activeSectionId() {
            return this.pageEditor.activeSectionId
        },
        sectionData() {
            return this.pageEditor.pageSectionData?.find(o => o.id === this.activeSectionId)
        },
        sectionTemplateId: {
            get() {
                return this.sectionData?.sectionTemplateId || 0
            },
            set(newValue) {
                this.$store.dispatch('pageEditor/saveSectionTemplateId', newValue)
            }
        }
    }
}
</script>

<style scoped>

</style>