<template>
    <div class="d-flex border-bottom">
        <div class="flex-fill d-flex align-items-center">
            <PageEditorToolbarAddPageBtn/>
            <PageEditorToolbarPageBtn/>
            <PageEditorToolbarSectionBtn/>
            <PageEditorToolbarContentAreaBtn/>
            <PageEditorToolbarContentBtn/>
            <PageEditorToolbarAddContentBtn/>
        </div>

        <div class="d-inline-flex">
            <Button v-if="!showUnpublishedContent" @click.native="toggleShowUnpublishedContent"
                    class="rounded-0 text-danger">
                <i class="bi bi-eye-slash-fill"/>
            </Button>

            <div class="dropdown">
                <div class="d-inline-block" data-bs-toggle="dropdown">
                    <Button class="rounded-0">
                        <i class="bi-three-dots"/>
                    </Button>
                </div>

                <ul class="dropdown-menu">
                    <li>
                        <a href="#" @click.prevent="toggleShowUnpublishedContent" class="dropdown-item small"
                           :class="{'text-danger':!showUnpublishedContent}">
                            <i class="me-1 bi"
                               :class="{'bi-eye':showUnpublishedContent,'bi-eye-slash-fill':!showUnpublishedContent}"/>
                            {{ showUnpublishedContent ? 'Hide' : 'Show' }} unpublished
                        </a>

                        <a href="#" @click.prevent="toggleShowPendingContent" class="dropdown-item small"
                           :class="{'text-success':!showPendingContent}">
                            <i class="me-1 bi"
                               :class="{'bi-eye':showPendingContent,'bi-eye-slash-fill':!showPendingContent}"/>
                            {{ showPendingContent ? 'Hide' : 'Show' }} pending
                        </a>

                        <a href="#" @click.prevent="toggleShowExpiringContent" class="dropdown-item small"
                           :class="{'text-warning':!showExpiringContent}">
                            <i class="me-1 bi"
                               :class="{'bi-eye':showExpiringContent,'bi-eye-slash-fill':!showExpiringContent}"/>
                            {{ showExpiringContent ? 'Hide' : 'Show' }} expiring
                        </a>

                        <a href="#" @click.prevent="toggleShowExpiredContent" class="dropdown-item small"
                               :class="{'text-info':!showExpiredContent}">
                        <i class="me-1 bi"
                           :class="{'bi-eye':showExpiredContent,'bi-eye-slash-fill':!showExpiredContent}"/>
                        {{ showExpiredContent ? 'Hide' : 'Show' }} expired
                    </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import PageEditorToolbarContentAreaBtn from "./PageEditorToolbarContentAreaBtn"
import PageEditorToolbarContentBtn from "./PageEditorToolbarContentBtn"
import PageEditorToolbarAddPageBtn from "./PageEditorToolbarAddPageBtn"
import Button from "../../common/Button.vue"
import PageEditorToolbarAddContentBtn from "./PageEditorToolbarAddContentBtn.vue"
import PageEditorToolbarPageBtn from "./PageEditorToolbarPageBtn.vue"
import PageEditorToolbarSectionBtn from "./PageEditorToolbarSectionBtn.vue"

export default {
    name: "PageEditorToolbar",
    components: {
        PageEditorToolbarSectionBtn,
        PageEditorToolbarPageBtn,
        PageEditorToolbarAddContentBtn,
        Button,
        PageEditorToolbarAddPageBtn,
        PageEditorToolbarContentBtn,
        PageEditorToolbarContentAreaBtn
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        showUnpublishedContent() {
            return this.pageEditor.showUnpublishedContent
        },
        showPendingContent() {
            return this.pageEditor.showPendingContent
        },
        showExpiringContent() {
            return this.pageEditor.showExpiringContent
        },
        showExpiredContent() {
            return this.pageEditor.showExpiredContent
        }
    },
    watch: {
        showUnpublishedContent() {
            this.$store.dispatch('pageEditor/showUnpublishedContent')
        }
    },
    methods: {
        toggleShowUnpublishedContent() {
            this.$store.commit('pageEditor/toggleShowUnpublishedContent')
        },
        toggleShowPendingContent() {
            this.$store.commit('pageEditor/toggleShowPendingContent')
        },
        toggleShowExpiringContent() {
            this.$store.commit('pageEditor/toggleShowExpiringContent')
        },
        toggleShowExpiredContent() {
            this.$store.commit('pageEditor/toggleShowExpiredContent')
        }
    }
}
</script>

<style scoped>

</style>