<template>
    <div class="d-inline-block">
        <Button ref="btn" class="rounded-0" data-bs-toggle="offcanvas" data-bs-target="#pageEditorAddPageOffCanvas"
                aria-controls="pageEditorAddPageOffCanvas" title="Add page"><i class="bi bi-plus-lg"/></Button>

        <div ref="offcanvas" class="offcanvas offcanvas-start" tabindex="-1" id="pageEditorAddPageOffCanvas"
             aria-labelledby="pageEditorAddPageOffCanvasLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="pageEditorAddPageOffCanvasLabel">Add page</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body">
                <Form v-if="formId" :form-id="formId" :show-field-names="true" :hide-help-text="true"/>
            </div>

            <div class="offcanvas-footer text-end p-3">
                <FormSaveBtn v-if="formId" :form-id="formId" :cancel-on-save="true" :button-text="'Save'"/>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"
import Form from "../../common/form/Form.vue"
import FormSaveBtn from "../../common/form/FormSaveBtn.vue"

export default {
    name: "PageEditorToolbarAddPageBtn",
    components: {FormSaveBtn, Form, Button},
    data() {
        return {
            tableName: 'pages',
            id: 0,
            formId: ''
        }
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        siteTreeId() {
            return this.pageEditor.siteTreeId
        },
        selectedNavigationGroup() {
            return this.$store.state[this.siteTreeId]?.selectedNavigationGroup
        }
    },
    mounted() {
        const offcanvasNode = document.getElementById('pageEditorAddPageOffCanvas')
        offcanvasNode.addEventListener('shown.bs.offcanvas', e => {
            this.createForm()
        })
        offcanvasNode.addEventListener('hidden.bs.offcanvas', e => {
            this.formId = ''
        })
    },
    methods: {
        async createForm() {
            this.formId = 'form:' + this.tableName + ':' + this.id

            await this.$store.dispatch('forms/createForm', {
                tableName: this.tableName,
                id: this.id,
                fieldNames: [
                    'title',
                    'url',
                    'templateId',
                    'type',
                    'componentId',
                    'parent',
                    'navigation'
                ],
                currentData: {
                    navigation: this.selectedNavigationGroup
                },
                onSave: async (o) => {
                    const pageId = o.id

                    // When the router loads the new page, which in turn calls pageEditor/init, init will then create
                    // a new section and target it.
                    this.$store.state.pageEditor.newPage = true

                    await this.$router.push({name: 'pageEditor', params: {pageId: pageId}})

                    // Close the offcanvas
                    this.$refs.btn.$el.click()

                    // Loads the new page into the site tree
                    await this.$store.dispatch(this.siteTreeId + '/init')

                    // Highlight the new page in the site tree
                    this.$store.commit(this.siteTreeId + '/selectedPageId', pageId)
                }
            })

            // Give the field time to compile
            this.$nextTick(() => {
                this.$refs.offcanvas.querySelector('[name="title"]').focus()
            })
        }
    }
}
</script>

<style scoped>

</style>