<template>
    <ImageSelection v-if="showThumbnail || true" :form-id="formId" :column-name="field.name" :file-browser="!isImage"
                    :file-id="fileId" :on-change="onChange" :remove-option="false" :row-id="currentData.id"
                    :table-name="tableName"/>
</template>

<script>
import ImageSelection from '../ImageSelection'

export default {
    name: "FormControlFilesFileName",
    components: {
        ImageSelection
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        fileId() {
            return this.currentData.id
        },
        isImage() {
            return (
                this.currentData.mimetype
                && this.currentData.mimetype.indexOf('image') === 0 // e.g. image/jpeg image/gif
                && this.currentData.mimetype !== 'image/svg+xml'
            )
        },
        showThumbnail() {
            return (
                this.isImage
                // todo - CloudImage can show PDFs so enabling this will present the PDF.
                //        I've not enabled it because the Lightbox fails to present the PDF.
                //|| this.currentData.mimetype === 'application/pdf'
            )
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
    },
    methods: {
        onChange(item) {
            this.$store.commit(this.formId + '/presetData', {
                name: 'name',
                value: item.name,
            })

            this.$store.commit(this.formId + '/presetData', {
                name: 'fileSize',
                value: item.fileSize,
            })

            this.$store.commit(this.formId + '/presetData', {
                name: 'mimetype',
                value: item.mimetype,
            })
            //this.currentData.fileSize = item.fileSize
            //this.currentData.mimetype = item.mimetype

            if (item.width) {
                this.$store.commit(this.formId + '/presetData', {
                    name: 'width',
                    value: item.width,
                })
                //this.currentData.width = item.width
            }

            if (item.height) {
                this.$store.commit(this.formId + '/presetData', {
                    name: 'height',
                    value: item.height,
                })
                //this.currentData.height = item.height
            }
        }
    }
}
</script>

<style scoped>

</style>