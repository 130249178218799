<template>
    <span v-if="count" :title="'Assigned to ' + count + ' item' + ( count > 1 ? 's' : '' ) + '.'"
          class="badge bg-secondary">{{ count }}</span>
</template>

<script>
export default {
    name: "TableCellTypeRelationshipManyToMany",
    props: {
        column: Object,
        item: Object,
        viewId: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        }
    },
    asyncComputed: {
        async count() {
            const o = await this.$store.dispatch('categories/getTableColumnData', {
                tableName: this.tableName,
                columnName: this.column.columnName
            })

            return o.filter(o => o.itemId === this.item.id)?.length
        }
    }
}
</script>

<style scoped>

</style>