<template>
    <button v-if="isFilterActive" class="btn btn-warning rounded-0" type="button" @click="resetAllFilters">
        <i class="bi bi-x"/>
    </button>
</template>

<script>
export default {
    name: "SearchFiltersResetBtn",
    props: {
        viewId: String
    },
    computed: {
        isFilterActive() {
            return this.$store.getters[this.viewId + '/isFilterActive']
        }
    },
    methods: {
        resetAllFilters() {
            this.$store.dispatch(this.viewId + '/resetAllFilters')

            if (Object.keys(this.$route.query).length) {
                this.$router.push({query: null})
            }
        }
    }
}
</script>

<style scoped>

</style>