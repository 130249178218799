<template>
    <div v-if="sectionNumber" class="d-inline">
        <i class="bi-chevron-compact-right mx-1 align-middle"/>

        <Button @mouseover.native="onMouseover" @mouseout.native="onMouseout" @click.native="selectSection"
                class="btn-sm lh-1 p-1">
            <small>Section {{ sectionNumber }}</small>
        </Button>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorToolbarSectionBtn",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        activeContent() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.pageEditor.activePageContentId)
        },
        contentAreaName() {
            return this.pageEditor.activeContentAreaName || this.activeContent?.contentAreaName
        },
        contentArea() {
            return this.pageEditor.contentAreas.find(o => o.name === this.contentAreaName)
        },
        sectionId() {
            return this.pageEditor.activeSectionId || this.contentArea?.sectionId
        },
        sectionNumber() {
            return this.pageEditor.pageSections.map(o => o.id).indexOf(this.sectionId) + 1
        }
    },
    methods: {
        onMouseover() {
            this.$store.commit('pageEditor/hoverSectionId', this.sectionId)
        },
        onMouseout() {
            this.$store.commit('pageEditor/hoverSectionId', 0)
        },
        async selectSection() {
            await this.$store.dispatch('pageEditor/goToSection', {
                router: this.$router,
                sectionId: this.sectionId
            })

            this.$store.commit('pageEditor/leftSidebarActiveTab', 'Content')

            await this.$store.dispatch('pageEditor/scrollToSection', {
                sectionId: this.sectionId
            })
        }
    }
}
</script>

<style scoped>

</style>