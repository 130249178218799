var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "btn-sm d-flex align-items-center gap-2",
      attrs: { disabled: _vm.isDisabled },
      nativeOn: {
        click: function ($event) {
          return _vm.moveDown.apply(null, arguments)
        },
      },
    },
    [
      _c("i", { staticClass: "bi bi-chevron-down" }),
      _vm._v("\n    Move down\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }