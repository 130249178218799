<template>
    <FormControlPlainText v-if="field.readonly || isTitleColumn" :value="currentData[field.name]" :form-id="formId"/>
    <input v-else :list="field.datalist ? 'datalist-' + field.id : false" :id="field.id" ref="field"
           v-model="currentData[field.name]" v-select="field.autofocus" v-form-validation :disabled="field.disabled"
           :minlength="field.minlength" :name="field.name" :pattern="field.pattern || false"
           :placeholder="field.placeholder || false" :required="field.required" :size="field.size" autocomplete="off"
           class="form-control" :class="{'is-invalid': field.error, 'form-control-sm': formSmall}" type="text">
</template>

<script>
import uniqueValueRequired from '../../vue/mixins/unique-value-required'
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"
import moment from "moment/moment"

export default {
    name: "FormControlTypeText",
    mixins: [uniqueValueRequired],
    components: {FormControlPlainText},
    directives: {formValidation},
    props: {
        formId: String,
        currentData: Object,
        field: Object,
        value: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        component() {
            return this.$store.state.components.items.find(o => o.id === this.componentId)
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
        displayName() {
            return this.component.displayName
        },
        isDisplayNameSet() {
            return !!this.displayName?.length
        },
        isTitleColumn() {
            const bool = this.isDisplayNameSet && this.nameField === this.field.columnName

            if (bool) {
                // There's no need to show the required help text when the user has no direct control over the value
                this.field.required = false
            }

            return bool
        },
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: ''
        })
    },
    watch: {
        currentData: {
            async handler(newValue) {
                // todo Would it be much simpler if this was replaced by an API request which tapped into PHP's
                //      DisplayName class? It would avoid having to update two scripts to support a single feature.
                if (this.isTitleColumn) {
                    const fields = this.$store.getters['componentStructure/get'](this.componentId)

                    let newStr = ''
                    let hasSetValues = false

                    const parts = this.displayName.split(',')
                    for (const part of parts) {
                        let str = part.trim()

                        // If the str is wrapped in single quotes then it's a string and not a column name.
                        if (str[0] === "'") {
                            const len = str.length
                            str = str.slice(1, len - 1)
                            newStr += str

                        } else {
                            const keys = Object.keys(newValue)
                            const columnName = str
                            if (keys.indexOf(columnName)) {
                                const field = fields.find(o => o.columnName === columnName)
                                let value = newValue[columnName]

                                if (value) {
                                    hasSetValues = true
                                }

                                let dateStr = ''
                                const type = field.type
                                switch (type) {
                                    case 'dateDate':
                                        if (value) {
                                            dateStr = moment(value).format('DD.MM.YY')
                                        }
                                        newStr += dateStr
                                        break

                                    case 'datetime-local':
                                        if (value) {
                                            dateStr = moment(value).format('DD.MM.YY HH:mm')
                                        }

                                        newStr += dateStr
                                        break

                                    case 'date':
                                        if (value) {
                                            // todo Duplicate of TableCellTypeDate
                                            let format
                                            switch (field.dateFormat) {
                                                case 'dd MMM yyyy':
                                                    format = 'DD MMM YYYY'
                                                    break
                                                case 'dd MMM yyyy, HH:mm':
                                                default:
                                                    format = 'DD MMM YYYY, HH:mm'
                                            }

                                            dateStr = moment(value * 1000).format(format)
                                        }

                                        newStr += dateStr
                                        break

                                    case 'relationshipOneToMany':
                                        if (value) {
                                            const component = this.$store.state.components.items.find(o => o.id === field.categoryComponentId)
                                            const tableName = component.tableName
                                            const o = await this.$store.dispatch('itemData/get', {
                                                tableName: tableName,
                                                id: value
                                            })
                                            const nameField = this.$store.getters['components/nameField'](component.id)
                                            newStr += o[nameField]

                                        } else {
                                            newStr += ''
                                        }
                                        break

                                    case 'text':
                                        value = value.trim() // Prevents extra whitespace e.g. "Nick  Wright "
                                        newStr += value
                                        break
                                }
                            }
                        }
                    }

                    this.currentData[this.field.name] = hasSetValues ? newStr : ''
                }
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>