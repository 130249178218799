var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.pageSectionsContentAreas?.length
        ? _c("div", [_vm._v(_vm._s(_vm.pageSectionsContentAreas))])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.pageSectionsContentAreas, function (section, i) {
        return _vm.pageSectionLinkItems
          ? _c("div", { staticClass: "card mb-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header py-1 pe-2 d-flex align-items-center",
                },
                [
                  _c("span", { staticClass: "flex-fill" }, [
                    _vm._v(_vm._s(section.name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      staticClass: "btn-sm",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.moveSectionUp(i)
                        },
                      },
                    },
                    [_c("i", { staticClass: "bi bi-chevron-up" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      staticClass: "btn-sm",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.moveSectionDown(i)
                        },
                      },
                    },
                    [_c("i", { staticClass: "bi bi-chevron-down" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      staticClass: "btn-sm",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.deleteSection(i)
                        },
                      },
                    },
                    [_c("i", { staticClass: "bi bi-x-lg" })]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-body" },
                _vm._l(section.areas, function (area, k) {
                  return _c(
                    "div",
                    {
                      staticClass: "card",
                      class: { "mb-3": k < section.areas.length - 1 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header py-1 pe-2 d-flex align-items-center",
                        },
                        [
                          _c("span", { staticClass: "flex-fill" }, [
                            _vm._v(_vm._s(area.text)),
                          ]),
                          _vm._v(" "),
                          _c("Button", { staticClass: "btn-sm" }, [
                            _c("i", { staticClass: "bi bi-plus-lg" }),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("PageContentArea", {
                        attrs: {
                          "page-section-link-id": section.id,
                          area: area.value,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      _c("Button", [
        _c("i", { staticClass: "bi bi-plus-lg" }),
        _vm._v(" Add section"),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }