var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.promotions.length === 0
        ? _c("FormControlPlainText", { attrs: { "form-id": _vm.formId } }, [
            _c("em", [_vm._v("No promotions available.")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.promotions, function (promotion) {
        return _c("div", { staticClass: "radio" }, [
          _c("label", [
            _c("input", {
              attrs: { type: "checkbox" },
              domProps: {
                checked: _vm.categoryIds?.indexOf(promotion.id) > -1,
              },
              on: {
                click: function ($event) {
                  return _vm.toggleSelection(promotion.id)
                },
              },
            }),
            _vm._v("\n            " + _vm._s(promotion.title) + "\n        "),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }