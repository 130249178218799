<template>
    <div>
        <div v-if="pageSectionsContentAreas?.length">{{ pageSectionsContentAreas }}</div>
        <div v-if="pageSectionLinkItems" v-for="(section, i) in pageSectionsContentAreas" class="card mb-3">
            <div class="card-header py-1 pe-2 d-flex align-items-center">
                <span class="flex-fill">{{ section.name }}<!-- ({{ section.id }})--></span>
                <Button @click.native="moveSectionUp(i)" class="btn-sm"><i class="bi bi-chevron-up"/></Button>
                <Button @click.native="moveSectionDown(i)" class="btn-sm"><i class="bi bi-chevron-down"/></Button>
                <Button @click.native="deleteSection(i)" class="btn-sm"><i class="bi bi-x-lg"/></Button>
            </div>

            <div class="card-body">
                <div v-for="(area, k) in section.areas" class="card" :class="{'mb-3': k < section.areas.length - 1}">
                    <div class="card-header py-1 pe-2 d-flex align-items-center">
                        <span class="flex-fill">{{ area.text }}</span>
                        <Button class="btn-sm"><i class="bi bi-plus-lg"/></Button>
                    </div>
                    <PageContentArea :page-section-link-id="section.id" :area="area.value"/>
                </div>
            </div>
        </div>

        <Button><i class="bi bi-plus-lg"/> Add section</Button>
    </div>
</template>

<script>
import PageContentArea from "./PageContentArea.vue"
import Button from "../Button.vue"

export default {
    name: "PageContentSections",
    components: {Button, PageContentArea},
    props: {
        pageId: Number
    },
    computed: {
        pageSectionsContentAreas() {
            return this.$store.getters['pageSectionsContentAreas/pageData'](this.pageId)
        },
        pageSectionLinkIds() {
            if (this.pageSectionsContentAreas) {
                return this.pageSectionsContentAreas.map(o => o.id)
            }
        }
    },
    asyncComputed: {
        async pageSectionLinkItems() {
            await this.$store.dispatch('pageSectionLink/init')

            return this.$store.state.pageSectionLink.data.filter(o => o.pageId === this.pageId)
        },
    },
    methods: {
        async moveSectionUp(i) {
            if (i === 0) {
                console.log('The first section cannot be moved higher.')
                return
            }

            const section = this.pageSectionsContentAreas[i]

            await this.$store.dispatch('request/patch', {url: 'api/page-section/' + section.id + '/up'}, {root: true})

            //this.$store.commit('pageSectionsContentAreas/data', {pageId: this.pageId, data: undefined})
            await this.$store.dispatch('pageSectionsContentAreas/get', this.pageId)
            /*
            const i = state.pageSections.map(o => o.id).indexOf(state.activeSectionId)
            const previousSection = state.pageSections[i - 1].el
            const section = state.pageSections.splice(i, 1)[0]
            state.pageSections.splice(i - 1, 0, section)
            section.el.after(previousSection)
             */

            console.log({section})
        },
        moveSectionDown(i) {
            if (i === this.pageSectionsContentAreas.length - 1) {
                console.log('The last section cannot be moved lower.')
                return
            }

            const section = this.pageSectionsContentAreas[i]
        },
        deleteSection(i) {
            console.log('Remove', i)
        }
    }
}
</script>

<style scoped>

</style>