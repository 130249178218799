<template>
    <Button @click.native="deleteSection" class="btn-sm d-flex align-items-center gap-2 text-danger">
        <i class="bi bi-trash"></i>
        Delete section
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorSectionDeleteBtn",
    components: {Button},
    methods: {
        deleteSection() {
            this.$store.dispatch('pageEditor/deleteSection')
        }
    }
}
</script>

<style scoped>

</style>