var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { name: _vm.field.name },
      model: {
        value: _vm.categoryIds,
        callback: function ($$v) {
          _vm.categoryIds = $$v
        },
        expression: "categoryIds",
      },
    },
    [
      !_vm.field.readonly
        ? _c(
            "div",
            { staticClass: "mt-1 mb-2" },
            [
              _c(
                "Button",
                {
                  class: { "btn-sm border": _vm.formSmall },
                  attrs: { disabled: _vm.field.disabled },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.selectCategories.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "bi-plus-lg" })]
              ),
              _vm._v(" "),
              _vm.targetTableName
                ? _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "user-access",
                          rawName: "v-user-access",
                          value: _vm.targetTableName,
                          expression: "targetTableName",
                        },
                      ],
                      class: { "btn-sm border": _vm.formSmall },
                      attrs: { disabled: _vm.field.disabled },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.manageCategories.apply(null, arguments)
                        },
                      },
                    },
                    [_c("i", { staticClass: "bi-pencil-square" })]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.targetTableName
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.field.disabled,
                  expression: "!field.disabled",
                },
              ],
              staticClass: "list-group mb-0",
            },
            _vm._l(_vm.categoryIds, function (id, index) {
              return !_vm.restrictedIds || _vm.restrictedIds.indexOf(id) === -1
                ? _c(
                    "a",
                    {
                      key: id,
                      staticClass: "list-group-item d-flex align-items-center",
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.editItem(id)
                        },
                      },
                    },
                    [
                      _c("FormControlCategoryTitle", {
                        staticClass: "flex-fill",
                        attrs: { id: id, "table-name": _vm.targetTableName },
                      }),
                      _vm._v(" "),
                      _c("ItemStatusIcons", {
                        staticClass: "me-2 gap-2",
                        attrs: { "table-name": _vm.targetTableName, id: id },
                      }),
                      _vm._v(" "),
                      !_vm.field.readonly
                        ? [
                            !_vm.selectLimit || _vm.selectLimit > 1
                              ? _c(
                                  "Button",
                                  {
                                    staticClass: "btn-sm",
                                    attrs: { disabled: !index },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.moveUp(id)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "bi-chevron-up" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.selectLimit || _vm.selectLimit > 1
                              ? _c(
                                  "Button",
                                  {
                                    staticClass: "btn-sm",
                                    attrs: {
                                      disabled:
                                        index === _vm.categoryIds.length - 1,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.moveDown(id)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "bi-chevron-down" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "Button",
                              {
                                staticClass: "btn-sm",
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.removeCategory(id)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "bi-x-lg" })]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e()
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }