import categories from "./categories"

export default {
    namespaced: true,
    state() {
        return {
            show: true,
            tableName: undefined,
            id: undefined,
            
            fields: [],
            legends: [],
            
            currentData: {},
            originalData: {},
            
            formEl: undefined,
            isSaving: false,
            hasJustBeenSaved: false,
            onSave: function(item) {
            },
            
            // When a user clicks an anchor this is set.
            // The Form component watches for changes to it and scrolls the form to the anchors fieldset.
            clickedAnchor: undefined,
            
            // todo - This will be used to trigger the feature that only shows the active fieldset.
            showAllFieldsets: true,
            
            formSmall: false,
            singularLabel: 'item',
            
            // Custom methods for overriding defaults
            onDelete: undefined, // Overrides the "delete" action entirely
            onDeleteConfirm: undefined, // Overrides the "delete" action's onConfirm method
            onDeleteComplete: undefined,
            
            ckEditorToolbar: 'default'
        }
    },
    mutations: {
        toggleShow(state) {
            state.show = false
            setTimeout(() => {
                state.show = true
            }, 50)
        },
        setFormEl(state, el) {
            state.formEl = el
        },
        ckEditorToolbar(state, toolbar) {
            state.ckEditorToolbar = toolbar
        },
        // Allows fields to change the original data without triggering isDataModified.
        // This is required for some fields which must tweak the value provided by PHP
        // For example, the link field must remove the backslashes from the JSON string
        // to bring its value in line with how the JS outputs the JSON string.
        presetData(state, d) {
            let name = d.name
            let value = d.value
            
            // If the value is an object then a copy must be assigned to the
            // original data otherwise changes to the model will be reflected
            // in both, resulting in no modifications ever being detected.
            let originalDataValue = (
                typeof value === 'object'
                    ? JSON.parse(JSON.stringify(value))
                    : value
            )
            
            // Support names that are objects e.g. content['intro'] or options[19]
            // Required for site tree options as the names of these are provided
            // in the format of content['name'].
            if (name.indexOf('[') !== -1) { // content['intro'] or options[19]
                let arr1 = name.split('[')      // ["content","'intro']"] or ["options","19]"]
                let name1 = arr1[0]            // "content" or "options"
                let arr2 = arr1[1].split(']')   // ["'intro'",""] or [19, ""]
                let newName = arr2[0]
                
                // modulestructure when adding a new item was throwing an error
                // due to the field[] array
                if (name1 !== 'field') {
                    newName = newName.replace(/["']/g, '') // 'intro' = intro
                    
                    // todo - The following will need to use $set.
                    
                    if (state.currentData[name1] === null) {
                        state.currentData[name1] = {}
                    }
                    if (state.originalData[name1] === null) {
                        state.originalData[name1] = {}
                    }
                    
                    state.currentData[name1][newName] = value     // E.g. state.currentData.content.introduction = "";
                    state.originalData[name1][newName] = originalDataValue
                }
                
            } else {
                //state.originalData[name] = originalDataValue;
                //state.currentData[name] = value;
                this._vm.$set(state.originalData, name, originalDataValue)
                this._vm.$set(state.currentData, name, value)
            }
        },
        set(state, d) {
            // This was required for insert forms, because currentData is an empty object so none of its properties are
            // reactive.
            // Update: I've moved this into init();
            this._vm.$set(state.currentData, d.name, d.value)
            
            state.currentData[d.name] = d.value
        },
        setFieldProperty(state, {fieldName, property, value}) {
            const field = state.fields.find(o => o.columnName === fieldName)
            if (field) {
                field[property] = value
            }
        },
        addField(state, {field}) {
            field.error = ''
            field.visibility = ''
            state.fields.push(field)
        },
        applyModifiedData(state) {
            // Overwrite the originalData with the currentData to reset the modified data.
            // Use a copy so that references to currentData aren't applied to originalData.
            Object.assign(state.originalData, JSON.parse(JSON.stringify(state.currentData)))
        }
    },
    getters: {
        // Note, this had to be updated to Method Style Access because it was caching its state between forms.
        //       For example, the OdpAddCreditModal was returning true even when it was false. You can test this
        //       by adding a console.log below, which won't be output when the result is cached.
        isModified: (state) => () => {
            // The order of currentData's properties may be inconsistent with originalData's due to setting and
            // unsetting individual properties, so the properties must be sorted in order to make a comparison.
            function sortObject(unordered) {
                // Copy the object so the original isn't modified
                unordered = JSON.parse(JSON.stringify(unordered))
                
                return Object.keys(unordered)
                    .sort()
                    .reduce((obj, key) => {
                        obj[key] = unordered[key]
                        return obj
                    }, {})
            }
            
            //console.log('isModified ' + state.tableName, JSON.stringify(sortObject(state.currentData)) !== JSON.stringify(sortObject(state.originalData)));
            
            return JSON.stringify(sortObject(state.currentData)) !== JSON.stringify(sortObject(state.originalData))
        },
        modifiedData: (state, getters) => () => {
            let modifiedData = false
            if (getters.isModified) {
                let obj = state.currentData
                for (let prop in obj) {
                    if (obj.hasOwnProperty(prop)) {
                        let newValue = obj[prop]
                        
                        let oldValue = state.originalData ? state.originalData[prop] : undefined
                        
                        if (
                            JSON.stringify(oldValue) !== JSON.stringify(newValue)
                            
                            // If a field invalidates then its value is set by Angular to undefined.
                            // This causes a conflict between preset values. For example, the text field's
                            // default value is an empty string. Therefore, if the user enters a value to
                            // a text field and then empties it again the resulting condition on a required
                            // field would be: ("" !== undefined), which is true.
                            && newValue !== undefined
                        ) {
                            if (!modifiedData) {
                                modifiedData = {}
                            }
                            
                            modifiedData[prop] = newValue
                        }
                    }
                }
            }
            
            return modifiedData
        },
        field: (state) => (fieldName) => {
            return state.fields.find(o => o.columnName === fieldName)
        },
        componentId: (state, getters, rootState) => {
            return rootState.components.componentIds[state.tableName]
        },
        component: (state, getters, rootState) => {
            return rootState.components.items.find(obj => obj.id === getters.componentId)
        },
        showDisplayOrderOption: (state, getters, rootState, rootGetters) => {
            return !!(
                getters.component.showDisplayOrder
                && state.currentData
                && !state.currentData.isArchived
                // todo - Display Order should be disabled for the content component.
                && state.tableName !== 'content'
            )
        }
    },
    actions: {
        async init({rootState, state, getters, commit, dispatch, rootGetters}, d) {
            state.tableName = d.tableName
            state.id = d.id
            state.currentData = d.currentData || {}
            state.onSave = d.onSave || state.onSave
            state.onDelete = d.onDelete
            state.onDeleteConfirm = d.onDeleteConfirm
            state.onDeleteComplete = d.onDeleteComplete
            
            state.singularLabel = d.singularLabel || state.singularLabel
            
            state.fields = []
            state.legends = []
            
            state.formSmall = d.formSmall || false
            
            const obj = await dispatch('itemData/get', {
                tableName: d.tableName,
                id: d.id,
            }, {root: true})
            
            if (d.id) {
                // Mutations Follow Vue's Reactivity Rules
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                // "Replace that Object with a fresh one."
                state.originalData = JSON.parse(JSON.stringify(obj))
                state.currentData = JSON.parse(JSON.stringify(obj))
            } else if (d.currentData) {
                state.originalData = JSON.parse(JSON.stringify(d.currentData))
                state.currentData = JSON.parse(JSON.stringify(d.currentData))
            }
            
            const objs = rootGetters['componentStructure/get'](getters.componentId)
            let allFields = JSON.parse(JSON.stringify(objs))
            // Set a reactive error property.
            allFields.forEach(o => o.error = '')
            allFields.forEach(o => o.visibility = '')
            
            let fields = []
            if (
                d.fieldNames
                && d.fieldNames.length) {
                
                // Outputs the fields in the same order in which they're listed in fieldNames.
                // This was implemented for the form builder so that the field names could be provided
                // alphabetically with the labels at the top.
                let matchedFields
                d.fieldNames.forEach((fieldName) => {
                    matchedFields = allFields.filter(o => o.columnName === fieldName)
                    if (matchedFields.length) {
                        let field = matchedFields[0]
                        fields.push(field)
                    }
                })
            } else {
                fields = allFields
            }
            
            fields.forEach((field) => {
                commit('addField', {
                    field: field
                })
            })
            
            let legends = []
            
            let fieldsets = rootState.fieldsets.items
            if (fieldsets.length) {
                const fieldsetIdsInUse = fields.map(o => o.fieldsetId)
                fieldsets = fieldsets.filter(o => o.componentId === getters.componentId && fieldsetIdsInUse.indexOf(o.id) > -1)
                legends = fieldsets.map(o => o.legend)
                
                if (getters.component.showMetadata) legends.push('SEO')
                if (getters.component.showScheduling) legends.push('Scheduling')
                
                // todo - deprecated - pre fieldsets form
            } else {
                if (fields.length) {
                    legends = fields
                        .filter(o => o.type === 'fieldset')
                        .map(o => o.title)
                    
                    // All fields must be grouped by fieldsets and legends. If the first element in a form is
                    // not a legend then group it in a General legend.
                    if (fields[0].type !== 'fieldset') {
                        legends.unshift('General')
                    }
                }
            }
            
            state.legends = legends
            
            if (d.onInit) d.onInit()
        },
        setDefaultValue({state, commit}, d) {
            let name = d.name
            
            if (
                state.currentData[name] === ''
                || state.currentData[name] === null
                || state.currentData[name] === undefined
            ) {
                commit('presetData', d)
            }
        },
        async save({dispatch, getters, state}) {
            // If this is an insert form and presetData has been used to preset some field's data, then those field's
            // values won't be included in modifiedData. We must therefore use currentData.
            const modifiedData = state.id ? getters.modifiedData() : state.currentData
            
            // If the save event is triggered for an existing item which has no modified data do nothing.
            if (state.currentData.id && !modifiedData) {
                return Promise.resolve(/*{foo: 'bar'}*/)
            }
            
            const isValid = await dispatch('validateForm')
            if (!isValid) {
                return Promise.resolve({invalid: true})
            }
            
            // Product variations forms
            if (state.tableName.indexOf('product_variations__') === 0) {
                return dispatch('saveVariations')
            }
            
            state.isSaving = true
            
            const o = state.id
                ? await dispatch('request/patch', {
                    url: 'api/component/' + state.tableName + '/' + state.id,
                    postData: modifiedData
                }, {root: true})
                : await dispatch('request/post', {
                    url: 'api/component/' + state.tableName,
                    postData: modifiedData
                }, {root: true})
            
            const item = o.data
            
            dispatch('onSave', item)
            
            state.isSaving = false
            
            return item
        },
        validateForm({commit, getters, state}) {
            // Form validation
            if (state.formEl.checkValidity()) {
                return true
            }
            
            // This cannot be used because it also shows valid green ticks. This isn't required for when autosave is in
            // used because we only want to show the current field's invalid state.
            //state.formEl.classList.add('was-validated')
            
            let firstInvalidElement
            
            // This triggers the browser's built in validation to appear.
            //state.formEl.reportValidity()
            
            // Only invalidate the form if an element with a validationMessage was found.
            //   This is a workaround for Safari's handling of datetime-local, which invalidates the form after JS
            //   unsets its value, even though it's valid. This allows an attempt to still save the form.
            // Update: I managed to fix the Safari issue with datetime-local but have left this here as it could
            //   be needed later. The benefit of this approach would be that future issues wouldn't prevent
            //   form submissions, but it would need further testing.
            //let hasInvalidField = false
            Array.from(state.formEl.elements).forEach((element) => {
                
                // Forms elements such as buttons do not have names and do not need to be validated,
                // so ignore any which don't have a name attribute
                let name = element.name
                if (!name) {
                    return
                }
                
                // FormControlTypeDate uses period's to append Min, Hour etc. onto the field's name.
                if (name.indexOf('.') > -1) {
                    name = element.name.split('.')[0]
                }
                
                // Always update the field so that when validationMessage === '' its error state is unset.
                commit('setFieldProperty', {
                    fieldName: name,
                    property: 'error',
                    value: element.validationMessage
                })
                
                if (
                    !firstInvalidElement
                    && element.validationMessage
                ) {
                    firstInvalidElement = element
                    element.closest('[data-form-group]').scrollIntoView()
                    //hasInvalidField = true
                }
                
                /* Displays a validation alert
                if (element.validationMessage) {
                    let field = getters.field(name);
    
                    dispatch('toasts/add', {
                        title: 'Validation error!',
                        body: <strong>' + field.title + '</strong><br>' + element.validationMessage
                    }, {root: true});
                }
                //*/
                
                // All field's should be available and present an error.
                if (
                    element.validationMessage // Omits buttons etc. which don't have a validationMessage.
                    && !getters.field(name)
                ) {
                    console.error('Invalid field:', [
                        name,
                        element.validationMessage,
                        //https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation
                        element.validity,
                        element
                    ])
                    //hasInvalidField = true
                }
                
                if (element.validationMessage) {
                    //hasInvalidField = true
                }
            })
        },
        onSave({state, commit, dispatch}, item) {
            if (!item) {
                state.isSaving = false
                return
            }
            
            const tableName = state.tableName
            const itemId = item.id
            
            state.hasJustBeenSaved = true
            
            commit('cacheNeedsClearing', null, {root: true})
            
            dispatch('itemData/set', {tableName, id: itemId, property: item}, {root: true})
            
            const columnNames = state.fields.filter(o => o.type === 'relationshipManyToMany')
                .map(o => o.columnName)
            for (const k in columnNames) {
                const columnName = columnNames[k]
                
                const catIds = item[columnName]
                if (catIds !== undefined) {
                    const data = []
                    catIds.forEach((catId, k) => data.push({itemId, catId, displayOrder: k + 1}))
                    
                    commit('categories/itemData', {tableName, columnName, itemId, data}, {root: true})
                }
            }
            
            commit('applyModifiedData')
            
            // The reason that a state.onSave is required is to allow components to inject onSave scripts. For example,
            // both the Edit and SiteTreeEdit components use the FormSaveBtn, but the SiteTreeEdit component has a
            // different onSave requirement. It wouldn't be good to bloat FormSaveBtn with these scripts.
            state.onSave(item)
        },
        delete({state, dispatch}, {siteTree}) {
            if (state.onDelete) {
                state.onDelete()
                return
            }
            
            dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Delete ' + state.singularLabel,
                    modalBody: `
                        <p class="alert alert-danger">This <strong>cannot</strong> be undone.</p>
                        <p>Are you sure?</p>
                    `,
                    onConfirm: () => {
                        if (state.onDeleteConfirm) {
                            state.onDeleteConfirm()
                            return
                        }
                        
                        dispatch('itemData/delete', {
                            tableName: state.tableName,
                            id: state.id
                        }, {root: true})
                            .then(() => {
                                if (state.onDeleteComplete) {
                                    state.onDeleteComplete()
                                    return
                                }
                                
                                window.location = siteTree ? '/#/site_tree/' : '/#/' + state.tableName
                            })
                    }
                }
            }, {root: true})
        },
        saveVariations({state, getters, dispatch, commit, rootGetters}) {
            // Get the structure of the type's table, so that we can collect the option's data in the correct order
            // as it must be in the same order as the fields in the table.
            const objs = rootGetters['componentStructure/get'](getters.componentId)
            let values = []
            objs.forEach((obj) => {
                if (obj.columnName.indexOf('option') === 0) {
                    let value = state.currentData[obj.columnName]
                    values.push(value)
                }
            })
            
            let calculateCartesianProductTotal = (paramArray) => {
                let total = 0
                paramArray.forEach((params, key) => {
                    key === 0
                        ? total = params.length
                        : total = total * params.length
                })
                return total
            }
            
            // I believe this limit was in place due to massive post requests failing to send.
            let total = calculateCartesianProductTotal(values)
            let limit = 20000
            if (total > limit) {
                dispatch('toasts/add', {
                    title: 'Too many variations!',
                    body: '<strong>' + total + '</strong> variations detected.<br>' +
                        'You may create up to <strong>' + limit + '</strong> at a time.'
                }, {root: true})
                
                dispatch('onSave')
                return
            }
            
            let cartesianProduct = (paramArray) => {
                
                // To avoid this function killing the browser, prevent it from calculating excessively large arrays.
                let limit = 500000
                let total = calculateCartesianProductTotal(paramArray)
                if (total > limit) {
                    console.error('cartesianProduct() is set to not create more than ' + limit + ' items.')
                    return false
                }
                
                function addTo(curr, args) {
                    let i
                    let copy
                    let rest = args.slice(1)
                    let last = !rest.length
                    let result = []
                    
                    for (i = 0; i < args[0].length; i++) {
                        copy = curr.slice()
                        copy.push(args[0][i])
                        
                        last
                            ? result.push(copy)
                            : result = result.concat(addTo(copy, rest))
                    }
                    
                    return result
                }
                
                return addTo([], paramArray)
            }
            
            return dispatch('request/post', {
                url: 'api/variations',
                postData: {
                    productId: state.currentData.productId,
                    sku: state.currentData.sku,
                    comparisonPrice: state.currentData.comparisonPrice,
                    price: state.currentData.price,
                    variations: cartesianProduct(values)
                }
            }, {root: true})
                .then((obj) => {
                    if (obj.data.duplicates) {
                        dispatch('toasts/add', {
                            body: obj.data.duplicates + ' duplicates were not saved.'
                        }, {root: true})
                    }
                    
                    commit('cacheNeedsClearing', null, {root: true})
                    
                    // Without this the "Your unsaved changes will be lost" alert will be displayed.
                    commit('applyModifiedData')
                    
                    dispatch('onSave')
                })
        }
    }
}