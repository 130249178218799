var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "d-flex flex-column gap-2 h-100 p-3" },
    [
      _c("h2", { staticClass: "h5 mb-3" }, [
        _vm._v("\n        Section " + _vm._s(_vm.sectionNumber) + "\n        "),
        _c("br"),
        _vm._v(" "),
        _c("small", { staticClass: "lh-1 text-body-secondary" }, [
          _vm._v("section"),
        ]),
      ]),
      _vm._v(" "),
      _c("PageEditorSectionTemplateMenu"),
      _vm._v(" "),
      _c("PageEditorSectionContainerMenu"),
      _vm._v(" "),
      _c("PageEditorSectionMoveUpBtn"),
      _vm._v(" "),
      _c("PageEditorSectionMoveDownBtn"),
      _vm._v(" "),
      _c("PageEditorSectionDeleteBtn"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }