<template>
    <section class="d-flex flex-column h-100 p-3">
        <h2 class="h5 mb-3">
            {{ contentAreaName }}
            <br>
            <small class="lh-1 text-body-secondary">area</small>
        </h2>

        <div v-if="!isHeaderOrFooterArea" class="d-grid gap-1">
            <Button  @click.native="setOffcanvas" class="btn-sm border d-flex align-items-center gap-2" data-bs-toggle="offcanvas"
                    data-bs-target="#addContentOffcanvas"
                    aria-controls="addContentOffcanvas">
                <i class="bi bi-plus-lg"></i>
                New content
            </Button>

            <Button @click.native="setOffcanvas" class="btn-sm border d-flex align-items-center gap-2" data-bs-toggle="offcanvas"
                    data-bs-target="#addExistingContentOffcanvas"
                    aria-controls="addExistingContentOffcanvas">
                <i class="bi-diagram-3-fill"/>
                Existing content
            </Button>
        </div>
    </section>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorContentAreaPanel",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        contentAreaName() {
            return this.pageEditor.activeContentAreaName
        },
        isHeaderOrFooterArea() {
            return this.contentAreaName === 'Page header' || this.contentAreaName === 'Page footer'
        }
    },
    methods: {
        setOffcanvas() {
            this.$store.commit('pageEditor/offcanvasEnd', true)
        }
    }
}
</script>

<style scoped>

</style>