<template>
    <section class="d-flex flex-column gap-2 h-100 p-3">
        <h2 class="h5 mb-3">
            Section {{ sectionNumber }}
            <br>
            <small class="lh-1 text-body-secondary">section</small>
        </h2>

        <PageEditorSectionTemplateMenu/>
        <PageEditorSectionContainerMenu/>
        <PageEditorSectionMoveUpBtn/>
        <PageEditorSectionMoveDownBtn/>
        <PageEditorSectionDeleteBtn/>
    </section>
</template>

<script>
import PageEditorContentMoveDownBtn from "./PageEditorContentMoveDownBtn.vue"
import PageEditorSectionMoveDownBtn from "./PageEditorSectionMoveDownBtn.vue"
import PageEditorSectionMoveUpBtn from "./PageEditorSectionMoveUpBtn.vue"
import PageEditorSectionDeleteBtn from "./PageEditorSectionDeleteBtn.vue"
import PageEditorSectionTemplateMenu from "./PageEditorSectionTemplateMenu.vue"
import PageEditorSectionContainerMenu from "./PageEditorSectionContainerMenu.vue"

export default {
    name: "PageEditorSectionPanel",
    components: {
        PageEditorSectionContainerMenu,
        PageEditorSectionTemplateMenu,
        PageEditorSectionDeleteBtn,
        PageEditorSectionMoveUpBtn,
        PageEditorSectionMoveDownBtn,
        PageEditorContentMoveDownBtn
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        activeSectionId() {
            return this.pageEditor.activeSectionId
        },
        sectionNumber() {
            return this.pageEditor.pageSections.map(o => o.id).indexOf(this.activeSectionId) + 1
        }
    }
}
</script>

<style scoped>

</style>