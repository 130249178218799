<template>
    <div>
        <FormControlPlainText class="small">
            Email sent:
            <span v-if="lastSent">{{ dateStr }}</span>
            <em v-else>never</em>
        </FormControlPlainText>

        <Button @click.native="resendEmail" :disabled="isDisabled" class="border">Send receipt</Button>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"
import FormControlPlainText from "../../common/form-control/FormControlPlainText.vue"
import moment from "moment/moment"

export default {
    name: "FormControlOdpPartyBookingsResendEmail",
    components: {FormControlPlainText, Button},
    data() {
        return {
            isDisabled: false
        }
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        value: String
    },
    computed: {
        lastSent() {
            return this.currentData[this.field.name] || ''
        },
        dateStr() {
            return this.lastSent ? moment(this.lastSent).format('DD MMM YYYY, HH:mm') : ''
        },
        partyBookingId() {
            return this.currentData.id
        }
    },
    methods: {
        async resendEmail() {
            this.isDisabled = true

            const o = await this.$store.dispatch('request/post', {
                url: 'api/odp/send-party-booking-email',
                postData: {
                    partyBookingId: this.partyBookingId
                }
            })
            const newDatetime = o.data.partyBooking.resendEmail

            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: newDatetime
            })
        }
    }
}
</script>

<style scoped>

</style>