<template>
    <div v-if="page">
        <a href="#" @click.prevent="onPageClick"
           class="list-group-item list-group-item-action d-flex align-items-center gap-2 border-0 small"
           :class="{'p-1 pe-2': !propagatedContentId, 'p-0 px-2': propagatedContentId, 'active': isLastSelected || isSelectedContentsPageId || isActive}">

            <!-- Toggle open/close page content -->
            <Button @click.native.prevent.stop="onIconClick" :disabled="!hasChildPages && !hasContent"
                    class="btn-sm p-0"
                    :default-class="'btn-transparent'">
                <i :class="{ 'bi-chevron-down': isOpen, 'bi-chevron-right': !isOpen, 'invisible': !hasChildPages && !hasContent }"/>
            </Button>

            <div class="flex-fill d-inline-flex align-items-center gap-2">
                <template v-if="propagatedContentId">
                    <span :class="{'bi-square': !isChecked, 'bi-check-square': isChecked}"/>

                    <span class="flex-fill d-inline-block py-2">{{ page.title }}</span>

                    <SiteTreePageItemSelectMenu v-if="isChecked && !selectedContentArea" :site-tree-id="siteTreeId"
                                                :page-id="pageId"/>
                </template>

                <span v-else>{{ page.title }}</span>

                <small class="text-body-secondary">{{ page.pageLabel }}</small>
            </div>

            <i v-if="isHomePage" class="bi-house" title="Home page"/>

            <i v-if="page.hidden" class="bi-eye-slash-fill"/>
            <ItemStatusIcons :table-name="'pages'" :id="pageId" :is-active="isActive"/>
        </a>

        <SiteTreeContentAreas v-if="hasContent && isOpen" :page-id="pageId" :site-tree-id="siteTreeId"/>

        <div v-if="showChildPages" class="ps-3">
            <SiteTreePageItem v-for="pageId in parentChildIds[pageId]" :key="pageId" :page-id="pageId"
                              :site-tree-id="siteTreeId"/>
        </div>
    </div>
</template>

<script>
import SiteTreeContentAreas from './SiteTreeContentAreas'
import Button from "../Button.vue"
import ItemStatusIcons from "../ItemStatusIcons.vue"
import SiteTreePageItemSelectMenu from "./SiteTreePageItemSelectMenu.vue"

export default {
    name: "SiteTreePageItem",
    components: {SiteTreePageItemSelectMenu, ItemStatusIcons, Button, SiteTreeContentAreas},
    props: {
        siteTreeId: String,
        pageId: Number
    },
    computed: {
        siteTree() {
            return this.$store.state[this.siteTreeId]
        },
        parentChildIds() {
            return this.siteTree?.parentChildIds
        },
        expandedPageIds() {
            return this.siteTree?.expandedPageIds
        },
        selectedPageId() {
            return this.siteTree?.selectedPageId
        },
        selectedContentId() {
            return this.siteTree?.selectedContentId
        },
        selectedContentsPageId() {
            if (this.selectedContentId) {
                return this.$store.state.pageContentData.items.find((obj) => obj.id === this.selectedContentId).pageId
            }
        },
        lastSelectedPageId() {
            return this.siteTree?.lastSelectedPageId
        },
        hasChildPages() {
            return this.parentChildIds[this.pageId]?.length
        },
        isChecked() {
            if (this.propagatedContentId) {
                return !!this.siteTree.propagationData.find(o => o.pageId === this.pageId)
            }
        },
        isActive() {
            return this.selectedPageId === this.pageId
        },
        isSelectedContentsPageId() {
            return this.selectedContentsPageId === this.pageId
        },
        isOpen() {
            return this.expandedPageIds.indexOf(this.pageId) > -1
        },
        showChildPages() {
            return this.parentChildIds[this.pageId]?.length && this.isOpen
        },
        homePageId() {
            return this.$store.state.settings.homePage
        },
        isHomePage() {
            return this.homePageId && this.homePageId === this.pageId
        },
        isLastSelected() {
            return (
                (
                    this.selectedPageId === 0
                    // Highlights the previously selected page when editing content in the site tree.
                    // todo - This was erroneously targeting this.selectedContentId, so wasn't doing anything.
                    //|| this.siteTree.selectedContentId
                )
                && this.lastSelectedPageId === this.pageId
            )
        },
        showContent() {
            return this.siteTree?.showContent
        },
        hasContent() {
            if (this.showContent) {
                return this.$store.state.pageContentData.items.filter(o => o.pageId === this.pageId).length
            }
        },
        selectContentMode() {
            return this.siteTree.selectContentMode
        },
        propagatedContentId() {
            return this.siteTree?.propagatedContentId
        },
        selectedContentArea() {
            return this.$store.state.pageContentData.items.find(
                o => o.pageId === this.pageId && o.contentId === this.propagatedContentId
            )?.contentArea
        }
    },
    asyncComputed: {
        page() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'pages',
                id: this.pageId
            })
        }
    },
    methods: {
        async onPageClick() {
            if (this.selectContentMode) {
                this.togglePage()
                return
            }

            if (this.propagatedContentId) {
                if (!!this.siteTree.propagationData.find(o => o.pageId === this.pageId)) {
                    this.$store.commit(this.siteTreeId + '/unsetPropagationData', this.pageId)
                    return
                }

                // Selecting the first option by default removes the need to validate the menus
                const contentArea = (await this.$store.dispatch('pageSectionsContentAreas/get', this.pageId))[0]?.areas[0].value
                    || this.$store.state.pageTemplatesContentAreas.pageTemplatesContentAreas[this.page.templateId][0]

                this.$store.commit(this.siteTreeId + '/propagationData', {pageId: this.pageId, contentArea})

            } else if (this.siteTree.onPageSelect) {
                this.siteTree.onPageSelect(this.pageId)
            }
        },
        onIconClick() {
            // When select mode is enabled, the whole page toggles the page. This prevents the function from
            // being called twice, which would open and close the page again.
            if (this.selectContentMode) return

            this.togglePage()
        },
        togglePage() {
            this.expandedPageIds.indexOf(this.pageId) === -1
                ? this.$store.commit(this.siteTreeId + '/addExpandedPageId', this.pageId)
                : this.$store.commit(this.siteTreeId + '/removeExpandedPageId', this.pageId)
        }
    }
}
</script>

<style scoped>

</style>