import { render, staticRenderFns } from "./FormControlTemplatesTemplatesDetailsPageLayout.vue?vue&type=template&id=9a2ca312&scoped=true"
import script from "./FormControlTemplatesTemplatesDetailsPageLayout.vue?vue&type=script&lang=js"
export * from "./FormControlTemplatesTemplatesDetailsPageLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a2ca312",
  null
  
)

export default component.exports