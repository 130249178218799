<template>
    <section :id="tableName" class="d-flex h-100 overflow-auto flex-column">
        <header>
            <div class="d-flex border-bottom">
                <div class="flex-fill d-flex align-items-center">
                    <template v-if="!selectMode">
                        <ListingOptionsAddBtn :view-id="viewId"/>
                        <ListingOptionsArchiveBtn :view-id="viewId" class="btn rounded-0"/>
                        <ListingOptionsDeleteBtn :view-id="viewId" class="btn rounded-0"/>
                        <ListingOptionsEditBtn :view-id="viewId"/>
                    </template>
                    <ListingOptionsSearchModalBtn :view-id="viewId"/>
                    <SearchFiltersResetBtn :view-id="viewId"/>
                    <ShowSiteTreeBtn :table-name="tableName" :id="lastSelectedId" :site-tree-id="'siteTreeOffcanvas'"/>
                    <ListingBreadcrumbs :view-id="viewId"/>

                    <template v-if="isOdp" class="flex-fill">
                        <OdpCourseDatesCoursesMenu :view-id="viewId" class="ms-1"/>
                        <OdpCourseDatesSchoolsMenu :view-id="viewId" class="ms-1"/>
                    </template>
                </div>

                <div class="d-flex align-items-center">
                    <ListingOptionsPaginationMetrics :view-id="viewId" class="mx-3"/>
                    <ListingOptionsPreviousBtn :view-id="viewId"/>
                    <ListingOptionsNextBtn :view-id="viewId"/>
                    <ListingOptionsMenu :view-id="viewId"/>
                </div>
            </div>

            <SearchFilterToolbar :view-id="viewId"/>
        </header>

        <main class="listing flex-grow-1 overflow-auto pt-3">
            <div v-if="viewMode === 'table'">
                <ListView :view-id="viewId" class="list-view d-md-none mb-3"/>
                <TableView :view-id="viewId" class="table-view d-none d-md-block mb-3"/>
            </div>

            <GridView v-if="viewMode === 'thumbnail'" :view-id="viewId"/>
        </main>

        <Dump>{{ $store.state[viewId] }}</Dump>
    </section>
</template>

<script>
import view from '../../store/modules/view'

import OdpCourseDatesCoursesMenu from '../../bespoke/odp/OdpCourseDatesCoursesMenu'
import OdpCourseDatesSchoolsMenu from '../../bespoke/odp/OdpCourseDatesSchoolsMenu'
import ListingOptionsAddBtn from './ListingOptionsAddBtn'
import ListingOptionsArchiveBtn from './ListingOptionsArchiveBtn'
import ListingOptionsDeleteBtn from './ListingOptionsDeleteBtn'
import ListingOptionsEditBtn from './ListingOptionsEditBtn'
import ListingOptionsSearchModalBtn from './ListingOptionsSearchModalBtn'
import SearchFiltersResetBtn from './SearchFiltersResetBtn'
import ListingOptionsPaginationMetrics from './ListingOptionsPaginationMetrics'
import ListingOptionsPreviousBtn from './ListingOptionsPreviousBtn'
import ListingOptionsNextBtn from './ListingOptionsNextBtn'
import ListingOptionsMenu from './ListingOptionsMenu'
import ListView from './ListView'
import TableView from './TableView'
import GridView from './GridView'
import ShowSiteTreeBtn from '../../common/ShowSiteTreeBtn'
import ListingBreadcrumbs from "./ListingBreadcrumbs.vue"
import SearchFilterToolbar from "../search-filters/SearchFilterToolbar.vue"

export default {
    name: "Listing",
    components: {
        SearchFilterToolbar,
        ListingBreadcrumbs,
        OdpCourseDatesCoursesMenu,
        OdpCourseDatesSchoolsMenu,
        ListingOptionsAddBtn,
        ListingOptionsArchiveBtn,
        ListingOptionsDeleteBtn,
        ListingOptionsEditBtn,
        ListingOptionsSearchModalBtn,
        SearchFiltersResetBtn,
        ListingOptionsPaginationMetrics,
        ListingOptionsPreviousBtn,
        ListingOptionsNextBtn,
        ListingOptionsMenu,
        ListView,
        TableView,
        GridView,
        ShowSiteTreeBtn,
    },
    props: {
        tableName: String,
    },
    data() {
        return {
            viewId: undefined,
        }
    },
    computed: {
        selectMode() {
            return this.$store.state[this.viewId].selectMode
        },
        viewMode() {
            return this.$store.state[this.viewId].viewMode
        },
        isOdp() {
            return (
                this.$store.state.sitename.indexOf('outdoorsproject') === 0
                && this.tableName === 'odp_course_dates'
            )
        },
        lastSelectedId() {
            if (this.viewId) {
                let length = this.$store.state[this.viewId].selectedIds.length
                return this.$store.state[this.viewId].selectedIds[length - 1]
            }
        }
    },
    watch: {
        tableName() {
            this.init()
        },
    },
    created() {
        this.init()
    },
    destroyed() {
        this.$store.commit('mainViewId', false)
    },
    methods: {
        init() {
            this.viewId = 'view:' + this.tableName
            // As users move to and from listings we only want to register modules which don't yet exist, so as to
            // preserve the user's settings for each module.
            if (!this.$store.hasModule(this.viewId)) {
                this.$store.registerModule(this.viewId, view)
            }
            this.$store.dispatch(this.viewId + '/init', {
                listingName: this.tableName,
                query: this.$route.query
            })

            this.$store.commit('mainViewId', this.viewId)
        }
    }
}
</script>

<style scoped>
.listing {
    font-size: 85%;
}
</style>