<template>
    <div>
        <FormControlPlainText v-if="promotions.length === 0" :form-id="formId">
            <em>No promotions available.</em>
        </FormControlPlainText>

        <div v-for="promotion in promotions" class="radio">
            <label>
                <input :checked="categoryIds?.indexOf(promotion.id) > -1" type="checkbox"
                       @click="toggleSelection(promotion.id)">
                {{ promotion.title }}
            </label>
        </div>
    </div>
</template>

<script>
import FormControlPlainText from "../../common/form-control/FormControlPlainText.vue"

export default {
    name: "FormControlOdpCourseDatesPromotionId",
    components: {FormControlPlainText},
    props: {
        currentData: Object,
        field: Object,
        formId: String
    },
    created() {
        if (!this.id) {
            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: []
            })
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        }
    },
    asyncComputed: {
        promotions: {
            default: [],
            async get() {
                if (!this.currentData.courseId)
                    return []

                let o = await this.$store.dispatch('itemData/get', {
                    tableName: 'odp_courses',
                    id: this.currentData.courseId
                })
                const franchiseId = o.franchiseId

                o = await this.$store.dispatch('request/get', {
                    url: 'api/component/odp_promotions',
                    params: {franchiseId}
                })

                return o.data.items
            }
        },
        async categoryIds() {
            const o = await this.$store.dispatch('categories/getTableColumnData', {
                tableName: this.tableName,
                columnName: this.field.name
            })

            const categoryIds = o.filter(o => o.itemId === this.id)?.map(o => o.catId)

            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: categoryIds
            })

            return categoryIds
        }
    },
    methods: {
        toggleSelection(promotionId) {
            let i = this.currentData.promotionId.indexOf(promotionId)

            i === -1
                ? this.currentData.promotionId.push(promotionId)
                : this.currentData.promotionId.splice(i, 1)
        }
    }
}
</script>

<style scoped>

</style>