export default {
    namespaced: true,
    state: {
        items: []
    },
    actions: {
        async notification({dispatch, rootState}, {title, body, onInterval}) {
            // Initiate a new notification.
            const notificationId = (
                await dispatch('request/post', {
                    url: 'api/notification',
                    postData: {title, data: {message: body, percentage: 0}}
                }, {root: true})
            ).data.id
            
            // Present the notification in a toast.
            await dispatch('toasts/add', {
                title, body, notificationId,
                disableAutoHide: true,
                percentage: 0,
                createdBy: rootState.user.user.id,
                onInterval
            }, {root: true})
            
            return {notificationId}
        },
        add({state, dispatch}, {
            title,
            body,
            disableAutoHide,
            percentage,
            notificationId,
            colourScheme,
            createdBy,
            onInterval
        }) {
            const item = {title, body, disableAutoHide, percentage, colourScheme, createdBy}
            
            state.items.push(item)
            
            if (notificationId) dispatch('startInterval', {notificationId, item, onInterval})
        },
        startInterval({state, dispatch}, {notificationId, item, onInterval}) {
            let promise = setInterval(async () => {
                const {percentage, message, downloadUrl} = (
                    await dispatch('request/get', {url: `api/notification/${notificationId}`}, {root: true})
                ).data.item.data
                
                if (percentage) {
                    item.percentage = percentage
                    if (percentage === 100) clearInterval(promise)
                }
                
                if (message) item.body = message
                if (downloadUrl) item.downloadUrl = downloadUrl
                if (onInterval) onInterval()
            }, 1000)
        }
    }
}