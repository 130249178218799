import { render, staticRenderFns } from "./ItemHistoryChangeTypeInternalLink.vue?vue&type=template&id=651b5d26&scoped=true"
import script from "./ItemHistoryChangeTypeInternalLink.vue?vue&type=script&lang=js"
export * from "./ItemHistoryChangeTypeInternalLink.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651b5d26",
  null
  
)

export default component.exports