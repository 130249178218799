<template>
    <a role="button" class="list-group-item list-group-item-action py-1 strong text-uppercase small" :class="classNames"
       @mouseover="onMouseover" @mouseout="onMouseout" @click="onClick">
        <small class="flex-fill">Section {{ sectionNumber }}</small>
    </a>
</template>

<script>
export default {
    name: "PageEditorContentNavSection",
    props: {
        sectionId: Number
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        activeSectionId() {
            return this.pageEditor.activeSectionId
        },
        hoverSectionId() {
            return this.pageEditor.hoverSectionId
        },
        sectionNumber() {
            return this.pageEditor.pageSections.map(o => o.id).indexOf(this.sectionId) + 1
        },
        classNames() {
            if (this.sectionId === this.activeSectionId) {
                return 'active'
            } else if (this.sectionId === this.hoverSectionId) {
                return 'list-group-item-hover'
            } else {
                return 'text-body-secondary bg-body-secondary'
            }
        }
    },
    methods: {
        onMouseover() {
            this.$store.commit('pageEditor/hoverSectionId', this.sectionId)
        },
        onMouseout() {
            this.$store.commit('pageEditor/hoverSectionId', 0)
        },
        async onClick() {
            await this.$store.dispatch('pageEditor/goToSection', {
                router: this.$router,
                sectionId: this.sectionId
            })

            await this.$store.dispatch('pageEditor/scrollToSection', {sectionId: this.sectionId})
        }
    }
}
</script>

<style scoped>
.list-group-item {
    border-color: transparent !important;
    font-size: 0.75rem;
    font-weight: 600;
}

.list-group-item-hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: rgba(9, 110, 253, 0.2);
}
</style>