var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contentTypeTitle
    ? _c(
        "div",
        { staticClass: "d-inline" },
        [
          _c("i", {
            staticClass: "bi-chevron-compact-right mx-1 align-middle",
          }),
          _vm._v(" "),
          _c(
            "Button",
            {
              staticClass: "btn-sm lh-1 p-1",
              on: { mouseover: _vm.onMouseover, mouseout: _vm.onMouseout },
              nativeOn: {
                click: function ($event) {
                  return _vm.selectContent.apply(null, arguments)
                },
              },
            },
            [_c("small", [_vm._v(_vm._s(_vm.contentTypeTitle))])]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }