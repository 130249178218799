<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ modalTitle }}</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <!--<p v-html="message"></p>-->
                <AttachmentDropzone :set-file="setFile" :multiple="true"/>
            </div>
        </div>
    </div>
</template>

<script>
import AttachmentDropzone from '../../common/AttachmentDropzone'

export default {
    name: "BulkUploadModal",
    props: {
        options: Object
    },
    components: {
        AttachmentDropzone
    },
    data() {
        return {
            modalTitle: this.options.modalTitle
        }
    },
    methods: {
        setFile(obj) {
            let viewId = this.$store.state.mainViewId
            this.$store.dispatch(viewId + '/scheduleLoad')
        }
    }
}
</script>

<style scoped>

</style>