<template>
    <div v-if="contentAreaName || activePageContentId || pageSectionsNode" class="dropdown">
        <div class="d-inline-block" data-bs-toggle="dropdown">
            <Button class="rounded-0" title="Add content"><i class="bi bi-plus-lg"/></Button>
        </div>

        <ul class="dropdown-menu">
            <li v-if="pageSectionsNode">
                <a @click="addSection" role="button" class="dropdown-item d-flex align-items-center gap-2">
                    <i class="bi bi-plus-lg"/>
                    New section
                </a>
            </li>
            <template v-if="contentAreaName || activePageContentId">
                <li>
                    <a @click="setOffcanvas" data-bs-toggle="offcanvas" href="#addContentOffcanvas" role="button"
                       aria-controls="addContentOffcanvas" class="dropdown-item d-flex align-items-center gap-2">
                        <i class="bi bi-plus-lg"/>
                        New content
                    </a>
                </li>
                <li>
                    <a @click="setOffcanvas" data-bs-toggle="offcanvas" href="#addExistingContentOffcanvas"
                       role="button"
                       aria-controls="addExistingContentOffcanvas"
                       class="dropdown-item d-flex align-items-center gap-2">
                        <i class="bi bi-diagram-3-fill"/>
                        Existing content
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorToolbarAddContentBtn",
    components: {Button},
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        contentAreaName() {
            return this.pageEditor.activeContentAreaName
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        pageSectionsNode() {
            return this.pageEditor.pageSectionsNode
        }
    },
    methods: {
        setOffcanvas() {
            this.$store.commit('pageEditor/offcanvasEnd', false)
        },
        async addSection() {
            const sectionId = await this.$store.dispatch('pageEditor/addSection')

            await this.$store.dispatch('pageEditor/goToSection', {
                router: this.$router,
                sectionId: sectionId
            })
        }
    }
}
</script>

<style scoped>

</style>