import { render, staticRenderFns } from "./FormSaveCloseBtn.vue?vue&type=template&id=461ba3f7&scoped=true"
import script from "./FormSaveCloseBtn.vue?vue&type=script&lang=js"
export * from "./FormSaveCloseBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461ba3f7",
  null
  
)

export default component.exports