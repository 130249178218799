export default {
    namespaced: true,
    state: {
        data: {}
    },
    mutations: {
        data(state, {tableName, columnName, data}) {
            if (state.data[tableName] === undefined)
                this._vm.$set(state.data, tableName, {})
            
            if (state.data[tableName][columnName] === undefined)
                this._vm.$set(state.data[tableName], columnName, [])
            
            state.data[tableName][columnName] = data
        },
        itemData(state, {tableName, columnName, itemId, data}) {
            if (state.data[tableName] === undefined)
                this._vm.$set(state.data, tableName, {})
            
            if (state.data[tableName][columnName] === undefined)
                this._vm.$set(state.data[tableName], columnName, [])
            
            state.data[tableName][columnName] = state.data[tableName][columnName]
                // Remove the item's old category data
                .filter(o => o.itemId !== itemId)
                // Add the new data
                .concat(data)
        }
    },
    actions: {
        async getTableColumnData({state, commit, dispatch}, {tableName, columnName}) {
            if (state.data?.[tableName]?.[columnName] === undefined) {
                // Prevent subsequent calls from sending requests
                // If, for example, a listing had 6 components which all called initTableColumn, 6 requests would
                // be sent without this.
                commit('data', {tableName, columnName, data: []})
                
                const o = await dispatch('request/get', {
                    url: 'api/categories/get-table-column-data/' + tableName + '/' + columnName,
                }, {root: true})
                
                commit('data', {tableName, columnName, data: o.data})
            }
            
            return state.data?.[tableName]?.[columnName]
        },
        async modifiedItemsUpdate({state, commit, getters, dispatch}, {tableName, columnName, itemId}) {
            const data = !!state.data?.[tableName]?.[columnName]
            
            // If the rel data has not yet been loaded into the UI, don't reload it
            if (!data) return
            
            const o = await dispatch('request/get', {
                url: 'api/categories/get-table-column-item-data',
                params: {tableName, columnName, itemId}
            }, {root: true})
            
            commit('itemData', {tableName, columnName, itemId, data: o.data})
        }
    }
}