<template>
    <Button @click.native="onClick" class="btn-sm rounded-0" :default-class="'bg-body-tertiary'"
            :class="classNames" :active-class="''" :active-value="isActive" :title="tabName">
        <i class="bi" :class="iconClass"/>
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorContentPanelTabBtn",
    components: {Button},
    props: {
        activeTabPropName: String,
        iconClass: String,
        tabName: String,
        borderClassName: String
    },
    computed: {
        activeTab: {
            get() {
                return this.$store.state.pageEditor[this.activeTabPropName]
            },
            set(newValue) {
                this.$store.state.pageEditor[this.activeTabPropName] = newValue
            }
        },
        isActive() {
            return this.activeTab === this.tabName
        },
        classNames() {
            return this.isActive
                ? 'active border-top border-bottom'
                : this.borderClassName
        }
    },
    methods: {
        onClick() {
            this.activeTab = this.activeTab === this.tabName ? '' : this.tabName
        }
    }
}
</script>

<style scoped>
.active {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: var(--bs-border-color) !important;
}
</style>