var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageContentLinkData.length
    ? _c("div", { staticClass: "card-body p-0 pb-1" }, [
        _c(
          "div",
          { staticClass: "list-group list-group-flush" },
          _vm._l(_vm.pageContentLinkData, function (o) {
            return _c("PageContentContent", {
              key: o.id,
              attrs: { "content-id": o.contentId },
            })
          }),
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }