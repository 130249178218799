<template>
    <div class="d-flex flex-column h-100 overflow-hidden">
        <PageEditorToolbar/>

        <PageEditorAddContentOffcanvas/>
        <PageEditorAddExistingContentOffcanvas/>
        <!--<PageEditorEditorToolbar/>-->

        <div ref="container" class="flex-fill d-flex h-100 overflow-hidden">
            <PageEditorLeftSidebar/>

            <div class="flex-fill position-relative overflow-hidden w-100 h-100 d-flex">
                <PageEditorIframe v-if="pageId" :page-id="pageId" :key="pageId"/>
            </div>

            <PageEditorRightSidebar/>
        </div>
    </div>
</template>

<script>
import Form from "../../common/form/Form.vue"
import PageEditorToolbar from "./PageEditorToolbar.vue"
import PageEditorIframe from "./PageEditorIframe.vue"
import PageEditorSiteTree from "./PageEditorSiteTree.vue"
import PageEditorLeftSidebar from "./PageEditorLeftSidebar.vue"
import PageEditorRightSidebar from "./PageEditorRightSidebar.vue"
import PageEditorAddContentOffcanvas from "./PageEditorAddContentOffcanvas.vue"
import PageEditorAddExistingContentOffcanvas from "./PageEditorAddExistingContentOffcanvas.vue"
import PageEditorEditorToolbar from "./PageEditorEditorToolbar.vue"

export default {
    name: "PageEditor",
    components: {
        PageEditorEditorToolbar,
        PageEditorAddExistingContentOffcanvas,
        PageEditorAddContentOffcanvas,
        PageEditorRightSidebar,
        PageEditorLeftSidebar,
        PageEditorSiteTree,
        PageEditorIframe,
        PageEditorToolbar,
        Form
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        pageSections() {
            return this.pageEditor.pageSections
        },
        contentAreas() {
            return this.pageEditor.contentAreas
        },
        contentItems() {
            return this.pageEditor.contentItems
        },
        hoverSectionId() {
            return this.pageEditor.hoverSectionId
        },
        hoverContentAreaName() {
            return this.pageEditor.hoverContentAreaName
        },
        hoverPageContentId() {
            return this.pageEditor.hoverPageContentId
        }
    },
    created() {
        this.$store.dispatch('pageEditor/loadSettings')

        const params = this.$route.params

        if (params.pageId) {
            this.$store.commit('pageEditor/pageId', parseInt(params.pageId))
        } else {
            const pageId = this.pageId || this.$store.state.settings.homePage
            this.$router.push({name: 'pageEditor', params: {pageId: pageId}})
        }

        document.addEventListener('keyup', this.deleteEvent)
    },
    destroyed() {
        document.removeEventListener('keyup', this.deleteEvent)
    },
    watch: {
        $route(to) {
            const pageId = to.params?.pageId ? parseInt(to.params?.pageId) : 0
            const sectionId = to.params?.sectionId ? parseInt(to.params?.sectionId) : 0
            const areaIndex = to.params?.areaIndex !== undefined ? to.params?.areaIndex : -1
            const pageContentId = to.params?.pageContentId ? parseInt(to.params?.pageContentId) : 0

            this.$store.commit('pageEditor/pageId', pageId)

            this.$store.commit('pageEditor/activeSectionId', 0)
            this.$store.commit('pageEditor/activeContentAreaName', '')
            this.$store.commit('pageEditor/activePageContentId', 0)

            switch (true) {
                case pageContentId > 0:
                    this.$store.commit('pageEditor/activePageContentId', pageContentId)
                    break
                case areaIndex > -1:
                    const contentArea = this.contentAreas[areaIndex].name
                    this.$store.commit('pageEditor/activeContentAreaName', contentArea)
                    break
                case sectionId > 0:
                    this.$store.commit('pageEditor/activeSectionId', sectionId)
            }
        },
        hoverSectionId(newValue, oldValue) {
            this.setSectionBackground(oldValue, '')
            this.setSectionBackground(newValue, 'rgba(9, 110, 253, 0.2)')
        },
        hoverContentAreaName(newValue, oldValue) {
            this.setAreaBackground(oldValue, '')
            this.setAreaBackground(newValue, 'rgba(9, 110, 253, 0.2)')
        },
        hoverPageContentId(newValue, oldValue) {
            this.setContentBackground(oldValue, '')
            this.setContentBackground(newValue, 'rgba(9, 110, 253, 0.2)')
        }
    },
    methods: {
        deleteEvent(e) {
            this.$store.dispatch('pageEditor/keyboardEventHandler', e)
        },
        setSectionBackground(id, colour) {
            if (id)
                this.$store.state.pageEditor.pageSections.find(o => o.id === id).el.style.background = colour
        },
        setAreaBackground(name, colour) {
            if (name)
                this.$store.state.pageEditor.contentAreas.find(o => o.name === name).el.style.background = colour
        },
        setContentBackground(id, colour) {
            if (id)
                this.$store.state.pageEditor.contentItems.find(o => o.pageContentId === id).el.style.background = colour
        }
    }
}
</script>

<style scoped>

</style>