import { render, staticRenderFns } from "./TableCellTypeTel.vue?vue&type=template&id=9bb6e210&scoped=true"
import script from "./TableCellTypeTel.vue?vue&type=script&lang=js"
export * from "./TableCellTypeTel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bb6e210",
  null
  
)

export default component.exports