<template>
    <div ref="dropdown" class="dropdown">
        <Button :class="{ active: isActive }" class="h-100 rounded-0" data-bs-toggle="dropdown"
                title="Settings">
            <UserAvatar v-if="user" :user-id="user.id" class="navigation-user-avatar"/>
        </Button>

        <div class="dropdown-menu dropdown-menu-end">
            <button v-user-access="'account_settings'" :class="{ active: this.$route.path === '/settings' }"
                    class="dropdown-item" type="button" @click="goTo('settings')">
                Settings
            </button>

            <button v-if="subscriptionNavItemEnabled()" :class="{ active: this.$route.path === '/subscription-plan' }"
                    class="dropdown-item" type="button" @click="goTo('subscription-plan')">
                Subscription plan
            </button>

            <button v-user-access="'users'" :class="{ active: this.$route.path === '/users' }" class="dropdown-item"
                    type="button" @click="goTo('users')">
                Manage users
            </button>

            <button v-user-access="'users_groups'" :class="{ active: this.$route.path === '/users_groups' }"
                    class="dropdown-item" type="button" @click="goTo('users_groups')">
                Manage roles
            </button>

            <router-link v-if="reportsLink" :to="{ name: 'reports' }" class="dropdown-item">
                Reports
            </router-link>

            <button v-if="showClearTheCache"
                    :class="{ 'clear-the-cache-item-active': this.$store.state.cacheNeedsClearing }"
                    class="dropdown-item clear-the-cache-item d-flex align-items-center" type="button"
                    @click="clearTheCache">
                <span class="me-2">Clear the cache</span>
            </button>

            <button class="dropdown-item d-flex align-items-center" type="button" @click="toggleDarkMode">
                <span class="me-2">{{ darkMode ? 'Light mode' : 'Dark mode' }}</span>
            </button>

            <button class="dropdown-item d-flex align-items-center" type="button" @click="logout">
                <span class="me-2 bi-box-arrow-right"/>
                Log out
            </button>
        </div>
    </div>
</template>

<script>
import UserAvatar from '../../common/UserAvatar'

import userAccess from '../../vue/directives/userAccess'
import Button from "../Button.vue"

export default {
    name: "NavigationUserMenu",
    components: {Button, UserAvatar},
    directives: {userAccess},
    computed: {
        isActive() {
            return['/settings', '/subscription-plan', '/users', '/users_groups',].indexOf(this.$route.path) > -1
        },
        user() {
            return this.$store.state.user.user
        },
        reportsLink() {
            const sitename = this.$store.state.sitename
            return ['bigbrandbeds', 'outdoorsproject', 'printgifts'].findIndex(str => sitename.startsWith(str)) > -1
        },
        darkMode() {
            return !!this.$store.getters['user/pref']('darkMode')
        },
        showClearTheCache() {
            return this.user.administrator || this.user.allowCacheClearance
        }
    },
    methods: {
        subscriptionNavItemEnabled() {
            return (
                this.$store.state.settings.subscriptionPriceId
                && (this.user.administrator || this.user.access.__subscriptionPlanAccess)
            )
        },
        goTo(tableName) {
            this.$router.push({path: '/' + tableName})

            this.$refs.dropdown.childNodes[0].click()
        },
        async clearTheCache() {
            const {notificationId} = (await this.$store.dispatch('toasts/notification', {
                title: 'Clear the cache',
                message: 'Starting&hellip;'
            }))

            await this.$store.dispatch('clearCache', {notificationId})
        },
        logout() {
            this.$store.commit('modals/hideAll')
            localStorage.removeItem('token')

            this.$store.commit('user/reset')

            window.location.reload()
        },
        toggleDarkMode() {
            this.$store.dispatch('user/toggleDarkMode')
        }
    }
}
</script>

<style scoped>
.clear-the-cache-item,
.clear-the-cache-item:hover {
    color: #999;
}

.clear-the-cache-item-active,
.clear-the-cache-item-active:hover {
    color: salmon;
}
</style>