<template>
    <div v-model="categoryIds" :name="field.name">
        <div v-if="!field.readonly" class="mt-1 mb-2">
            <Button :disabled="field.disabled" @click.native="selectCategories" :class="{'btn-sm border': formSmall}">
                <i class="bi-plus-lg"/>
            </Button>

            <Button v-if="targetTableName" v-user-access="targetTableName" :disabled="field.disabled"
                    @click.native="manageCategories" :class="{'btn-sm border': formSmall}">
                <i class="bi-pencil-square"/>
            </Button>
        </div>

        <div v-if="targetTableName" v-show="!field.disabled" class="list-group mb-0">
            <a v-for="(id, index) in categoryIds" v-if="!restrictedIds || restrictedIds.indexOf(id) === -1" :key="id"
               class="list-group-item d-flex align-items-center" href @click.prevent="editItem(id)">
                <FormControlCategoryTitle :id="id" :table-name="targetTableName" class="flex-fill"/>

                <ItemStatusIcons :table-name="targetTableName" :id="id" class="me-2 gap-2"/>

                <template v-if="!field.readonly">
                    <Button v-if="!selectLimit || selectLimit > 1" class="btn-sm" :disabled="!index"
                            @click.prevent.stop.native="moveUp(id)">
                        <i class="bi-chevron-up"/>
                    </Button>

                    <Button v-if="!selectLimit || selectLimit > 1" class="btn-sm" :disabled="index === categoryIds.length - 1"
                            @click.prevent.stop.native="moveDown(id)">
                        <i class="bi-chevron-down"/>
                    </Button>

                    <Button @click.prevent.stop.native="removeCategory(id)" class="btn-sm">
                        <i class="bi-x-lg"/>
                    </Button>
                </template>
            </a>
        </div>
    </div>
</template>

<script>
import FormControlCategoryTitle from '../../common/form-control/FormControlCategoryTitle'
import ItemStatusIcons from '../../common/ItemStatusIcons'

import userAccess from '../../vue/directives/userAccess'
import Button from "../Button.vue"

export default {
    name: "FormControlTypeRelationshipManyToMany",
    components: {Button, FormControlCategoryTitle, ItemStatusIcons},
    directives: {userAccess},
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        restrictedIds: Array,
        selectLimit: Number
    },
    computed: {
        components() {
            return this.$store.state.components.items
        },
        targetTableName() {
            return this.field?.tableName || this.components.find(o => o.id === this.field.categoryComponentId)?.tableName
        },
        tableName() {
            let tableName = this.$store.state[this.formId].tableName

            // If this is the content component then this field may belong to a CT component, in which case the
            // categories request must target the component_<id> table and not "content". The ID of the table's record
            // is assigned by FormControlContentContent to the field's contentComponentsItemId property.
            if (tableName === 'content') {
                let componentId = this.field.modulecomponentsID
                let component = this.$store.state.components.items.find(obj => obj.id === componentId)
                if (component) {
                    tableName = component.tableName
                }
            }

            return tableName
        },
        id() {
            let id = this.$store.state[this.formId].id

            // If this is the content component then this field may belong to a CT component, in which case the
            // categories request must target the component_<id> table and not "content". The ID of the table's record
            // is assigned by FormControlContentContent to the field's contentComponentsItemId property.
            if (this.tableName.indexOf('component_') === 0) {
                id = this.field.contentComponentsItemId || id
            }

            return id
        },
        formSmall() {
            return this.$store.state[this.formId].formSmall
        },
        categoryIds() {
            return this.currentData[this.field.name]
        }
    },
    async created() {
        const o = await this.$store.dispatch('categories/getTableColumnData', {
            tableName: this.tableName,
            columnName: this.field.name
        })

        let ids = o.filter(o => o.itemId === this.id)?.map(o => o.catId)

        ids = [...new Set(ids)]

        this.$store.commit(this.formId + '/presetData', {
            name: this.field.name,
            value: ids,
        })
    },
    methods: {
        selectCategories() {
            this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    formId: this.formId,
                    listingName: this.targetTableName + '.' + this.field.name,
                    selectedIds: this.currentData[this.field.name],
                    // Providing an empty onSelect function triggers the view to use onMetaClick in place of onClick.
                    onSelect: ids => {}
                }
            })
        },
        manageCategories() {
            this.$router.push({name: 'list', params: {component: this.targetTableName}})
        },
        editItem(id) {
            this.$router.push({name: 'form', params: {component: this.targetTableName, id: id}})
        },
        moveUp(id) {
            let ids = this.currentData[this.field.name]
            let i = ids.indexOf(id)
            ids.splice(i, 1)
            ids.splice(i - 1, 0, id)
        },
        moveDown(id) {
            let ids = this.currentData[this.field.name]
            let i = ids.indexOf(id)
            ids.splice(i, 1)
            ids.splice(i + 1, 0, id)
        },
        removeCategory(id) {
            let ids = this.currentData[this.field.name]
            let i = ids.indexOf(id)
            ids.splice(i, 1)
        }
    }
}
</script>

<style scoped>

</style>