<template>
    <div class="dropdown">
        <!-- The span is required because Vue's :class conflicts with data-bs-toggle="dropdown". If both are assigned to
             the button then, when :class updates the component, it fails to toggle the menu -->
        <div class="d-inline-block" data-bs-toggle="dropdown">
            <Button class="rounded-0" :active-class="'btn-warning'" :active-value="showArchive">
                <i class="bi-three-dots"/>
            </Button>
        </div>

        <div class="dropdown-menu">
            <ListingOptionsPageSize :view-id="viewId" class="dropdown-item"/>
            <ListingOptionsArchiveBtn :show-text="true" :view-id="viewId" class="dropdown-item"/>
            <ListingOptionsDeleteBtn :show-text="true" :view-id="viewId" class="dropdown-item"/>
            <ListingOptionsViewMode :view-id="viewId" class="dropdown-item"/>
            <ListingOptionsShowArchiveBtn :view-id="viewId" class="dropdown-item"/>
            <ListingOptionsExportBtn v-if="!selectMode" :view-id="viewId" class="dropdown-item"/>
            <ListingOptionsImportBtn v-if="!selectMode" :view-id="viewId" class="dropdown-item"/>
            <ListingOptionsBulkUploadBtn v-if="!selectMode" :view-id="viewId" class="dropdown-item"/>
            <ListingOptionsShareBtn :view-id="viewId" class="dropdown-item"/>
            <ListingOptionsColumnsBtn :view-id="viewId" class="dropdown-item"/>
            <ComponentSettingsBtn v-if="!isSelectListing" :table-name="tableName" class="dropdown-item"/>
            <ComponentFieldsBtn v-if="!isSelectListing" :table-name="tableName" class="dropdown-item"/>
        </div>
    </div>
</template>

<script>
import ListingOptionsArchiveBtn from './ListingOptionsArchiveBtn'
import ListingOptionsBulkUploadBtn from './ListingOptionsBulkUploadBtn'
import ListingOptionsDeleteBtn from './ListingOptionsDeleteBtn'
import ListingOptionsPageSize from './ListingOptionsPageSize'
import ListingOptionsViewMode from './ListingOptionsViewMode'
import ListingOptionsShowArchiveBtn from './ListingOptionsShowArchiveBtn'
import ListingOptionsExportBtn from './ListingOptionsExportBtn'
import ListingOptionsImportBtn from './ListingOptionsImportBtn'
import ListingOptionsColumnsBtn from './ListingOptionsColumnsBtn'
import ComponentSettingsBtn from '../../components/component-settings/ComponentSettingsBtn'
import ComponentFieldsBtn from '../../components/component-fields/ComponentFieldsBtn'
import Button from "../Button.vue"
import ListingOptionsShareBtn from "./ListingOptionsShareBtn.vue"

export default {
    name: "ListingOptionsMenu",
    components: {
        ListingOptionsShareBtn,
        Button,
        ListingOptionsArchiveBtn,
        ListingOptionsBulkUploadBtn,
        ListingOptionsDeleteBtn,
        ListingOptionsPageSize,
        ListingOptionsViewMode,
        ListingOptionsShowArchiveBtn,
        ListingOptionsExportBtn,
        ListingOptionsImportBtn,
        ListingOptionsColumnsBtn,
        ComponentSettingsBtn,
        ComponentFieldsBtn
    },
    props: {
        viewId: String,
    },
    computed: {
        selectMode() {
            return this.$store.state[this.viewId].selectMode
        },
        showArchive() {
            return this.$store.state[this.viewId].showArchive
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        isSelectListing() {
            return this.viewId.indexOf('.') > -1
        }
    }
}
</script>

<style scoped>

</style>